import { Injectable } from "@angular/core";
import lodash from "lodash";
import moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  globalCache = {};
  CACHE_VALIDITY = 15 * 60 * 1000; // 15 minutes

  constructor() {
  }

  // --- helper method to prepare cache path
  private getCachePath(key, path) {
    return `${key}${path ? "." + path : ""}`;
  }

  // --- if its array or object, value can be referenced by the parent variable,
  // so dereferencify value by deep cloning to prevent unwanted changes when parent variable changes
  dereferencify(value) {
    if (lodash.isObject(value) || lodash.isArray(value)) {
      value = lodash.cloneDeep(value);
    }
    return value;
  }

  // --- get cache value from key
  get(key, path) {
    if (!key) return false;

    let cachePath = this.getCachePath(key, path);
    let cachedData = lodash.get(this.globalCache, cachePath);
    if (
      !cachedData ||
      !cachedData.timestamp ||
      !cachedData.hasOwnProperty("value")
    )
      return null;

    if (
      moment(cachedData.timestamp)
        .add(this.CACHE_VALIDITY, "milliseconds")
        .valueOf() >= moment().valueOf()
    ) {
      return this.dereferencify(cachedData);
    }
    return null;
  }

  // --- set cache value
  set(key, path, value) {
    if (!key) return false;

    value = this.dereferencify(value);

    let cachePath = this.getCachePath(key, path);
    lodash.set(this.globalCache, cachePath, {
      value: value,
      timestamp: moment().valueOf(),
    });
    return true;
  }

  // --- remove cache value from key
  remove(key, path) {
    if (!key) return false;

    let cachePath = this.getCachePath(key, path);
    this.globalCache = lodash.omit(this.globalCache, [cachePath]);
    return true;
  }

  // --- remove all cache values
  removeAll() {
    this.globalCache = {};
    return true;
  }
}

export enum CacheKeys {
  OrgLicensesArr = "OrgLicensesArr",
  DesignTypeData = "DesignTypeData",
  DesignTypesData = "DesignTypesData",
  OrgCustomizeDesignsData = "OrgCustomizeDesignsData",
  StudioCustomizeDesignsData = "StudioCustomizeDesignsData",
  CustomizeDesignsData = "CustomizeDesignsData",
  OrgStudioID = "OrgStudioID",
  OrgData = "OrgData",
  OrgSecData = "OrgSecData",
  StudioData = "StudioData",
  CustomizeDesignsOrder = "CustomizeDesignsOrder",
  StudioCustomizeDesignsOrder = "StudioCustomizeDesignsOrder",
  OrgCustomizeDesignsOrder = "OrgCustomizeDesignsOrder",
  AdmissibleGrp = "AdmissibleGrp",
  AdmissibleGrps = "AdmissibleGrps",
  StudioAdmissibleGrps = "StudioAdmissibleGrps",
  OrgAdmissibleGrps = "OrgAdmissibleGrps",
  Design = "Design",
  StudioDesign = "StudioDesign",
  OrgDesign = "OrgDesign",
  Designs = "Designs",
  StudioDesigns = "StudioDesigns",
  OrgDesigns = "OrgDesigns",
  Background = "Background",
  OrgBackground = "OrgBackground",
  StudioBackground = "StudioBackground",
  BGUrl = "BGUrl",
  DateToShowOnID = "DateToShowOnID",
  DBValueFromPath = "DBValueFromPath",
  PreferenceData = "PreferenceData",
  ImgWithBGData = "ImgWithBGData",
  InheritanceData = "InheritanceData",
  SVGData = "SVGData",
  RegionsList = "RegionsList",
  ConfList = "ConfList",
  OrgsList = "OrgsList",
  IndData = "IndData",
  LocalStorage = "LocalStorage",
}
