import { Inject, Injectable, forwardRef } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import lodash from "lodash";
import { Subscription } from "rxjs";
import { HttpClient } from "@angular/common/http";
import moment from "moment";
import { debounceTime, map, take } from "rxjs/operators";
import { Role } from "../../constants/enums";
import { EncryptionService } from "../encryption/encryption.service";
import { environment } from "../../../../environments/environment.stage";
import { Router } from "@angular/router";
import { getAuth, signOut } from "firebase/auth";
import { AuthService } from "../auth/auth.service";
import { OrganizationService } from "../organization/organization.service";
import { CommonServiceService } from "../common-service.service";
import { AuthDependency } from "../../interface/types";
import { IndividualApiService, IndividualService } from "../individuals/individual.service";
import { CacheKeys, CacheService } from "../cache/cache.service";

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class UserService {
 // UserService Permission Settings
 userPermissionList: any[] = [
  {
    "name": "Communications",
    "key": "Communications",
    "access": true,
  },
  {
    "name": "Database Tools",
    "key": "Database_Tools",
    "access": true,
    "Actions": [
      {
        "name": "Export",
        "key": "Export",
        "access": true
      },
      {
        "name": "Import",
        "key": "Import",
        "access": true
      },
      {
        "name": "Repair Database",
        "key": "Repair_Database",
        "access": true
      },
      {
        "name": "Resolve Duplicates",
        "key": "Resolve_Duplicates",
        "access": true
      }
    ]
  },
  {
    "name": "Ind Actions",
    "key": "Ind_Action",
    "access": true,
    "Actions": [
      {
        "name": "Add Behavioral Incident",
        "key": "Add_Behavioral_Incident",
        "access": true
      },
      {
        "name": "Add Event Ticket",
        "key": "Add_Event_Ticket",
        "access": true
      },
      {
        "name": "Add Image",
        "key": "Add_Image",
        "access": true
      },
      {
        "name": "Assign Pass",
        "key": "Assign_Pass",
        "access": true
      },
      {
        "name": "Capture Photo",
        "key": "Capture_Photo",
        "access": true
      },
      {
        "name": "Mark Task Completed",
        "key": "Mark_Task_Completed",
        "access": true
      },
      {
        "name": "Output ID",
        "key": "Output_ID",
        "access": true
      },
      {
        "name": "Personal Home Page",
        "key": "Personal_Home_Page",
        "access": true
      },
      {
        "name": "Remove Image",
        "key": "Remove_Image",
        "access": true
      },
      {
        "name": "Reset Password",
        "key": "Reset_Password",
        "access": true
      },
      {
        "name": "Send Personal Page Link",
        "key": "Send_Personal_Page_Link",
        "access": true
      },
      {
        "name": "Set Flag",
        "key": "Set_Flag",
        "access": true
      },
      {
        name: 'Bulk Edit',
        key: 'Bulk_Edit',
        access: true
      }
    ]
  },
  {
    "name": "Data Management",
    "key": "Data_Management",
    "access": true,
    "Actions": [
      {
        "name": "Add Individual",
        "key": "Add_Individual",
        "access": true
      },
      {
        "name": "Update Individual",
        "key": "Update_Individual",
        "access": true
      },
      {
        "name": "Delete Individual",
        "key": "Delete_Individual",
        "access": true
      },
      {
        "name": "Add Relation",
        "key": "Add_Relation",
        "access": true
      },
      {
        "name": "Delete Relation",
        "key": "Delete_Relation",
        "access": true
      },
      {
        "name": "Detail Section",
        "key": "Detail_Section",
        "access": true,
        "isButton": true,
        "Actions": [
          {
            "name": "School",
            "key": "School",
            "access": true
          },
          {
            "name": "Contact",
            "key": "Contact",
            "access": true
          },
          {
            "name": "Relation",
            "key": "Relation",
            "access": true
          },
          {
            "name": "Behaviour",
            "key": "Behaviour",
            "access": true
          },
          {
            "name": "Passes",
            "key": "Passes",
            "access": true
          },
          {
            "name": "Operations",
            "key": "Operations",
            "access": true
          },
          // {
          //   "name": "Certifications",
          //   "key": "Certifications",
          //   "access": false
          // },
          {
            "name": "Organization",
            "key": "Organization",
            "access": true
          },
          {
            "name": "Other Identification",
            "key": "Other_Identification",
            "access": true
          },
          {
            "name": "Surveys And Quiz",
            "key": "Surveys_And_Quiz",
            "access": true
          }
        ]
      },
      {
        "name": "Attributes",
        "key": "Attributes",
        "isButton": true,
        "access": false,
        "Actions": [
          {
            "name": "Phone",
            "key": "Phone",
            "access": 3
          },
          {
            "name": "Email",
            "key": "Email",
            "access": 3
          },
          {
            "name": "StudentID",
            "key": "StudentID",
            "access": 3
          },
          {
            "name": "GovID",
            "key": "GovID",
            "access": 3
          },
          {
            "name": "RFID",
            "key": "RFID",
            "access": 3
          },
          {
            "name": "DatabaseID",
            "key": "DatabaseID",
            "access": 3
          },
          {
            "name": "Hash",
            "key": "Hash",
            "access": 3
          }
        ]
      }
    ]
  },
  {
    "name": "Designs",
    "key": "Designs",
    "access": true,
    "Actions": [
      {
        name: 'FormFactors',
        key: 'FormFactors',
        access: true
      },
      {
        "name": "Design Catalog",
        "key": "DesignCatalog",
        "access": true
      },
      {
        "name": "Media",
        "key": "Media",
        "access": true
      },
      {
        "name": "Defaults",
        "key": "Defaults",
        "access": true
      },
      {
        "name": "Edit Variations",
        "key": "Edit_Variations",
        "access": true
      },
      {
        "name": "OutputDesigns",
        "key": "Output_Designs",
        "access": true
      },
      {
        "name": "Edit Badges",
        "key": "Edit_Badges",
        "access": true
      }
    ]
  },
  {
    "name": "Settings",
    "key": "Settings",
    "access": true,
    "Actions": [
      {
        "name": "Organization",
        "key": "Organization",
        "access": false
      },
      {
        "name": "Financial settings",
        "key": "Financial_settings",
        "access": false
      },
      {
        "name": "Scheduling",
        "key": "Scheduling",
        "access": false
      },
    ]
  },
  {
    "name": "Messages",
    "key": "Messages",
    "access": true
  },
  {
    "name": "Reception",
    "key": "Reception",
    "access": true
  },
  {
    "name": "Behavior Tracking",
    "key": "Behavior_Tracking",
    "access": true
  },
  {
    "name": "Event Tracking",
    "key": "Event_Tracking",
    "access": true,
    "Actions": [
      {
        "name": "Attended Events",
        "key": "Attended_Events",
        "access": true
      },
      {
        "name": "Ticketed Events",
        "key": "Ticketed_Events",
        "access": true
      }
    ]
  },
  {
    "name": "ID Essentials",
    "key": "ID_Essentials",
    "access": true,
    "Actions": [
      {
        "name": "Kiosk",
        "key": "Kiosk",
        "access": true
      },
      {
        "name": "Review & Approval",
        "key": "Review_And_Approval",
        "access": true
      },
      {
        "name": "Output ID",
        "key": "Output_ID",
        "access": true
      },
      {
        "name": "See Cards",
        "key": "See_Cards",
        "access": true
      },
      {
        "name": "Print Order",
        "key": "Print_Order",
        "access": true
      },
      {
        "name": "Colors Of The Day",
        "key": "Colors_Of_The_Day",
        "access": false
      },
      {
        "name": "Approval worksheet",
        "key": "Approval_worksheet",
        "access": true
      },
      {
        "name": "Certifications/ Qualifications",
        "key": "Certifications_Qualifications",
        "access": true
      }
    ]
  },
  {
    "name": "Student Tracking",
    "key": "Student_Tracking",
    "access": true,
    "Actions": [
      {
        "name": "Kiosk",
        "key": "Kiosk",
        "access": true
      },
      {
        "name": "Reception",
        "key": "Reception",
        "access": true
      },
      {
        "name": "Student Logs",
        "key": "Student_Logs",
        "access": true
      },
      {
        "name": "Reasons",
        "key": "Reasons",
        "access": true
      },
      {
        "name": "Attendance",
        "key": "Attendance",
        "access": true
      },
    ]
  },
  {
    "name": "Visitor Tracking",
    "key": "Visitor_Tracking",
    "access": true,
    "Actions": [
      {
        "name": "Kiosk",
        "key": "Kiosk",
        "access": true
      },
      {
        "name": "Reception",
        "key": "Reception",
        "access": true
      },
      {
        "name": "Visitor Logs",
        "key": "Visitor_Logs",
        "access": true
      },
      {
        "name": "Reasons",
        "key": "Reasons",
        "access": true
      },
      {
        "name": "Locations",
        "key": "Locations",
        "access": true
      },
    ]
  },
  {
    "name": "Yearbook Tools",
    "key": "Yearbook_Tools",
    "access": true
  },
  {
    "name": "Logs",
    "key": "Logs",
    "access": true
  },
  {
    "name": "News",
    "key": "News",
    "access": true
  },
  {
    "name": "UserService Management",
    "key": "User_Management",
    "access": true
  },
  {
    "name": "Expo Tools",
    "key": "Expo_Tools",
    "access": true
  }
]

// UserService Permission Settings
sportsteamUserPermissionList: any[] = [
  {
    "name": "Registration",
    "key": "Registration",
    "access": true,
    "Actions": [
      {
        "name": "Approval worksheet",
        "key": "Approval_worksheet",
        "access": false
      },
      {
        "name": "Final Approval Settings",
        "key": "Final_Approval_Settings",
        "access": false
      }
    ]
  },
  {
    "name": "Communications",
    "key": "Communications",
    "access": true,
  },
  {
    "name": "Database Tools",
    "key": "Database_Tools",
    "access": true,
    "Actions": [
      {
        "name": "Export",
        "key": "Export",
        "access": true
      },
      {
        "name": "Import",
        "key": "Import",
        "access": true
      },
      {
        "name": "Repair Database",
        "key": "Repair_Database",
        "access": true
      },
      {
        "name": "Resolve Duplicates",
        "key": "Resolve_Duplicates",
        "access": true
      }
    ]
  },
  {
    "name": "Ind Actions",
    "key": "Ind_Action",
    "access": true,
    "Actions": [
      {
        "name": "Add Behavioral Incident",
        "key": "Add_Behavioral_Incident",
        "access": true
      },
      {
        "name": "Add Event Ticket",
        "key": "Add_Event_Ticket",
        "access": true
      },
      {
        "name": "Add Image",
        "key": "Add_Image",
        "access": true
      },
      {
        "name": "Assign Pass",
        "key": "Assign_Pass",
        "access": true
      },
      {
        "name": "Capture Photo",
        "key": "Capture_Photo",
        "access": true
      },
      {
        "name": "Mark Task Completed",
        "key": "Mark_Task_Completed",
        "access": true
      },
      {
        "name": "Output ID",
        "key": "Output_ID",
        "access": true
      },
      {
        "name": "Personal Home Page",
        "key": "Personal_Home_Page",
        "access": true
      },
      {
        "name": "Remove Image",
        "key": "Remove_Image",
        "access": true
      },
      {
        "name": "Reset Password",
        "key": "Reset_Password",
        "access": true
      },
      {
        "name": "Send Personal Page Link",
        "key": "Send_Personal_Page_Link",
        "access": true
      },
      {
        "name": "Set Flag",
        "key": "Set_Flag",
        "access": true
      },
      {
        name: 'Bulk Edit',
        key: 'Bulk_Edit',
        access: true
      }
    ]
  },
  {
    "name": "Data Management",
    "key": "Data_Management",
    "access": true,
    "Actions": [
      {
        "name": "Add Individual",
        "key": "Add_Individual",
        "access": true
      },
      {
        "name": "Update Individual",
        "key": "Update_Individual",
        "access": true
      },
      {
        "name": "Delete Individual",
        "key": "Delete_Individual",
        "access": true
      },
      {
        "name": "Add Relation",
        "key": "Add_Relation",
        "access": true
      },
      {
        "name": "Delete Relation",
        "key": "Delete_Relation",
        "access": true
      },
      {
        "name": "Detail Section",
        "key": "Detail_Section",
        "access": true,
        "isButton": true,
        "Actions": [
          {
            "name": "School",
            "key": "School",
            "access": true
          },
          {
            "name": "Contact",
            "key": "Contact",
            "access": true
          },
          {
            "name": "Relation",
            "key": "Relation",
            "access": true
          },
          {
            "name": "Behaviour",
            "key": "Behaviour",
            "access": true
          },
          {
            "name": "Passes",
            "key": "Passes",
            "access": true
          },
          {
            "name": "Operations",
            "key": "Operations",
            "access": true
          },
          {
            "name": "Organization",
            "key": "Organization",
            "access": true
          },
          {
            "name": "Other Identification",
            "key": "Other_Identification",
            "access": true
          },
          {
            "name": "Surveys And Quiz",
            "key": "Surveys_And_Quiz",
            "access": true
          }
        ]
      },
      {
        "name": "Attributes",
        "key": "Attributes",
        "isButton": true,
        "access": false,
        "Actions": [
          {
            "name": "Phone",
            "key": "Phone",
            "access": 3
          },
          {
            "name": "Email",
            "key": "Email",
            "access": 3
          },
          {
            "name": "StudentID",
            "key": "StudentID",
            "access": 3
          },
          {
            "name": "GovID",
            "key": "GovID",
            "access": 3
          },
          {
            "name": "RFID",
            "key": "RFID",
            "access": 3
          },
          {
            "name": "DatabaseID",
            "key": "DatabaseID",
            "access": 3
          },
          {
            "name": "Hash",
            "key": "Hash",
            "access": 3
          }
        ]
      }
    ]
  },
  {
    "name": "Designs",
    "key": "Designs",
    "access": true,
    "Actions": [
      {
        name: 'FormFactors',
        key: 'FormFactors',
        access: true
      },
      {
        "name": "Design Catalog",
        "key": "DesignCatalog",
        "access": true
      },
      {
        "name": "Media",
        "key": "Media",
        "access": true
      },
      {
        "name": "Defaults",
        "key": "Defaults",
        "access": true
      },
      {
        "name": "Edit Variations",
        "key": "Edit_Variations",
        "access": true
      },
      {
        "name": "OutputDesigns",
        "key": "Output_Designs",
        "access": true
      },
      {
        "name": "Edit Badges",
        "key": "Edit_Badges",
        "access": true
      }
    ]
  },
  {
    "name": "Settings",
    "key": "Settings",
    "access": true,
    "Actions": [
      {
        "name": "Organization",
        "key": "Organization",
        "access": false
      },
      {
        "name": "Financial settings",
        "key": "Financial_settings",
        "access": false
      },
      {
        "name": "Scheduling",
        "key": "Scheduling",
        "access": false
      },
    ]
  },
  {
    "name": "Messages",
    "key": "Messages",
    "access": true
  },
  {
    "name": "ID Essentials",
    "key": "ID_Essentials",
    "access": true,
    "Actions": [
      {
        "name": "Kiosk",
        "key": "Kiosk",
        "access": true
      },
      {
        "name": "Review & Approval",
        "key": "Review_And_Approval",
        "access": true
      },
      {
        "name": "Output ID",
        "key": "Output_ID",
        "access": true
      },
      {
        "name": "See Cards",
        "key": "See_Cards",
        "access": true
      },
      {
        "name": "Print Order",
        "key": "Print_Order",
        "access": true
      },
      {
        "name": "Colors Of The Day",
        "key": "Colors_Of_The_Day",
        "access": false
      }
    ]
  },
  {
    "name": "Logs",
    "key": "Logs",
    "access": true
  },
  {
    "name": "News",
    "key": "News",
    "access": true
  },
  {
    "name": "UserService Management",
    "key": "User_Management",
    "access": true
  },
  {
    "name": "Player Tracking",
    "key": "Player_Tracking",
    "access": true,
    "Actions": [
      {
        "name": 'Create Game',
        "key": 'Create_Game',
        "access": true
      },
      {
        "name": "Delete Game",
        "key": "Delete_Game",
        "access": true
      },
      {
        "name": "Game Attendance",
        "key": "Game_Attendance",
        "access": true
      },
      {
        "name": "MPR",
        "key": "MPR",
        "access": true
      }
    ]
  }
]

orgManagerUserPermissionList: any[] = [
  {
    "name": "Organization",
    "key": "Organization",
    "access": true,
    "Actions": [
      {
        "name": "Add",
        "key": "Add",
        "access": false
      },
      {
        "name": "Edit",
        "key": "Edit",
        "access": false
      },
      {
        "name": "Delete",
        "key": "Delete",
        "access": false
      }
    ]
  },
  {
    "name": "Designs",
    "key": "Designs",
    "access": true,
    "Actions": [
      {
        name: 'FormFactors',
        key: 'FormFactors',
        access: true
      },
      {
        "name": "Design Catalog",
        "key": "DesignCatalog",
        "access": true
      },
      {
        "name": "Media",
        "key": "Media",
        "access": true
      },
      {
        "name": "Defaults",
        "key": "Defaults",
        "access": true
      },
      {
        "name": "Edit Variations",
        "key": "Edit_Variations",
        "access": true
      },
      {
        "name": "OutputDesigns",
        "key": "Output_Designs",
        "access": true
      },
      {
        "name": "Edit Badges",
        "key": "Edit_Badges",
        "access": true
      }
    ]
  },
  {
    "name": "Printing",
    "key": "Printing",
    "access": true,
    "Actions": [
      {
        "name": "Defaults",
        "key": "Defaults",
        "access": false
      },
      {
        "name": "Price",
        "key": "Price",
        "access": false
      },
      {
        "name": "Print Station",
        "key": "Print_Station",
        "access": false
      }
    ]
  },
  {
    "name": "Messages",
    "key": "Messages",
    "access": true
  },
  {
    "name": "Logs",
    "key": "Logs",
    "access": true
  },
  {
    "name": "News",
    "key": "News",
    "access": true
  },
  {
    "name": "Settings",
    "key": "Settings",
    "access": true,
    "Actions": [
      {
        "name": "Settings",
        "key": "Preference",
        "access": false
      },
      {
        "name": "Financial Settings",
        "key": "Financial_Settings",
        "access": false
      },
      {
        "name": "Account Management",
        "key": "Account_Management",
        "access": false
      },
      {
        "name": "UserService Management",
        "key": "User_Management",
        "access": false
      },
      {
        "name": "Credentials",
        "key": "Credentials",
        "access": false
      },
      {
        "name": "Approval worksheet",
        "key": "Approval_worksheet",
        "access": false
      },
      {
        "name": "Final Approval Settings",
        "key": "Final_Approval_Settings",
        "access": false
      }
    ]
  },
  {
    "name": "League Tracking",
    "key": "League_Tracking",
    "access": true,
    "Actions": [
      {
        "name": "Division",
        "key": "Division",
        "access": false
      },
      {
        "name": "Rules",
        "key": "Rules",
        "access": false
      },
      {
        "name": "Venue",
        "key": "Venue",
        "access": false
      }
    ]
  },
  {
    "name": "Player Tracking",
    "key": "Player_Tracking",
    "access": true,
    "Actions": [
      {
        "name": "Games",
        "key": "Games",
        "isButton": true,
        "access": true,
        "Actions": [
          {
            "name": "Access",
            "key": "Access",
            "access": false
          },
          {
            "name": "Add Game",
            "key": "Add_Game",
            "access": false
          },
          {
            "name": "Import Games",
            "key": "Import_Games",
            "access": false
          },
          {
            "name": "Edit Game",
            "key": "Edit_Game",
            "access": false
          },
          {
            "name": "Delete Game",
            "key": "Delete_Game",
            "access": false
          }
        ]
      },
      {
        name: "Game Scores",
        key: "Game_Scores",
        isButton: true,
        access: true,
        Actions: [
          {
            "name": "Access",
            "key": "Access",
            "access": false
          },
          {
            "name": "Export To CSV",
            "key": "Export_To_CSV",
            "access": false
          },
          {
            "name": "Edit",
            "key": "Edit",
            "access": false
          },
        ]
      },
      {
        name: "Game Attendance",
        key: "Game_Attendance",
        isButton: true,
        access: true,
        Actions: [
          {
            "name": "Access",
            "key": "Access",
            "access": false
          }
        ]
      },
      {
        name: "Mandatory Play Rule",
        key: "Mandatory_Play_Rule",
        isButton: true,
        access: true,
        Actions: [
          {
            "name": "Access",
            "key": "Access",
            "access": false
          }
        ]
      },
      {
        name: "Rankings",
        key: "Rankings",
        isButton: true,
        access: true,
        Actions: [
          {
            "name": "Access",
            "key": "Access",
            "access": false
          },
          {
            "name": "Export To CSV",
            "key": "Export_To_CSV",
            "access": false
          },
          {
            "name": "Edit Tiebreaks",
            "key": "Edit_Tiebreaks",
            "access": false
          }
        ]
      },
      // {
      //   "name": "Reporting",
      //   "key": "Reporting",
      //   "isButton": true,
      //   "access": true,
      //   "Actions": [
      //     {
      //       "name": "Game Attendance",
      //       "key": "Game_Attendance",
      //       "access": false
      //     },
      //     {
      //       "name": "Mandatory Play Rule",
      //       "key": "Mandatory_Play_Rule",
      //       "access": false
      //     }
      //   ]
      // }
    ]
  },
  {
    "name": "Tools",
    "key": "Tools",
    "access": true,
    "Actions": [ 
      {
        "name": "Surveys",
        "key": "Surveys",
        "access": true
      },
      {
        "name": "Personal Pages",
        "key": "Personal_Pages",
        "access": true
      }
    ]
  }
]

defaultOrgMngrAccessSettings: any[] = [
  {
    "Actions": [
      {
        "name": "Division",
        "key": "Division",
        "access": true
      },
      {
        "name": "Rules",
        "key": "Rules",
        "access": true
      },
      {
        "name": "Venue",
        "key": "Venue",
        "access": true
      }
    ],
    "access": true,
    "name": "League Tracking",
    "key": "League_Tracking"
  },
  {
    "access": true,
    "name": "Logs",
    "key": "Logs"
  },
  {
    "access": true,
    "name": "Messages",
    "key": "Messages"
  },
  {
    "access": true,
    "name": "News",
    "key": "News"
  },
  {
    "Actions": [
      {
        "name": "Add",
        "key": "Add",
        "access": true
      },
      {
        "name": "Delete",
        "key": "Delete",
        "access": true
      },
      {
        "name": "Edit",
        "key": "Edit",
        "access": true
      }
    ],
    "access": true,
    "name": "Organization",
    "key": "Organization"
  },
  {
    "Actions": [
      {
        "name": "Games",
        "key": "Games",
        "access": {
          "Actions": {
            "Add": true,
            "Delete": true
          }
        }
      },
      {
        "name": "Reporting",
        "key": "Reporting",
        "access": {
          "Actions": {
            "Game_Attendance": true,
            "Mandatory_Play_Rule": true
          }
        }
      }
    ],
    "access": true,
    "name": "Player Tracking",
    "key": "Player_Tracking"
  },
  {
    "Actions": [
      {
        "name": "Defaults",
        "key": "Defaults",
        "access": true
      },
      {
        "name": "Price",
        "key": "Price",
        "access": true
      },
      {
        "name": "Print Station",
        "key": "Print_Station",
        "access": true
      }
    ],
    "access": true,
    "name": "Printing",
    "key": "Printing"
  },
  {
    "Actions": [
      {
        "name": "Account Management",
        "key": "Account_Management",
        "access": true
      },
      {
        "name": "Financial Settings",
        "key": "Financial_Settings",
        "access": true
      },
      {
        "name": "Preference",
        "key": "Preference",
        "access": true
      },
      {
        "name": "UserService Management",
        "key": "User_Management",
        "access": true
      }
    ],
    "access": true,
    "name": "Settings",
    "key": "Settings"
  }
]


uid: any;
firstName: string;
lastName: string;
email: string;
role: string;
userRole: string;
subUserData: any;
subUserIndData: any;
userAccessSettings: any;
isSubUser: boolean = false;
textLimit = 35;
public bootedKiosk: boolean = false;
public orgID: string;
public org: any;
public studio: any; // --- if role is studioadmin, you will find value here
public studioID: string;
static orgManagerType: string;
public indKey: string;
public password: string;
public imgApprovalArr = { '': "Not yet reviewed", '1': "Approved", '0': "Rejected", "not yet reviewed": '', "approved": '1', "rejected": '0' }
public imgImportCheckArr = { "not yet reviewed": '', "approved": '1', "rejected": '0', '': "Not yet reviewed", '1': "Approved", '0': "Rejected" }
public commanArr = { 'true': 'yes', 'false': 'no', 'undefined': false }
public commanArrIsIdValid = { 'true': 'active', 'false': 'inactive', 'undefined': false, '1': 'active', '2': 'inactive' };
public commanArrIsIdValidForInd = { 'true': 1, 'false': 2, 'undefined': 2, '1': 1, '2' : 2, '3' : 3, '4' : 4, '5' : 5, '6' : 6, '7' : 7, '8' : 8, '9' : 9, '10' : 10};
public displayIsIdValidForInd = { 'true': '1', 'false': '2', 'undefined': 2, '1': '1', '2' : '2', '3' : '3', '4' : '4', '5' : '5', '6' : '6', '7' : '7', '8' : '8', '9' : '9', '10' : '10'};
public storage = localStorage;
constructor(
  public db: AngularFireDatabase,
  public afAuth: AngularFireAuth,
  private fireStorage: AngularFireStorage,
  public http: HttpClient,
  private encryptionProvider: EncryptionService,
  @Inject(forwardRef(() => CacheService)) public cacheProvider,
  private router: Router
) {
  UserService.orgManagerType = 'District';
}

// Add login history
addOrgLoginHistory(data: any) {
  return this.db.object(`/orgLoginHistory/${Number(moment().format('x'))}/`).update(data);
}

/**
 * Process a login/signup response to store user data
 */
public loggedIn(resp) {
  
  return new Promise(async resolve => {
    this.email = resp.email;
    this.uid = resp.uid;
    let account: any = await this.http.post(`${environment.cloudFunctionsDomain}getAccountInfo`, 
    this.encryptionProvider.encrypt({uid: this.uid, email: this.email}, environment.edk), {responseType: 'text'}).toPromise();
    account = JSON.parse(this.encryptionProvider.decrypt(account, environment.edk));
    
    if(account && account.success == 1) {
      this.role = account.data.role;
      this.orgID = account.data.orgID;
      this.studioID = account.data.studioID;
      await this.setStudioData();
      let userResponse: any = await this.checkIfDataExistInUserManagement(this.uid);
      if (userResponse) {
        this.userRole = userResponse.role;
        this.subUserData = userResponse;
        this.isSubUser = true;
        let accessData: any = await this.getUserAccessSettings(this.role == Role.ORG ? this.orgID : this.studioID, this.userRole);
        if (accessData) this.userAccessSettings = accessData;
      }
      resolve(typeof account.data === 'object' && Object.keys(account.data).length === 0 ? '' : account.data);
    } else {
      resolve("");
    }
  });
}

getUserAccessSettings(orgId: string, roleId: string) {
  return new Promise<void>((resolve, reject) => {
    let abc = this.db.object(`/userManagementRoles/${orgId}/${roleId}/accessSettings`).valueChanges().pipe(debounceTime(1000)).subscribe({
      next: async (data: any) => {
        abc.unsubscribe();
        let orgData;
        if (this.role == Role.ORG) orgData = await this.getOrgData(this.orgID)
        let tempArr: any[] = (this.role == Role.ORG) ? orgData && orgData.type == 'sportsteam' ? this.sportsteamUserPermissionList : this.userPermissionList : this.orgManagerUserPermissionList
        tempArr.forEach(element => {
          if ((data && !data[element.key]) || !data) {
            if (data) {
              data[element.key] = {
                name: element.name,
                access: element.access
              }
            } else {
              data = {
                [element.key]: {
                  name: element.name,
                  access: element.access
                }
              }
            }
            if (data && data[element.key].Actions) {
              element.Actions.forEach(ele => {
                if (!data[element.key].Actions.hasOwnProperty(ele.key)) {
                  data[element.key].Actions[ele.key] = ele.access;
                }
              });
            } else if (element.Actions) {
              element.Actions.forEach(ele => {
                if (data[element.key].Actions) {
                  data[element.key].Actions[ele.key] = ele.access
                } else {
                  data[element.key].Actions = {
                    [ele.key]: ele.access
                  }
                }
              });
            }
          } else {
            if (element.hasOwnProperty('Actions')) {
              element.Actions.forEach(ele => {
                if (ele.hasOwnProperty('Actions')) {
                  if (data[element.key].Actions && data[element.key].Actions[ele.key] && !data[element.key].Actions[ele.key].hasOwnProperty('access')) {
                    data[element.key].Actions[ele.key].access = ele.access;
                  }
                  ele.Actions.forEach(detailEle => {
                    if (!data[element.key].Actions) {
                      data[element.key].Actions = {
                        [ele.key]: {
                          Actions: {
                            [detailEle.key]: detailEle.access
                          }
                        }
                      }
                    } else if (!data[element.key].Actions[ele.key]) {
                      data[element.key].Actions[ele.key] = {
                        'Actions': {
                          [detailEle.key]: detailEle.access
                        }
                      }
                    } else if (!data[element.key].Actions[ele.key].Actions) {
                      data[element.key].Actions[ele.key].Actions = {
                        [detailEle.key]: detailEle.access
                      }
                    } else if (!data[element.key].Actions[ele.key].Actions[detailEle.key]) {
                      data[element.key].Actions[ele.key].Actions[detailEle.key] = detailEle.access;
                    }
                  })
                } else {
                  if (data[element.key].Actions && data[element.key].Actions.hasOwnProperty(ele.key)) {
                    data[element.key].Actions[ele.key] = data[element.key].Actions[ele.key]
                  } else {
                    if (data.hasOwnProperty(element.key) && data[element.key].hasOwnProperty('Actions')) {
                      data[element.key].Actions[ele.key] = ele.access
                    } else {
                      data[element.key].Actions = {
                        [ele.key]: ele.access
                      }
                    }
                  }
                }
              });
            }
          }
        });
        resolve(data ? data : '');
      },
      error: (err) => {
        resolve();
      }
    })
  })
}

getSubUserSecData(orgId: string, subUserDataKey: string) {
  return new Promise((resolve, reject) => {
    let secData: any = this.db.object(`/organizations-sec/${orgId}/users/${subUserDataKey}/email`).valueChanges().pipe(take(1)).toPromise()
    resolve(secData);
  })
}

getStudioSubUserSecData(studioId: string, subUserDataKey: string) {
  return new Promise((resolve, reject) => {
    let secData: any = this.db.object(`/studios-sec/${studioId}/users/${subUserDataKey}/email`).valueChanges().pipe(take(1)).toPromise()
    resolve(secData);
  })
}

getDataFromUserManagement(userKey: string) {
  return new Promise((resolve, reject) => {
    let userManagementData = this.db.object(`/userManagement/${userKey}`).valueChanges().pipe(take(1)).toPromise()
    resolve(userManagementData);
  })
}

checkIfDataExistInUserManagement(authId: string) {
  return new Promise<void>((resolve, reject) => {
    this.db.object(`/userManagement/${authId}`).valueChanges().subscribe({
      next: (res: any) => {
        if (res) {
          resolve({ ...res, key: authId });
        } else {
          resolve();
        }
      },
      error: (err: any) => {

        resolve();
      }
    })
  })
}

  async setStudioData(studioObj?) {
    if (studioObj) {
      this.studio = studioObj;
      if (this.studio.orgManagerType) {
        let orgMgrTypes = (await this.db.list('/org-manager-types').query.once('value')).val();
        if (orgMgrTypes) this.studio.orgManagerTypeName = orgMgrTypes[this.studio.orgManagerType].name;
      }
      if (studioObj.key) {
        this.setStudioLogo(studioObj.key)
      }
    } else {
      let snapshot = await this.db.object(`/studios/${this.studioID}`).query.once('value');
      let studioData = snapshot.val();
      if (studioData) {
        studioData['key'] = this.studioID;
        // --- set cache
        this.cacheProvider.set(CacheKeys.StudioData, this.studioID, studioData);
        await this.setStudioData(studioData);
      }
    }
  }

  async setStudioLogo(studioID: string) {
    if (!this.studioID) {
      return;
    }

    let studioLogoRef = "photos/studios/" + studioID + "/studioLogo";
    const imageRef = this.fireStorage.ref(studioLogoRef);
    const subscription = imageRef
      .getDownloadURL()
      .toPromise()
      .then(url => {
        this.studio.studioLogoUrl = url;
      })
      .catch((err) => {
      });
  }

public getOrgManagerTyps(): Promise<any[]> {
  return new Promise((resolve, reject) => {
    let sub = this.getOrgManagetTypes().subscribe({
      next: orgMgrTypes => {
        if (sub) sub.unsubscribe();
        return resolve(orgMgrTypes);
      }
    })
  })
}

getOrgManagetTypes() {
  return this.db.list('/org-manager-types').snapshotChanges().pipe(
    map(changes =>
      changes.map((c: any) => ({ key: c.payload.key, ...c.payload.val() }))
    )
  )
}
public getOrgmanagerTypes() {
  this.getOrgManagetTypes().subscribe()
}
textEllipse(tag: string) {
  let txtArr: any = tag.split(" ");
  if (txtArr.length && txtArr.length > this.textLimit) {
    txtArr.length = this.textLimit;
    return txtArr.join(" ") + "...";
  } else {
    return tag;
  }
}
getWellFormattedPhone(num: string) {
  num = `${num}`
  if (num.includes("+")) {
    return num.toString().replace(/[- )(]/g, '')
  } else {
    let number = num.toString().replace(/\D/g, "");
    // --- add international prefix
    if (number.length > 10) {
      number = "+" + number;
    } else if (number.length < 10) {
      number = "+1" + number;
    }
    return number;
  }
}
checkPopupIsBlocked(newWin) {
  if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
    alert("Please enable popups for this page.")
  }
}
// ---- check blank, undefined or null value
isBlank(val: string): boolean {
  return !(val != "" && val != undefined && val != null);
}

getCountryCode(number: string) {
  const phoneNumber = parsePhoneNumberFromString(number, 'US')
  if (phoneNumber && phoneNumber.country) {
    return { countryCode: phoneNumber.country, number: phoneNumber.number };
  } else {
    if (phoneNumber && phoneNumber.number) {
      return { countryCode: "US", number: phoneNumber.number };
    } else {
      return { countryCode: "US", number: number };
    }
  }
}
 removeAllUrlParameters() {
  if (window.location.href.indexOf("?") > -1) {
    window.history.pushState(
      "",
      "Title",
      "/" +
      window.location.href
        .substring(window.location.href.lastIndexOf("/") + 1)
        .split("?")[0]
    );
  }
}
public loggedOut() {
  this.email = undefined;
  this.uid = undefined;
}

async doSignOut(reload: boolean, callback?: Function) {
  let pathArr = lodash.cloneDeep(window.location.href).split("/");
  let urlPath = lodash.cloneDeep(window.location.href);
  this.removeAllUrlParameters();
  signOut(getAuth()).then(async () => {
    this.loggedOut();
    this.router.navigate(['/auth'])

    let updateStoragePromises = []
    updateStoragePromises.push(this.storage.removeItem("bootToKiosk"));
    updateStoragePromises.push(this.storage.removeItem("servePhotoIdKiosk"));
    updateStoragePromises.push(this.storage.removeItem("DesignType_Mugshot"));
    updateStoragePromises.push(this.storage.removeItem("inSameTab"));
    updateStoragePromises.push(this.storage.removeItem("bootToKioskDetails"));
    updateStoragePromises.push(this.storage.removeItem("loggedInAt"));
    updateStoragePromises.push(this.storage.removeItem("loginVerifiedAt"));
    updateStoragePromises.push(this.storage.removeItem("photoKioskPrefs"))
    await Promise.all(updateStoragePromises)

    // --- remove quick start progress value from local storage
    localStorage.removeItem('quickstartProgress');

    localStorage.removeItem('h5:internal');
    localStorage.removeItem('h5:trace');

    if (reload === true) {
      this.getCurrentDomainPath();
    } else {
      if (callback) {
        callback();
      }
    }
  });
}
guid(len) {
  var buf = [],
    chars = '0123456789',
    charlen = chars.length,
    length = len || 32;
  for (var i = 0; i < length; i++) {
    buf[i] = chars.charAt(Math.floor(Math.random() * charlen));
  }
  return buf.join('');
}
checkAssingedOnKey(key) {
  if (key.includes('_')) {
    key = key.substring(0, key.length - 5)
  }
  return key;
}

getCurrentDomainPath() {
  if (location.host.includes('high5.id') || location.host.includes('localhost')) {
    let url: any = location.protocol + '//' + location.host + '/';
    console.log('url: ', url);
    window.location = url;
  } else {
    location.reload();
  }
}

createOrg(type: string, schoolName: string, studioID?: string) {
  return new Promise(resolve => {
    let subscription = this.db
      .object("/settings/defaults/")
      .valueChanges()
      .subscribe({
        next: (defaults: any) => {
          subscription.unsubscribe();

          // prepare settings
          const orgSettings: any = lodash.clone(defaults);
          orgSettings.cards = defaults.cards[type];

          this.db
            .list("/organizations/")
            .push("toAdd")
            .then(newOrg => {
              let orgID = newOrg.key;

              this.db.object("/organizations/" + orgID).set(null);

              let fullOrgID: any = schoolName
                .toLowerCase()
                .replace(/\W/g, "")
                .split(" ");
              fullOrgID = fullOrgID.join("_") + "_" + orgID;

              this.db
                .object("/organizations/" + fullOrgID)
                .set({
                  orgName: schoolName,
                  studioID: studioID ? studioID : null,
                  settings: orgSettings,
                  type: type
                })
                .then(() => {
                  resolve(fullOrgID);
                });
            });
        }
      });
  });
}

/**
 * get list of all organizations
 */
getAllOrgs(): Promise<any[]> {
  return new Promise((resolve, reject) => {
    let sub = this.monitorOrganisations(false, this.role == Role.STUDIO ? this.studioID : undefined, (organizations) => {
      if (sub) sub.unsubscribe();
      return resolve(organizations);
    }, this.role == Role.STUDIO ? false : true)
  })
}

monitorOrganisations(
  requestImages: boolean,
  studioID: any,
  callback: Function,
  roleOptional?: boolean
): Subscription {
  var subscription: Subscription;
  let role = this.role;
  if (roleOptional) {
    role = Role.SUPERADMIN;
  }
  if (role == Role.ORG) {
    callback([]);
  } else if (studioID === undefined && role == Role.SUPERADMIN) {
    subscription = this.db.list("/organizations/").snapshotChanges().pipe(debounceTime(1000)).subscribe({
      next: obj => {
        let list = [];
        lodash.each(obj, entry => {
          let value: any = {
            key: entry.key,
            value: entry.payload.val()
          };
          if (value.value["createdAt"]) {
            if (parseInt(value.value.createdAt) == value.value.createdAt) {
              value.value["createdAt"] = moment(parseInt(value.value.createdAt)).format("YYYY MMMM DD");
            }
          }
          let orgData: any = value.value;

          if (
            requestImages === true &&
            orgData.settings &&
            orgData.settings.visuals &&
            orgData.settings.visuals.logo
          ) {
            try {
              this.fireStorage.ref(orgData.settings.visuals.logo).getDownloadURL().toPromise().then(res => {
                orgData.settings.visuals.logo = res;
              }, err => {
              }).catch(res => {
              })
            } catch (e) {
            }
          }

          list.push(value);
        });

        callback(list);
      }
    });
  } else if ((role == Role.STUDIO) && ((this.studio.orgManagerTypeName == 'adminOrgManager') || studioID)) {
    subscription = this.db.list("/organizations/", ref => ref.orderByChild("studioID")).snapshotChanges().pipe(debounceTime(1000)).subscribe({
      next: (arr: any) => {
        let list = [];

        lodash.each(arr, entry => {
          let value: any = {
            key: entry.key,
            value: entry.payload.val()
          };
          let obj = value.value;
          if (obj.parentList && !lodash.isEmpty(obj.parentList)) {
            if (obj.parentList && !lodash.isEmpty(obj.parentList) && obj.parentList[studioID] == true) {
              if (value.value["createdAt"]) {
                if (parseInt(value.value.createdAt) == value.value.createdAt) {
                  value.value["createdAt"] = moment(parseInt(value.value.createdAt)).format("YYYY MMMM DD")
                }
              }
              let orgData: any = value.value;
              if (
                requestImages === true &&
                orgData.settings &&
                orgData.settings.visuals &&
                orgData.settings.visuals.logo
              ) {
                try {
                  this.fireStorage.ref(orgData.settings.visuals.logo).getDownloadURL().toPromise().then(res => {
                    orgData.settings.visuals.logo = res;
                  }, err => {
                  }).catch(e => {
                  })
                } catch (e) {
                }
              }

              list.push(value);
            } else if (obj.studioID == studioID) {
              let value: any = {
                key: entry.key,
                value: entry.payload.val()
              };
              if (value.value["createdAt"]) {
                if (parseInt(value.value.createdAt) == value.value.createdAt) {
                  value.value["createdAt"] = moment(parseInt(value.value.createdAt)).format("YYYY MMMM DD")
                }
              }
              let orgData: any = value.value;
              if (
                requestImages === true &&
                orgData.settings &&
                orgData.settings.visuals &&
                orgData.settings.visuals.logo
              ) {
                try {
                  this.fireStorage.ref(orgData.settings.visuals.logo).getDownloadURL().toPromise().then(res => {
                    orgData.settings.visuals.logo = res;
                  }, err => {
                  }).catch(e => {
                  })
                } catch (e) {
                }
              }

              list.push(value);
            }

          }

        });

        callback(list);
      }
    });
  } else if (role == Role.STUDIO && !studioID) {
    callback([]);
  } else {
    callback([])
  }
  return subscription;
}

// --- get org type name using id
getOrgTypeName(typeId, callback: Function) {
  this.db.list(`/org-types/`, ref => ref.orderByChild("id").equalTo(parseInt(typeId))).snapshotChanges().subscribe({
    next: res => {
      if (res.length > 0) {
        lodash.each(res, entry => {
          const value: any = entry.payload.val();
          callback(value);
        })
      } else {
        callback(null);
      }
    }
  })
}

async removeOrganization(
  orgID: string,
  providers: {
    individualsProvider: IndividualService,
    individualsApiProvider: IndividualApiService,
    authProvider: AuthService,
    orgProvider: OrganizationService,
    commonFun: CommonServiceService
  },
  isCurrentUserStudio: boolean = false
) {
  if(!orgID)
    throw new Error("Organization ID missing in params!");

  let authDependencies: AuthDependency[] = [];
  if(isCurrentUserStudio) authDependencies.push({
    forRule: "static",
    requires: "userAccountInfo",
  })

  // --- read org individuals
  let individuals: any = await providers.individualsProvider.monitorIndividualsSub(orgID)
  .pipe(take(1))
  .toPromise()
  
  // --- add bulk deletion flag (to prevent listener from deleting ind firebase users)
  // additionally, prepare uniq uids list to remove users from firebase in bulk
  let indsUpdateObj = {};
  let uniqUIDs = [];
  let userUIDs = []; // admin & subusers uid array
  lodash.each(individuals, ind => {
    indsUpdateObj[`${ind._key}/isBulkDelete`] = true;
    uniqUIDs.push(ind.uid)

    if(ind.isMainAdmin || providers.individualsApiProvider.isSubUser(ind)) {
      userUIDs.push(ind.uid)
    }
  })
  if (!lodash.isEmpty(indsUpdateObj))
    await providers.individualsApiProvider.bulkUpdateIndividuals(
      orgID,
      indsUpdateObj,
      undefined,
      true
    );

  // --- prepare uniq UIDs list to be deleted
  uniqUIDs = lodash.chain(uniqUIDs).uniq().compact().value();
  userUIDs = lodash.chain(userUIDs).uniq().compact().value();

  let removeOrgDataPromises = [];

  // --- remove org ind firebase users
  removeOrgDataPromises.push(
    providers.authProvider.bulkRemoveUsers(uniqUIDs, authDependencies)
  )

  // --- remove users data (for admin & subusers)
  let removeUsersPromises = lodash
    .chain(userUIDs)
    .map(uid => {
      if(!uid) return null
      this.db.object(`users/${uid}/account`).remove()
    })
    .compact()
    .value();
  removeOrgDataPromises.push(Promise.all(removeUsersPromises))

  // --- remove licenses
  removeOrgDataPromises.push(
    this.db.object(`licenses/instances/${orgID}`).remove()
  )

  // --- remove bundle history
  removeOrgDataPromises.push(
    this.db.object(`/licenses/orgBundleHistory/${orgID}`).remove()
  )

  // --- remove relations
  removeOrgDataPromises.push(
    this.db.object(`/relations/${orgID}`).remove()
  )
  
  // --- remove individuals
  removeOrgDataPromises.push(
    this.db.object(`individuals/${orgID}`).remove()
  )

  // --- remove user management roles
  removeOrgDataPromises.push(
    this.db.object(`userManagementRoles/${orgID}`).remove()
  )

  // --- remove archived inds
  removeOrgDataPromises.push(
    this.db.object(`individuals-archived/${orgID}`).remove()
  )

  // --- remove transactions
  removeOrgDataPromises.push(
    this.db.object(`Transactions/${orgID}`).remove()
  )

  // --- remove render jobs
  removeOrgDataPromises.push(
    this.db.object(`render-jobs/${orgID}`).remove()
  )

  // --- remove export jobs
  removeOrgDataPromises.push(
    this.db.object(`export-jobs/${orgID}`).remove()
  )

  // --- remove organization sec node data
  removeOrgDataPromises.push(
    this.db.object(`organizations-sec/${orgID}`).remove()
  )

  // --- remove organization data 
  // (exception is, if current user is studio, delete organization data after deleting other stuff as studio's authorization depends on org data)
  if(!isCurrentUserStudio) {
    removeOrgDataPromises.push(
      this.db.object(`organizations/${orgID}`).remove()
    )
  }

  // --- remove org logo
  let orgLogoPath = `/photos/organisations/${orgID}/orgLogo`
  removeOrgDataPromises.push(
    providers.commonFun.executePromise(
      this.fireStorage.ref(orgLogoPath).delete().toPromise()
    )
  )

  // --- remove ind images
  removeOrgDataPromises.push(
    providers.orgProvider.removeOrgIndImages(orgID, authDependencies)
  )

  let results = await Promise.all(removeOrgDataPromises.map(p => p.catch(e => e)))
  let isAnyErr = lodash.some(results, r => r instanceof Error)
  if(isAnyErr)
    console.log("Some error while deleting org", results)
  else
    console.log("Org deleted successfully!")

  // --- remove organization data
  if(isCurrentUserStudio)
    await this.db.object(`organizations/${orgID}`).remove()

  return true;
}


deleteOrganization(orgID: string) {
  return this.db.object("/organizations/" + orgID).set(null);
}

getOrgData(orgId: string) {
  return new Promise((resolve, reject) => {
    this.db.object(`/organizations/${orgId}`).valueChanges().subscribe({
      next: res => {
        resolve(res);
      }
    })
  })
}

/**
 * get list of all org managers
 */
getAllOrgMgrs(): Promise<any[]> {
  return new Promise((resolve, reject) => {
    let sub = this.monitorStudios(false, (studios) => {
      if (sub) sub.unsubscribe();
      return resolve(studios);
    }, true)
  })
}

// --- get studio property
async getStudioProp(studioID, prop) {
  if(!studioID || !prop) return null;

  let snapshot = await this.db.object(`studios/${studioID}/${prop}`).query.once('value')
  return snapshot.val()
}

monitorStudios(requestImages: boolean, callback: Function, isRequestedFomOrg?): Subscription {
  var subscription: Subscription;
  if (this.role == Role.ORG && !isRequestedFomOrg) callback([]);
  else if (this.role == Role.SUPERADMIN || this.role == Role.STUDIO || isRequestedFomOrg) {
    subscription = this.db
      .list("/studios/")
      .snapshotChanges()
      .subscribe({
        next: obj => {
          let list = [];

          lodash.each(obj, entry => {
            let value: any = {
              key: entry.key,
              value: entry.payload.val()
            };
            if (value.value["createdAt"]) {
              if (parseInt(value.value.createdAt) == value.value.createdAt) {
                value.value["createdAt"] = moment(parseInt(value.value.createdAt)).format("YYYY MMMM DD")
              }
            }

            const studioData: any = value.value;

            if (requestImages === true && studioData.studioLogo) {
              try {
                this.fireStorage.ref(studioData.studioLogo).getDownloadURL().toPromise().then(res => {
                  studioData.studioLogo = res;
                }, err => {
                }).catch(err => {
                })
              } catch (e) {
              }
            }

            list.push(value);
          });

          callback(list);
        }
      });
  } else {
    callback([]);
  }
  return subscription;
}

monitorFotoMerchantStudios(requestImages: boolean, orgMngrId: string, callback: Function, isRequestedFomOrg?): Subscription {
  var subscription: Subscription;
  if (this.role == Role.ORG && !isRequestedFomOrg) callback([]);
  else if (this.role == Role.SUPERADMIN || this.role == Role.STUDIO || isRequestedFomOrg) {
    subscription = this.db.list("/studios/", ref => ref.orderByChild('parentID').equalTo(orgMngrId)).snapshotChanges().subscribe({
      next: obj => {
      let list = [];
      lodash.each(obj, entry => {
        let value: any = {
          key: entry.key,
          value: entry.payload.val()
        };
        if (value.value["createdAt"]) {
          if (parseInt(value.value.createdAt) == value.value.createdAt) {
            value.value["createdAt"] = moment(parseInt(value.value.createdAt)).format("YYYY MMMM DD")
          }
        }
        const studioData: any = value.value;
        if (requestImages === true && studioData.studioLogo) {
          try {
            this.fireStorage.ref(studioData.studioLogo).getDownloadURL().toPromise().then(res => {
              studioData.studioLogo = res;
            }, err => {
            }).catch(err => {
            })
          } catch (e) {
          }
        }
        list.push(value);
      });
      callback(list);
    }});
  } else {
    callback([]);
  }
  return subscription;
}

monitorOrgManagerUpdate(orgID, callback: Function) {
  let subscribe = this.db
    .object("organizations/" + orgID + "/" + "studioID")
    .valueChanges()
    .subscribe({
      next: (value: any) => {
      if (lodash.isFunction(callback)) {
        callback(value);
      }
    }});
}

removeAppLoading() {
  // --- remove vip pass app loader
  $("#vippass-loading").fadeOut("slow");
}

static orgTagList() {
  let arr = ['bootedKiosk', 'email', 'uid', 'role', 'orgID', 'studioID', 'firstName', 'lastName', 'orgName', 'shortID', 'type', 'displayName', 'cycle', 'logoURL',
    'Deadline_YearbookPortraits', 'YearbookAdvisor_Name', 'YearbookAdvisor_eMail', 'YearbookAdvisor_Phone', 'YearbookAdvisor_Location',
    'URL_OrganizationWebSite', 'URL_OrgLink_SIS', 'URL_OrgLink_Teammate', 'URL_OrgLink_Additional',
    'Shipping_Address_1', 'Shipping_Address_2', 'Shipping_City', 'Shipping_StateProv', 'Shipping_ZipPostCode',
    'Shipping_Country', 'Billing_Address_1', 'Billing_Address_2', 'Billing_City', 'Billing_StateProv', 'Billing_ZipPostCode',
    'Billing_Country', 'Phone_Main', 'shortID', "motto", "principal", "photoURL", 'logoColorPrimary', 'logoColorSecondary', 'incomingSMS'
  ]
  return arr;
}

static tagList() {
  let list = [];
  list.push({ key: 'Ind', property: 'DBkey' });
  list.push({ key: 'Ind', property: 'timestamp_indCreated' });
  list.push({ key: 'Ind', property: 'timestamp_indLastUpdated' });
  list.push({ key: 'Ind', property: 'timestamp_PhotoLastUpdated' });
  list.push({ key: 'Ind', property: 'ASBMember' });
  list.push({ key: 'Ind', property: 'studentID' });
  list.push({ key: 'Ind', property: 'grade' });
  list.push({ key: 'Ind', property: 'photoUrl' });
  list.push({ key: 'Ind', property: 'accessCampus_mode' });
  list.push({ key: 'Ind', property: 'group' });
  list.push({ key: 'Ind', property: 'department' });
  list.push({ key: 'Ind', property: 'title' });
  list.push({ key: 'Ind', property: 'custom1' });
  list.push({ key: 'Ind', property: 'custom2' });
  list.push({ key: 'Ind', property: 'custom3' });
  list.push({ key: 'Ind', property: 'lockerNumber' });
  list.push({ key: 'Ind', property: 'lockerCombination' });

  list.push({ key: UserService.orgManagerType, property: 'admin_email' });
  list.push({ key: UserService.orgManagerType, property: 'admin_name' });
  list.push({ key: UserService.orgManagerType, property: 'support_name' });
  list.push({ key: UserService.orgManagerType, property: 'support_email' });
  list.push({ key: UserService.orgManagerType, property: 'support_Phone' });
  list.push({ key: UserService.orgManagerType, property: 'salesTaxID' });
  list.push({ key: UserService.orgManagerType, property: 'displayName' });
  list.push({ key: UserService.orgManagerType, property: 'website' });
  list.push({ key: UserService.orgManagerType, property: 'logoURL' });
  list.push({ key: UserService.orgManagerType, property: 'adminPhone' });

  list.push({ key: 'Crossing', property: 'timestamp_entry' });
  list.push({ key: 'Crossing', property: 'VisitType' });
  list.push({ key: 'Crossing', property: 'VisitorType' });
  list.push({ key: 'Crossing', property: 'Reason' });
  list.push({ key: 'Crossing', property: 'Location' });
  list.push({ key: 'Output', property: 'timestamp' });
  return list;
}

static generateSynonyms = (...sets) => {
  const recursiveCombinations = (ary1, ary2) => {
    return lodash
      .chain(ary1)
      .map(str1 => {
        return lodash
          .chain(ary2)
          .map(str2 => lodash.trim(`${str1} ${str2}`))
          .value()
      })
      .flatten()
      .uniq()
      .value()
  }
  return lodash
    .chain(sets)
    .reduceRight((ac, set) => {
      return recursiveCombinations(set, ac)
    }, [''])
    .compact()
    .value()
}

// helper function to get synonyms
static getSynonyms(field: string) {
  switch(field) {
    case 'firstName': field = 'fname'; break;
    case 'lastName': field = 'lname'; break;

    case 'orgID':
    case 'studentID': 
      field = 'id';
      break;
  }

  return lodash.get(this.synonym, field, []);
}

static synonym = {
  image: ["photo", "image file name", "image name", "img name", "image", "photo name", "image_name", "filename", "image file", "images"],
  grade: ['class', "ChronoGrade", "Chrono grade", 'gr', "grade_level", 'grade', 'student grade level', 'student grade', 'grade level'],
  salutation: ["Salutation", "Salutations", "salutations"],
  fname: ['firstName', 'First Name', 'fname', 'first name', 'given name', 'f name', 'first', "first_name", ...UserService.generateSynonyms(["student"], ["first name", "firstname", "fname"])],
  middleName: ["middleName", "mname", "middle name"],
  lname: ['lastName', "Family Name", "Surname", "last name", 'lname', 'family name', 'familyName', 'l name', 'last', 'last_name', ...UserService.generateSynonyms(["student"], ["last name", "lastname", "lname"])],
  suffix: ["suffix", "Suffix"],
  role: ['role', 'type', 'idtype', 'id type'],
  photoUrl: ['photoUrl', 'Image Url'],
  teacher: ["HR Teacher", "Teacher Name", "HRTeachername", "teachername", "teacher name", "homeroom teacher name", "homeroomteachername"],
  homeRoom: ["Home Room", "homeroom"],
  id: ["studentID", "id", "Student ID", "Student Number","student number", 'SIN', 'studentNumber', 'student#', 'student #', 'studentId', 'org id', 'OrgId', 'org_Id', "Staff number", "StaffNumber", "StaffNum", "Staff Num", "Studentcode", "Student code", "student_number", "online code", "user id", "userid", "sid", "Child ID"],
  externalSource: ["externalSource", "ExternalSource","externalSourceType"],
  actions: ["action", "Action", "Actions", "actions"],
  hash: ['hash', 'dbhash', 'db hash'],
  photoStatus: ['photoApprovalStatus', 'Image status'],
  email: ['Email ID', 'emailID', 'email', 'e-mail', 'email address', 'email ID', 'student email', 'StudentEmail', 'preferred_email', 'preferred email', 'preferredemail'],
  mobilePhone: ['mobile', 'cell', 'mobilePhone', 'mobile Phone', 'phone', 'cellphone', 'cell phone', 'cell phone', 'cell phone number'],
  guardianPhone: ['Primary phone', "ParentPhone", "Parent Phone", 'Primary phone 1', "Parent 1 Phone", "ParentPhone1", "Parent Phone 1", "guardian phone", "GuardianPhone", "Guardian phone 1", "Guardian 1 phone", "Guardian Telephone", "GuardianTelephone", "GuardianTelephone1", "guardian telephone 1", "guardian 1 telephone", "Guardian mobile", "GuardianMobile", "GuardianMobile1", "Guardian mobile 1", "Guardian 1 mobile", "phone1", "phone 1", "home phone", "parent phone (1)"],
  guardianPhone2: ['Primary phone 2', "ParentPhone2", "Parent Phone 2", "Parent 2 Phone", "GuardianPhone2", "Guardian phone 2", "Guardian 2 phone", "Guardian Telephone 2", "GuardianTelephone2", "guardian 2 telephone", "Guardian mobile 2", "GuardianMobile2", "Guardian 2 mobile", "phone2", "phone 2", "parent phone (2)"],
  guardianPhone3: ['Primary phone 3', "ParentPhone3", "Parent Phone 3", "Parent 3 Phone", "GuardianPhone3", "Guardian phone 3", "Guardian 3 phone", "Guardian Telephone 3", "GuardianTelephone3", "guardian 3 telephone", "Guardian mobile 3", "GuardianMobile3", "Guardian 3 mobile", "phone3", "phone 3", "parent phone (3)"],
  guardianPhone4: ['Primary phone 4', "ParentPhone4", "Parent Phone 4", "Parent 4 Phone", "GuardianPhone4", "Guardian phone 4", "Guardian 4 phone", "Guardian Telephone 4", "GuardianTelephone4", "guardian 4 telephone", "Guardian mobile 4", "GuardianMobile4", "Guardian 4 mobile", "phone4", "phone 4", "parent phone (4)"],
  ASBMember: ['ASB Member', 'ASB'],
  govtID: ['DL', 'drivers', 'driversLicense', 'drivers', 'license', 'government ID'],
  admissibilityGroup: ['admissible group', 'Admissible Group', 'Admissibility Group'],
  RFID: ['RFID Code', 'RF ID', 'rfid'],
  schoolDepartment: ['schoolDepartment', 'school Department'],
  campus: ['campus', 'Campus'],
  lunchPeriod: ['lunchPeriod', 'lunch Period','Lunch Period','lunch_Period'],
  birth: ['Birthdate', 'birthday', 'Date of birth', 'DateOfBirth', 'birth', 'birth day', 'birthday', "DOB"],
  company: ['company', 'company name', 'employer'],
  hairColor: ['hairColor', 'Hair Color', 'haircolor'],
  eyeColor: ['Eye Color', 'eye color', 'eyecolor'],
  indID_Client: ['Client indID', 'ClientIndID', 'indID_Client', "subjectID", "subject ID", 'internal id', "client id", "clientid"],
  title: ["Title/Position", "Position", "Job title"],
  guardianEmail: ["ParentEmail", "Parent Email", "ParentEmail1", "Parent Email 1", "Parent 1 Email", "ParentMail", "Parent mail", "ParentMail1", "Parent mail 1", "Parent 1 mail", "GuardianEmail", "Guardian Email", "GuardianEmail1", "Guardian Email 1", "Guardian 1 Email", "guardian mail", "guardian 1 mail", "guardian mail 1", "email1", "parent email (1)"],
  guardianEmail2: ["ParentEmail2", "Parent Email 2", "Parent 2 Email", "ParentMail2", "Parent mail 2", "Parent 2 mail", "GuardianEmail2", "Guardian Email 2", "Guardian 2 Email", "guardian mail 2", "guardian 2 mail", "email2", "parent email (2)"],
  guardianEmail3: ["ParentEmail3", "Parent Email 3", "Parent 3 Email", "ParentMail3", "Parent mail 3", "Parent 3 mail", "GuardianEmail3", "Guardian Email 3", "Guardian 3 Email", "guardian mail 3", "guardian 3 mail", "email3", "parent email (3)"],
  guardianEmail4: ["ParentEmail4", "Parent Email 4", "Parent 4 Email", "ParentMail4", "Parent mail 4", "Parent 4 mail", "GuardianEmail4", "Guardian Email 4", "Guardian 4 Email", "guardian mail 4", "guardian 4 mail", "email4", "parent email (4)"],
  key: ["key", "db key", "dbkey", "DBKey"],
  guardianFullName: ['Guardian name', 'GuardianName', 'Guardian 1 name', 'Parent Name', 'Parent 1 name', 'ParentName', 'Parent/Guardian', 'Guardian/Parent', 'Father', 'Mother', 'Guardian name 1', 'GuardianName1', 'Parent Name 1', 'ParentName1', 'Parent/Guardian 1', 'Guardian/Parent 1', 'Father1', 'Mother1'],
  guardianFullName2: ['Guardian name 2', 'Guardian 2 name', 'GuardianName2', 'Parent Name 2', 'Parent 2 Name', 'ParentName2', 'Parent/Guardian 2', 'Guardian/Parent 2', 'Father2', 'Mother2'],
  guardianFullName3: ['Guardian name 3', 'Guardian 3 name', 'GuardianName3', 'Parent Name 3', 'Parent 3 Name', 'ParentName3', 'Parent/Guardian 3', 'Guardian/Parent 3', 'Father3', 'Mother3'],
  guardianFullName4: ['Guardian name 4', 'Guardian 4 name', 'GuardianName4', 'Parent Name 4', 'Parent 4 Name', 'ParentName4', 'Parent/Guardian 4', 'Guardian/Parent 4', 'Father4', 'Mother4'],
  guardian1FirstName: UserService.generateSynonyms(["guardian", "parent", "father", "mother"], ["", "1"], ["firstname", "first name", "givenname", "given name"], ["", "(1)"]),
  guardian2FirstName: UserService.generateSynonyms(["guardian", "parent", "father", "mother"], ["2"], ["firstname", "first name", "givenname", "given name"], ["", "(2)"]),
  guardian3FirstName: UserService.generateSynonyms(["guardian", "parent", "father", "mother"], ["3"], ["firstname", "first name", "givenname", "given name"], ["", "(3)"]),
  guardian4FirstName: UserService.generateSynonyms(["guardian", "parent", "father", "mother"], ["4"], ["firstname", "first name", "givenname", "given name"], ["", "(4)"]),
  guardian1LastName: UserService.generateSynonyms(["guardian", "parent", "father", "mother"], ["", "1"], ["lastname", "last name", "familyname", "family name"], ["", "(1)"]),
  guardian2LastName: UserService.generateSynonyms(["guardian", "parent", "father", "mother"], ["2"], ["lastname", "last name", "familyname", "family name"], ["", "(2)"]),
  guardian3LastName: UserService.generateSynonyms(["guardian", "parent", "father", "mother"], ["3"], ["lastname", "last name", "familyname", "family name"], ["", "(3)"]),
  guardian4LastName: UserService.generateSynonyms(["guardian", "parent", "father", "mother"], ["4"], ["lastname", "last name", "familyname", "family name"], ["", "(4)"]),
  auxID: ['Auxiliary ID', 'Aux ID'],
  photoYear: ['photoYear', 'PhotoYear', 'Photo Year', 'Image Year', 'imageYear', 'year', "cycle"],
  jerseyNumber: ['jerseyNumber', 'jersey', 'jersey #', '#', 'shirt number', 'shirt #', "jersey number"],
  yearbookCollected: ['yearbookCollected','yearbookCollect', 'yearbook collect', 'yearbook collected', "yearbook Collect", "annual collected","annual collect", "annual collected", "annualCollected", "annualCollect"],
  yearbookOrdered: ['yearbookOrdered','yearbookOrder', 'yearbook ordered', "yearbook Ordered", "annual ordered", "annual ordered", "annualOrdered", "annualOrdered"],
  yearbookPaid: ['yearbookPaid', 'yearbook paid', "yearbook Paid", "annual Paid","annual paid", "annualPaid"],

  eventCode: ['Event Code', 'Event', 'EventCode', 'EventID', 'Event ID', 'ShowCode', 'Show Code', 'Game', 'GameCode', 'Game Code', 'GameID', 'Game ID'],
  ticketCode: ['Ticket Code', 'TicketCode', 'TicketType', 'Ticket Type', 'Ticket Class', 'TicketClass'],
  addressPart1: ['addressPart1', 'address1', 'Address 1', "Address1", "Address line 1"],
  addressPart2: ['addressPart2', 'address2', 'Address 2', "Address2", "Address line 2"],
  postalCode: ['postalCode', 'postal code', 'zip code', 'zip', "zipcode", "post code", "postcode", "Zip/Postal Code", "Zip/post code", "zip/postcode", "zip/postalcode", "Zip / Postal Code", "Zip / post code", "zip / postcode", "zip / postalcode"],
  city: ['city', 'address_city', "City", "Address_City"],
  state: ['state', 'State', 'region', "Region"],
  country: ['country', 'Country'],
  password: ['password', "security"],
  booleanTrueValue: ["y", "yes", "true"],
  custom1: ['custom1', 'custom data 1', 'customdata1'],
  custom2: ['custom2', 'custom data 2', 'customdata2'],
  custom3: ['custom2', 'custom data 3', 'customdata3'],
  
  accessBus_mode: ["accessBus_mode", "accessbusmode", "accessBusMode", "access Bus mode", "access_Bus_mode", "Access Bus mode",],
  accessBus_endDateTime: ['access Bus End Date Time', 'accessBus endDateTime', 'accessBus_EndDateTime','accessBus_endDateTime','accessbusenddatetime','accessbus_enddatetime','access_Bus_endDateTime','access_Bus_end_Date_Time', 'Access Bus EndDateTime'],
  accessBus_startDateTime: ['access Bus Start Date Time', 'accessBus startDateTime', 'accessBus_StartDateTime','accessBus_startDateTime','accessbusstartdatetime','accessbus_startdatetime','access_Bus_startDateTime','access_Bus_start_Date_Time', 'Access Bus StartDateTime'],
  accessBus_validAfterDateTime: ['access Bus Valid After Date Time', 'accessBus validafterDateTime', 'accessBus_validAfterDateTime','accessBus_ValidAfterDateTime','accessbusvalidafterdatetime','accessbus_validafterdatetime','accessBus_Valid_AfterDateTime','access_Bus_Valid_After_Date_Time', 'Access Bus ValidAfterDateTime'],
  accessBus_invalidAfterDateTime: ['access Bus Invalid After Date Time', 'accessBus invalidafterDateTime', 'accessBus_invalidAfterDateTime','accessBus_InvalidAfterDateTime','accessbusinvalidafterdatetime','accessbus_invalidafterdatetime','accessBus_Invalid_AfterDateTime','access_Bus_Invalid_After_Date_Time', 'Access Bus InvalidAfterDateTime'],
  
  accessCampus_mode: ["accessCampus_mode", "accesscampusmode", "accessCampusMode", "access Campus mode", "access_Campus_mode", "Access Campus mode", "Admissible in person mode", "Admissible in Person Mode", "admissibleinpersonmode", "AdmissibleInPerson", "admissibleInPerson", "admissibleinperson"],
  accessCampus_endDateTime: ['access Campus End DateTime', 'accessCampus endDateTime', 'accessCampus_EndDateTime','accessCampus_endDateTimeTime','accesscampusenddatetime','accesscampus_enddatetime','access_Campus_endDateTime','access_Campus_end_Date_Time', 'Access Campus EndDateTime', "Admissible in person endDateTime", "Admissible in Person EndDateTime", "admissibleinpersonenddatetime"],
  accessCampus_startDateTime: ['access Campus Start Date Time', 'accessCampus startDateTime', 'accessCampus_StartDateTime','accessCampus_startDateTimeTime','accesscampusstartdatetime','accesscampus_startdatetime','access_Campus_startDateTime','access_Campus_start_Date_Time', 'Access Campus StartDateTime', "Admissible in person startDateTime", "Admissible in Person StartDateTime", "admissibleinpersonstartdatetime"],
  accessCampus_validAfterDateTime: ['access Campus Valid After Date Time', 'accessCampus validafterDateTime', 'accessCampus_validAfterDateTime','accessCampus_ValidAfterDateTime','accesscampusvalidafterdatetime','accesscampus_validafterdatetime','accessCampus_Valid_AfterDateTime','access_Campus_Valid_After_Date_Time', 'Access Campus ValidAfterDateTime', "Admissible in person ValidAfterDateTime", "Admissible in Person validafterdatetime", "admissibleinpersoncalidafterdatetime"],
  accessCampus_invalidAfterDateTime: ['access Campus Invalid After Date Time', 'accessCampus invalidafterDateTime', 'accessCampus_invalidafterDateTime','accessCampus_InvalidAfterDateTime','accesscampusinvalidafterdatetime','accesscampus_invalidafterdatetime','accessCampus_Invalid_AfterDateTime','access_Campus_Invalid_After_Date_Time', 'Access Campus InvalidAfterDateTime', "Admissible in person InvalidAfterDateTime", "Admissible in Person invalidafterdatetime", "admissibleinpersoninvalidaterdatetime"],
  
  accessCafeteria_mode: ["accessCafeteria_mode", "accesscafeteriamode", "accessCafeteriaMode", "access Cafeteria mode", "access_Cafeteria_mode", "Access Cafeteria mode", 'Lunch period mode', 'Lunch Period Mode'],
  accessCafeteria_endDateTime: ['access Cafeteria End DateTime', 'accessCafeteria endDateTime', 'accessCafeteria_EndDateTime','accessCafeteria_endDateTimeTime','accesscafeteriaenddatetime','accesscafeteria_enddatetime','access_Cafeteria_endDateTime','access_Cafeteria_end_Date_Time', 'Access Cafeteria EndDateTime', 'Lunch period endDateTime', 'Lunch Period EndDateTime'],
  accessCafeteria_startDateTime: ['access Cafeteria Start Date Time', 'accessCafeteria startDateTime', 'accessCafeteria_StartDateTime','accessCafeteria_startDateTimeTime','accesscafeteriastartdatetime','accesscafeteria_startdatetime','access_Cafeteria_startDateTime','access_Cafeteria_start_Date_Time', 'Access Cafeteria StartDateTime', 'Lunch period startDateTime', 'Lunch Period StartDateTime'],
  accessCafeteria_validAfterDateTime: ['access Cafeteria Valid After Date Time', 'accessCafeteria validafterDateTime', 'accessCafeteria_validAfterDateTime','accessCafeteria_ValidAfterDateTime','accesscafeteriavalidafterdatetime','accesscafeteria_validafterdatetime','accessCafeteria_Valid_AfterDateTime','access_Cafeteria_Valid_After_Date_Time', 'Access Cafeteria ValidAfterDateTime', 'Lunch period validAfetrDateTime', 'Lunch Period ValidAfterDateTime'],
  accessCafeteria_invalidAfterDateTime: ['access Cafeteria Invalid After Date Time', 'accessCafeteria invalidafterDateTime', 'accessCafeteria_invalidafterDateTime','accessCafeteria_InvalidAfterDateTime','accesscafeteriainvalidafterdatetime','accesscafeteria_invalidafterdatetime','accessCafeteria_Invalid_AfterDateTime','access_Cafeteria_Invalid_After_Date_Time', 'Access Cafeteria InvalidAfterDateTime', 'Lunch period invalidAfterDateTime', 'Lunch Period InvalidAfterDateTime'],
  
  idValid_mode: ["idValid_mode", "idvalidiamode", "idValidMode", "id Valid mode", "id_Valid_mode", "Id Valid mode", 'IdStatus', 'Id Status', 'IdStatus Mode'],
  idValid_endDateTime: ['id Valid End DateTime', 'idValid endDateTime', 'idValid_EndDateTime','idValid_endDateTimeTime','idvalidiaenddatetime','idvalidia_enddatetime','id_Valid_endDateTime','id_Valid_end_Date_Time', 'IdValid EndDateTime', 'Id Status EndDateTime', 'IdStatus EndDateTime'],
  idValid_startDateTime: ['id Valid Start Date Time', 'idValid startDateTime', 'idValid_StartDateTime','idValid_startDateTimeTime','idvalidiastartdatetime','idvalidia_startdatetime','id_Valid_startDateTime','id_Valid_start_Date_Time', 'IdValid StartDateTime', 'Id Status StartDateTime', 'IdStatus StartDateTime'],
  idValid_validAfterDateTime: ['id Valid Valid After Date Time', 'idValid validafterDateTime', 'idValid_validAfterDateTime','idValid_ValidAfterDateTime','idvalidiavalidafterdatetime','idvalidia_validafterdatetime','idValid_Valid_AfterDateTime','id_Valid_Valid_After_Date_Time', 'IdValid ValidAfterDateTime', 'Id Status ValidAfterDateTime', 'IdStatus ValidAfterDateTime'],
  idValid_invalidAfterDateTime: ['id Valid Invalid After Date Time', 'idValid invalidafterDateTime', 'idValid_invalidafterDateTime','idValid_InvalidAfterDateTime','idvalidiainvalidafterdatetime','idvalidia_invalidafterdatetime','idValid_Invalid_AfterDateTime','id_Valid_Invalid_After_Date_Time', 'IdValid InvalidAfterDateTime', 'Id Status InvalidAfterDateTime', 'IdStatus InvalidAfterDateTime'],
  
  accessSocial_mode: ["accessSocial_mode", "accesssocialmode", "accessSocialMode", "access Social mode", "access_Social_mode", "Access Social mode", 'No-go list mode', 'No-go List Mode'],
  accessSocial_endDateTime: ['access Social End DateTime', 'accessSocial endDateTime', 'accessSocial_EndDateTime','accessSocial_endDateTimeTime','accesssocialenddatetime','accesssocial_enddatetime','access_Social_endDateTime','access_Social_end_Date_Time', 'Access Social EndDateTime', 'No-go list endDateTime', 'No-go List EndDateTime'],
  accessSocial_startDateTime: ['access Social Start Date Time', 'accessSocial startDateTime', 'accessSocial_StartDateTime','accessSocial_startDateTimeTime','accesssocialstartdatetime','accesssocial_startdatetime','access_Social_startDateTime','access_Social_start_Date_Time', 'Access Social StartDateTime', 'No-go list startDateTime', 'No-go List StartDateTime'],
  accessSocial_validAfterDateTime: ['access Social Valid After Date Time', 'accessSocial validafterDateTime', 'accessSocial_validAfterDateTime','accessSocial_ValidAfterDateTime','accesssocialvalidafterdatetime','accesssocial_validafterdatetime','accessSocial_Valid_AfterDateTime','access_Social_Valid_After_Date_Time', 'Access Social ValidAfterDateTime', 'No-go list validAfetrDateTime', 'No-go List ValidAfterDateTime'],
  accessSocial_invalidAfterDateTime: ['access Social Invalid After Date Time', 'accessSocial invalidafterDateTime', 'accessSocial_invalidafterDateTime','accessSocial_InvalidAfterDateTime','accesssocialinvalidafterdatetime','accesssocial_invalidafterdatetime','accessSocial_Invalid_AfterDateTime','access_Social_Invalid_After_Date_Time', 'Access Social InvalidAfterDateTime', 'No-go list invalidAfterDateTime', 'No-go List InvalidAfterDateTime'],
  
  accessAthletics_mode: ["accessAthletics_mode", "accessathleticsmode", "accessAthleticsMode", "access Athletics mode", "access_Athletics_mode", "Access Athletics mode"],
  accessAthletics_endDateTime: ['access Athletics End DateTime', 'accessAthletics endDateTime', 'accessAthletics_EndDateTime','accessAthletics_endDateTimeTime','accessathleticsenddatetime','accessathletics_enddatetime','access_Athletics_endDateTime','access_Athletics_end_Date_Time', 'Access Athletics EndDateTime'],
  accessAthletics_startDateTime: ['access Athletics Start Date Time', 'accessAthletics startDateTime', 'accessAthletics_StartDateTime','accessAthletics_startDateTimeTime','accessathleticsstartdatetime','accessathletics_startdatetime','access_Athletics_startDateTime','access_Athletics_start_Date_Time', 'Access Athletics StartDateTime'],
  accessAthletics_validAfterDateTime: ['access Athletics Valid After Date Time', 'accessAthletics validafterDateTime', 'accessAthletics_validAfterDateTime','accessAthletics_ValidAfterDateTime','accessathleticsvalidafterdatetime','accessathletics_validafterdatetime','accessAthletics_Valid_AfterDateTime','access_Athletics_Valid_After_Date_Time', 'Access Athletics ValidAfterDateTime'],
  accessAthletics_invalidAfterDateTime: ['access Athletics Invalid After Date Time', 'accessAthletics invalidafterDateTime', 'accessAthletics_invalidafterDateTime','accessAthletics_InvalidAfterDateTime','accessathleticsinvalidafterdatetime','accessathletics_invalidafterdatetime','accessAthletics_Invalid_AfterDateTime','access_Athletics_Invalid_After_Date_Time', 'Access Athletics InvalidAfterDateTime'],
  
  permissionToLeave_mode: ["permissionToLeave_mode", "permissiontoleavemode", "permissionToLeaveMode", "permission To Leave mode", "permission_To_Leave_mode", "Permission To Leave mode", 'Off-campus lunch mode', 'Off-campus Lunch Mode', 'Permission To Leave', 'permission to leave', 'permissionToLeave'],
  permissionToLeave_endDateTime: ['permission To Leave End DateTime', 'permissionToLeave endDateTime', 'permissionToLeave_EndDateTime','permissionToLeave_endDateTimeTime','permissiontoleaveenddatetime','permissiontoleave_enddatetime','permission_To_Leave_endDateTime','permission_To_Leave_end_Date_Time', 'Permission To Leave EndDateTime', 'Off-campus lunch endDateTime', 'Off-campus Lunch EndDateTime'],
  permissionToLeave_startDateTime: ['permission To Leave Start Date Time', 'permissionToLeave startDateTime', 'permissionToLeave_StartDateTime','permissionToLeave_startDateTimeTime','permissiontoleavestartdatetime','permissiontoleave_startdatetime','permission_To_Leave_startDateTime','permission_To_Leave_start_Date_Time', 'Permission To Leave StartDateTime', 'Off-campus lunch startDateTime', 'Off-campus Lunch StartDateTime'],
  permissionToLeave_validAfterDateTime: ['permission To Leave Valid After Date Time', 'permissionToLeave validafterDateTime', 'permissionToLeave_validAfterDateTime','permissionToLeave_ValidAfterDateTime','permissiontoleavevalidafterdatetime','permissiontoleave_validafterdatetime','permissionToLeave_Valid_AfterDateTime','permission_To_Leave_Valid_After_Date_Time', 'Permission To Leave ValidAfterDateTime', 'Off-campus lunch validAfterDateTime', 'Off-campus Lunch ValidAfterDateTime'],
  permissionToLeave_invalidAfterDateTime: ['permission To Leave Invalid After Date Time', 'permissionToLeave invalidafterDateTime', 'permissionToLeave_invalidafterDateTime','permissionToLeave_InvalidAfterDateTime','permissiontoleaveinvalidafterdatetime','permissiontoleave_invalidafterdatetime','permissionToLeave_Invalid_AfterDateTime','permission_To_Leave_Invalid_After_Date_Time', 'Permission To Leave InvalidAfterDateTime', 'Off-campus lunch invalidAfterDateTime', 'Off-campus Lunch InvalidAfterDateTime'],
  
  accessParking_mode: ["accessParking_mode", "accessparkingmode", "accessParkingMode", "access Parking mode", "access_Parking_mode", "Access Parking mode"],
  accessParking_endDateTime: ['access Parking End DateTime', 'accessParking endDateTime', 'accessParking_EndDateTime','accessParking_endDateTimeTime','accessparkingenddatetime','accessparking_enddatetime','access_Parking_endDateTime','access_Parking_end_Date_Time', 'Access Parking EndDateTime'],
  accessParking_startDateTime: ['access Parking Start Date Time', 'accessParking startDateTime', 'accessParking_StartDateTime','accessParking_startDateTimeTime','accessparkingstartdatetime','accessparking_startdatetime','access_Parking_startDateTime','access_Parking_start_Date_Time', 'Access Parking StartDateTime'],
  accessParking_validAfterDateTime: ['access Parking Valid After Date Time', 'accessParking validafterDateTime', 'accessParking_validAfterDateTime','accessParking_ValidAfterDateTime','accessparkingvalidafterdatetime','accessparking_validafterdatetime','accessParking_Valid_AfterDateTime','access_Parking_Valid_After_Date_Time', 'Access Parking ValidAfterDateTime'],
  accessParking_invalidAfterDateTime: ['access Parking Invalid After Date Time', 'accessParking invalidafterDateTime', 'accessParking_invalidafterDateTime','accessParking_InvalidAfterDateTime','accessparkinginvalidafterdatetime','accessparking_invalidafterdatetime','accessParking_Invalid_AfterDateTime','access_Parking_Invalid_After_Date_Time', 'Access Parking InvalidAfterDateTime'],
  
  permissionFlexSchedule_mode: ["permissionFlexSchedule_mode", "permissionflexschedulemode", "permissionFlexScheduleMode", "permission Flex Schedule mode", "permission_Flex_Schedule_mode", "Access Parking mode", 'Short schedule mode', 'Short Schedule Mode'],
  permissionFlexSchedule_endDateTime: ['permission Flex Schedule End DateTime', 'permissionFlexSchedule endDateTime', 'permissionFlexSchedule_EndDateTime','permissionFlexSchedule_endDateTimeTime','permissionflexscheduleenddatetime','permissionflexschedule_enddatetime','permission_Flex_Schedule_endDateTime','permission_Flex_Schedule_end_Date_Time', 'Permission Flex Schedule EndDateTime', 'Short schedule endDateTime', 'Short Schedule EndDateTime', 'Short schedule mode', 'Short Schedule Mode'],
  permissionFlexSchedule_startDateTime: ['permission Flex Schedule Start Date Time', 'permissionFlexSchedule startDateTime', 'permissionFlexSchedule_StartDateTime','permissionFlexSchedule_startDateTimeTime','permissionflexschedulestartdatetime','permissionflexschedule_startdatetime','permission_Flex_Schedule_startDateTime','permission_Flex_Schedule_start_Date_Time', 'Permission Flex Schedule StartDateTime', 'Short schedule startDateTime', 'Short Schedule StartDateTime'],
  permissionFlexSchedule_validAfterDateTime: ['permission Flex Schedule Valid After Date Time', 'permissionFlexSchedule validafterDateTime', 'permissionFlexSchedule_validAfterDateTime','permissionFlexSchedule_ValidAfterDateTime','permissionflexschedulevalidafterdatetime','permissionflexschedule_validafterdatetime','permissionFlexSchedule_Valid_AfterDateTime','permission_Flex_Schedule_Valid_After_Date_Time', 'Permission Flex Schedule ValidAfterDateTime', 'Short schedule validAfterDateTime', 'Short Schedule ValidAfterDateTime'],
  permissionFlexSchedule_invalidAfterDateTime: ['permission Flex Schedule Invalid After Date Time', 'permissionFlexSchedule invalidafterDateTime', 'permissionFlexSchedule_invalidafterDateTime','permissionFlexSchedule_InvalidAfterDateTime','permissionflexscheduleinvalidafterdatetime','permissionflexschedule_invalidafterdatetime','permissionFlexSchedule_Invalid_AfterDateTime','permission_Flex_Schedule_Invalid_After_Date_Time', 'Permission Flex Schedule InvalidAfterDateTime', 'Short schedule invalidAfterDateTime', 'Short Schedule InvalidAfterDateTime'],
  
  dateRange: ['dateRange', 'date range', 'Date Range', 'dateRange', 'daterange'],
  nickName: ['nickName', 'nick name', 'Nick Name', 'nickName'],
  physicianName: ['physicianName', 'Medical Physician Name', 'Physician Name', 'medicalPhysicianName', 'medicalphysicianname', 'physician name'],
  physicianStreet: ['physicianStreet', 'Medical Physician Street', 'Physician Street', 'physicianStreet', 'medicalPhysicianStreet', 'physician street'],
  physicianCity: ['physicianCity', 'medical physician city', 'physician city', 'physicianCity', 'Physician City', 'Medical Physician City', 'medicalPhysicianCity'],
  physicianState: ['physicianState', 'Medical Physician State', 'Physician State', 'physician state', 'medicalPhysicianState', 'medicalphysicianstate'],
  physicianZip: ['physicianZip', 'Medical Physician Zip', 'Physician Zip', 'physicianZip', 'medicalPhysicianZip', 'medical physician zip'],
  physicianPhone: ['physicianPhone', 'Medical Physician Phone', 'Physician Phone', 'medicalPhysicianPhone', 'physicianPhone'],
  physicianFax: ['physicianFax', 'Medical Physician Fax', 'Physician Fax', 'physician fax', 'medicalPhysicianFax', 'MedicalphysicianFax'],
  physicianEmail: ['physicianEmail', 'MedicalPhysicianEmail', 'Medical Physician Email', 'Physician Email', 'medicalPhysicianEmail'],
  medPrefHospital: ['medPrefHospital', 'Medical Preferred Hospital', 'Preferred Hospital', 'medical preferred hospital', 'medicalPrefrredHospital'],
  medAllergies: ['medAllergies', 'Medical Allergies', 'medical allergies', 'medicalAllergies', 'MedicalAllergies', 'medicalallergies'],
  medOtherNotes: ['medOtherNotes', 'medical other notes', 'medicalOtherNotes', 'Medical Other Notes', 'medical_other_notes', 'medicalothernotes'],
  medConditions: ['medConditions', 'Med Conditions', 'medconditions', 'medicalConditions', 'Medical Conditions', 'medicalconditions', 'MedicalConditions', 'medical conditions'],
  hasInsurance: ['hasInsurance', 'hasInsurance', 'hasInsurance', 'Has Insurance', 'hasinsurance'],
  insuranceCarrierName: ['insuranceCarrierName', 'insurance carrier name', 'insuranceCarrierName', 'Insurance Carrier Name', 'InsuranceCarrierName'],
  insuranceGroup: ['insuranceGroup', 'Insurance Group', 'InsuranceGroup', 'insurance group', 'insuranceGroup', 'insurancegroup'],
  insurancePolicyNumber: ['insurancePolicyNumber', 'Insurance Policy Number', 'Insurance Policy No', 'insurance policy number', 'insurance policy no'],
  insuranceGroupNumber: ['insuranceGroupNumber', 'insurance group number', 'Insurance Group Number', 'insurance group no', 'Insurance Group No'],
  insurancePolicyHolderName: ['insurancePolicyHolderName', 'Insurance Policy Holder Name', 'insurance policy holder name', 'insurance holder name', 'policy holder name'],
  schoolName: ['schoolName', 'School Name', 'school name', 'schoolname', 'nameschool', 'nameSchool', 'name school', 'nameSchool', 'Name School'],
  schoolDistrict: ['schoolDistrict', 'School District', 'school district', 'schooldistrict', 'district school', 'District School', 'districtSchool'],
  emergencyCon1Name: ['emergencyCon1Name', 'emergency con 1 name', 'contact 1 name'],
  emergencyCon1Phone: ['emergencyCon1Phone', 'emergency con 1 phone', 'contact 2 phone'],
  emergencyCon1Relationship: ['emergencyCon1Relationship', 'emergency con 1 relationship', 'contact 1 relationship'],
  emergencyCon2Name: ['emergencyCon2Name', 'emergency con 2 name', 'contact 2 name'],
  emergencyCon2Phone: ['emergencyCon2Phone', 'emergency con 2 phone', 'contact 2 phone'],
  emergencyCon2Relationship: ['emergencyCon2Relationship', 'emergency con 2 relationship', 'contact 2 relationship'],
  employer: ['employer', 'Employer'],
  feesSeason: ['feesSeason'],
  feesRegistration: ['feesRegistration'],
  signature: ['sign', 'signature']
};
static getFieldMapperColumns(type: string) {
  let synonym = UserService.synonym;
  switch (type) {
    case "exportPSPA":
      return [
        {
          name: "image",
          property: "photo",
          title: "Photo filename",
          synonyms: synonym.image
        },
        {
          name: "grade",
          property: "class",
          title: "Grade",
          synonyms: synonym.grade
        },
        {
          name: "lname",
          property: "lastName",
          title: "Last Name",
          synonyms: synonym.lname
        },
        {
          name: "fname",
          property: "firstName",
          title: "First Name",
          synonyms: synonym.fname
        },
        {
          name: "nickName",
          property: "nickName",
          title: "Nick Name",
          synonyms: synonym.nickName
        },
        {
          name: "homeRoom",
          property: "homeRoom",
          title: "Home room",
          synonyms: synonym.homeRoom
        },
        {
          name: "period",
          property: "period",
          title: "Period",
          synonyms: []
        },
        {
          name: "teacher",
          property: "teacher",
          title: "Teacher",
          synonyms: synonym.teacher
        },
        {
          name: "track",
          property: "track",
          title: "Track",
          synonyms: []
        },
        {
          name: "schoolDepartment",
          property: "schoolDepartment",
          title: "Department",
          synonyms: []
        },
        {
          name: "campus",
          property: "campus",
          title: "Campus",
          synonyms: synonym.campus
        },
        {
          name: "lunchPeriod",
          property: "lunchPeriod",
          title: "Lunch Period",
          synonyms: synonym.lunchPeriod
        },
        {
          name: "externalSource",
          property: "externalSource",
          title: "External Source type",
          synonyms: synonym.externalSource
        },
        {
          name: "actions",
          property: "actions",
          title: "Actions to Apply",
          synonyms: synonym.actions
        },
      ];
    case "aeries":
      return [
        {
          name: "id",
          property: "studentID",
          title: "Organization ID",
          synonyms: synonym.id
        },
        {
          name: "image",
          property: "photo",
          title: "Photo filename",
          synonyms: ["photo", "image file name", "image name", "img name", "image", "photo name", "image_name"]
        },
        {
          name: "fname",
          property: "firstName",
          title: "First Name",
          synonyms: synonym.fname
        },
        {
          name: "lname",
          property: "lastName",
          title: "Last Name",
          synonyms: synonym.lname
        },
        {
          name: "nickName",
          property: "nickName",
          title: "Nick Name",
          synonyms: synonym.nickName
        },
        {
          name: "grade",
          property: "class",
          title: "Grade",
          synonyms: synonym.grade
        },
        {
          name: "externalSource",
          property: "externalSource",
          title: "External Source type",
          synonyms: synonym.externalSource
        },
        {
          name: "actions",
          property: "actions",
          title: "Actions to Apply",
          synonyms: synonym.actions
        },
      ];
    case "aeriesNoId":
      return [
        {
          name: "fname",
          property: "firstName",
          title: "First Name",
          synonyms: synonym.fname
        },
        {
          name: "lname",
          property: "lastName",
          title: "Last Name",
          synonyms: synonym.lname
        },
        {
          name: "image",
          property: "photo",
          title: "Photo filename",
          synonyms: ["photo", "image file name", "image name", "img name", "image", "photo name", "image_name"]
        },
      ]
    case "aeriesException":
      return [
        {
          name: "fname",
          property: "firstName",
          title: "First Name",
          synonyms: synonym.fname
        },
        {
          name: "lname",
          property: "lastName",
          title: "Last Name",
          synonyms: synonym.lname
        },
      ]
    case "aeriesDrum":
      return [
        {
          name: "id",
          property: "studentID",
          title: "Organization ID",
          synonyms: synonym.id
        },
        {
          name: "image",
          property: "photo",
          title: "Photo filename",
          synonyms: ["photo", "image file name", "image name", "img name", "image", "photo name", "image_name"]
        },
      ]
    case "powerSchool":
      return [
        {
          name: "id",
          property: "studentID",
          title: "Organization ID",
          synonyms: synonym.id
        },
        {
          name: "fname",
          property: "firstName",
          title: "First Name",
          synonyms: synonym.fname
        },
        {
          name: "lname",
          property: "lastName",
          title: "Last Name",
          synonyms: synonym.lname
        },
        {
          name: "image",
          property: "photo",
          title: "Photo filename",
          synonyms: ["photo", "image file name", "image name", "img name", "image", "photo name", "image_name"]
        },
      ]
    case "synergy":
      return [
        {
          name: "id",
          property: "studentID",
          title: "Organization ID",
          synonyms: synonym.id
        },
        {
          name: "image",
          property: "photo",
          title: "Photo filename",
          synonyms: ["photo", "image file name", "image name", "img name", "image", "photo name", "image_name"]
        },
      ]
    case "zangle":
      return [
        {
          name: "image",
          property: "photo",
          title: "Photo filename",
          synonyms: ["photo", "image file name", "image name", "img name", "image", "photo name", "image_name"]
        },
        {
          name: "lname",
          property: "lastName",
          title: "Last Name",
          synonyms: synonym.lname
        },
        {
          name: "fname",
          property: "firstName",
          title: "First Name",
          synonyms: synonym.fname
        },
        {
          name: "teacher",
          property: "teacher",
          title: "Teacher",
          synonyms: synonym.teacher
        },
        {
          name: "grade",
          property: "class",
          title: "Grade",
          synonyms: synonym.grade
        },

      ]
    case "infiniteCampus":
      return [
        {
          name: "lname",
          property: "lastName",
          title: "Last Name",
          synonyms: synonym.lname
        },
        {
          name: "fname",
          property: "firstName",
          title: "First Name",
          synonyms: synonym.fname
        },
        {
          name: "id",
          property: "studentID",
          title: "Organization ID",
          synonyms: synonym.id
        },
        {
          name: "teacher",
          property: "teacher",
          title: "Teacher",
          synonyms: synonym.teacher
        },
        {
          name: "grade",
          property: "class",
          title: "Grade",
          synonyms: synonym.grade
        },
        {
          name: "image",
          property: "photo",
          title: "Photo filename",
          synonyms: ["photo", "image file name", "image name", "img name", "image", "photo name", "image_name"]
        },
      ]
    case "school":
      return [
        {
          name: "key",
          property: "key",
          title: "DB key",
          synonyms: synonym.key
        },
        {
          name: "signature",
          property: "signature",
          title: "Signature",
          synonyms: synonym.signature
        },
        {
          name: "salutation",
          property: "salutation",
          title: "Salutation",
          synonyms: synonym.salutation
        },
        {
          name: "fname",
          property: "firstName",
          title: "First Name",
          synonyms: synonym.fname
        },
        {
          name: "middleName",
          property: "middleName",
          title: "Middle Name",
          synonyms: synonym.middleName
        },
        {
          name: "nickName",
          property: "nickName",
          title: "Nick Name",
          synonyms: synonym.nickName
        },
        {
          name: "lname",
          property: "lastName",
          title: "Last Name",
          synonyms: synonym.lname
        },
        {
          name: "suffix",
          property: "suffix",
          title: "Suffix",
          synonyms: synonym.suffix
        },
        {
          name: "role",
          property: "role",
          title: "Role",
          synonyms: synonym.role
        },
        {
          name: "status",
          property: "status",
          title: "status",
          synonyms: []
        },
        {
          name: "grade",
          property: "class",
          title: "Grade",
          synonyms: synonym.grade
        },
        {
          name: "homeRoom",
          property: "homeRoom",
          title: "Home room",
          synonyms: synonym.homeRoom
        },
        {
          name: "period",
          property: "period",
          title: "Period",
          synonyms: []
        },
        {
          name: "track",
          property: "track",
          title: "Track",
          synonyms: []
        },
        {
          name: "photoUrl",
          property: "photoUrl",
          title: "Image Url",
          synonyms: synonym.photoUrl
        },
        {
          name: "teacher",
          property: "teacher",
          title: "Teacher",
          synonyms: synonym.teacher
        },
        {
          name: "id",
          property: "studentID",
          title: "Organization ID",
          synonyms: synonym.id
        },
        {
          name: "hash",
          property: "hash",
          title: "DB Hash",
          synonyms: synonym.hash
        },
        {
          name: "image",
          property: "photo",
          title: "Photo filename",
          synonyms: synonym.image
        },
        {
          name: "photoStatus",
          property: "photoStatus",
          title: "Photo Approval Status",
          synonyms: synonym.photoStatus
        },
        {
          name: "email",
          property: "email",
          title: "Email",
          synonyms: synonym.email
        },
        {
          name: "mobilePhone",
          property: "mobilePhone",
          title: "Mobile Phone",
          synonyms: synonym.mobilePhone
        },
        {
          name: "guardianFullName",
          property: "guardianFullName",
          title: "Guardian Name",
          synonyms: synonym.guardianFullName
        },
        {
          name: "guardianFirstName",
          property: "guardianFirstName",
          title: "Guardian First Name",
          synonyms: synonym.guardian1FirstName
        },
        {
          name: "guardianLastName",
          property: "guardianLastName",
          title: "Guardian Last Name",
          synonyms: synonym.guardian1LastName
        },
        {
          name: "guardianEmail",
          property: "guardianEmail",
          title: "Guardian Email",
          synonyms: synonym.guardianEmail
        },
        {
          name: "guardianPhone",
          property: "guardianPhone",
          title: "Guardian Phone",
          synonyms: synonym.guardianPhone
        },
        {
          name: "guardianFullName2",
          property: "guardianFullName2",
          title: "Guardian Name 2",
          synonyms: synonym.guardianFullName2
        },
        {
          name: "guardian2FirstName",
          property: "guardian2FirstName",
          title: "Guardian 2 First Name",
          synonyms: synonym.guardian2FirstName
        },
        {
          name: "guardian2LastName",
          property: "guardian2LastName",
          title: "Guardian 2 Last Name",
          synonyms: synonym.guardian2LastName
        },
        {
          name: "guardianEmail2",
          property: "guardianEmail2",
          title: "Guardian Email 2",
          synonyms: synonym.guardianEmail2
        },
        {
          name: "guardianPhone2",
          property: "guardianPhone2",
          title: "Guardian Phone 2",
          synonyms: synonym.guardianPhone2
        },
        {
          name: "guardianFullName3",
          property: "guardianFullName3",
          title: "Guardian Name 3",
          synonyms: synonym.guardianFullName3
        },
        {
          name: "guardian3FirstName",
          property: "guardian3FirstName",
          title: "Guardian 3 First Name",
          synonyms: synonym.guardian3FirstName
        },
        {
          name: "guardian3LastName",
          property: "guardian3LastName",
          title: "Guardian 3 Last Name",
          synonyms: synonym.guardian3LastName
        },
        {
          name: "guardianEmail3",
          property: "guardianEmail3",
          title: "Guardian Email 3",
          synonyms: synonym.guardianEmail3
        },
        {
          name: "guardianPhone3",
          property: "guardianPhone3",
          title: "Guardian Phone 3",
          synonyms: synonym.guardianPhone3
        },
        {
          name: "guardianFullName4",
          property: "guardianFullName4",
          title: "Guardian Name 4",
          synonyms: synonym.guardianFullName4
        },
        {
          name: "guardian4FirstName",
          property: "guardian4FirstName",
          title: "Guardian 4 First Name",
          synonyms: synonym.guardian4FirstName
        },
        {
          name: "guardian4LastName",
          property: "guardian4LastName",
          title: "Guardian 4 Last Name",
          synonyms: synonym.guardian4LastName
        },
        {
          name: "guardianEmail4",
          property: "guardianEmail4",
          title: "Guardian Email 4",
          synonyms: synonym.guardianEmail4
        },
        {
          name: "guardianPhone4",
          property: "guardianPhone4",
          title: "Guardian Phone 4",
          synonyms: synonym.guardianPhone4
        },
        {
          name: "ASBMember",
          property: "ASBMember",
          title: "ASB Member",
          synonyms: synonym.ASBMember
        },
        {
          name: "govtID",
          property: "govtID",
          title: "Government-issued ID",
          synonyms: synonym.govtID
        },
        {
          name: "admissibilityGroup",
          property: "admissibilityGroup",
          title: "Admissibility Group",
          synonyms: synonym.admissibilityGroup
        },
        {
          name: "RFID",
          property: "RFID",
          title: "RFID Code",
          synonyms: synonym.RFID
        },
        {
          name: "group",
          property: "group",
          title: "Group",
          synonyms: []
        },
        {
          name: "department",
          property: "department",
          title: "Department",
          synonyms: []
        },
        {
          name: "schoolDepartment",
          property: "schoolDepartment",
          title: "School Department",
          synonyms: synonym.schoolDepartment
        },
        {
          name: "campus",
          property: "campus",
          title: "Campus",
          synonyms: synonym.campus
        },
        {
          name: "lunchPeriod",
          property: "lunchPeriod",
          title: "Lunch Period",
          synonyms: synonym.lunchPeriod
        },
        {
          name: "title",
          property: "title",
          title: "Title",
          synonyms: synonym.title
        },
        {
          name: "section",
          property: "section",
          title: "section",
          synonyms: []
        },
        {
          name: "custom1",
          property: "custom1",
          title: "Custom1",
          synonyms: synonym.custom1
        },
        {
          name: "custom2",
          property: "custom2",
          title: "Custom2",
          synonyms: synonym.custom2
        },
        {
          name: "custom3",
          property: "custom3",
          title: "Custom3",
          synonyms: synonym.custom3
        },
        {
          name: "lockerNumber",
          property: "lockerNumber",
          title: "Locker Number",
          synonyms: []
        },
        {
          name: "lockerCombination",
          property: "lockerCombination",
          title: "Locker Combination",
          synonyms: []
        },
        {
          name: "teammatePURL",
          property: "teammatePURL",
          title: "TeammatePURL",
          synonyms: []
        },
        {
          name: "birth",
          property: "birth",
          title: "DateOfBirth",
          synonyms: synonym.birth
        },
        {
          name: "company",
          property: "company",
          title: "Company",
          synonyms: synonym.company
        },
        {
          name: "weight",
          property: "weight",
          title: "Weight",
          synonyms: []
        },
        {
          name: "height1",
          property: "height1",
          title: "Height (feet)",
          synonyms: []
        },
        {
          name: "height2",
          property: "height2",
          title: "Height (inches)",
          synonyms: []
        },
        {
          name: "hairColor",
          property: "hairColor",
          title: "Hair Color",
          synonyms: synonym.hairColor
        },
        {
          name: "eyeColor",
          property: "eyeColor",
          title: "Eye Color",
          synonyms: synonym.eyeColor
        },
        {
          name: "indID_Client",
          property: "indID_Client",
          title: "Client indID",
          synonyms: synonym.indID_Client
        },
        {
          name: "auxID",
          property: "auxID",
          title: "Auxiliary ID",
          synonyms: synonym.auxID
        },
        {
          name: "photoYear",
          property: 'photoYear',
          title: 'Photo Year',
          synonyms: synonym.photoYear
        },
        {
          name: "yearbookOrdered",
          property: "yearbookOrdered",
          title: "Yearbook Ordered",
          synonyms: synonym.yearbookOrdered
        },
        {
          name: "yearbookPaid",
          property: "yearbookPaid",
          title: "Yearbook Paid",
          synonyms: synonym.yearbookPaid
        },
        {
          name: "yearbookCollected",
          property: "yearbookCollected",
          title: "Yearbook Collected",
          synonyms: synonym.yearbookCollected
        },
        {
          name: "address1",
          property: 'addressPart1',
          title: "Address 1",
          synonyms: synonym.addressPart1
        },
        {
          name: "address2",
          property: 'addressPart2',
          title: "Address 2",
          synonyms: synonym.addressPart2
        },
        {
          name: "postalCode",
          property: 'postalCode',
          title: "Postal Code",
          synonyms: synonym.postalCode
        },
        {
          name: 'city',
          property: "city",
          title: "City",
          synonyms: synonym.city
        },
        {
          name: "state",
          property: 'state',
          title: "State",
          synonyms: synonym.state
        },
        {
          name: "country",
          property: 'country',
          title: "Country",
          synonyms: synonym.country
        },
        {
          name: "password",
          property: 'password',
          title: "Password",
          synonyms: synonym.password
        },
        {
          name: "externalSource",
          property: "externalSource",
          title: "External Source type",
          synonyms: synonym.externalSource
        },
        {
          name: "actions",
          property: "actions",
          title: "Actions to Apply",
          synonyms: synonym.actions
        },
        {
          name: "accessBus_mode",
          property: 'accessBus_mode',
          title: "Bus Access",
          synonyms: synonym.accessBus_mode
        },
        {
          name: "accessBus_startDateTime",
          property: 'accessBus_startDateTime',
          title: "Bus Access Start",
          synonyms: synonym.accessBus_startDateTime
        },
        {
          name: "accessBus_endDateTime",
          property: 'accessBus_endDateTime',
          title: "Bus Access End",
          synonyms: synonym.accessBus_endDateTime
        },
        {
          name: "accessBus_validAfterDateTime",
          property: 'accessBus_validAfterDateTime',
          title: "Bus Access Not Valid Before Time",
          synonyms: synonym.accessBus_validAfterDateTime
        },
        {
          name: "accessBus_invalidAfterDateTime",
          property: 'accessBus_invalidAfterDateTime',
          title: "Bus Access Not Valid after Time",
          synonyms: synonym.accessBus_invalidAfterDateTime
        },
        {
          name: "accessCafeteria_mode",
          property: 'accessCafeteria_mode',
          title: "Cafeteria Access",
          synonyms: synonym.accessCafeteria_mode
        },
        {
          name: "accessCafeteria_startDateTime",
          property: 'accessCafeteria_startDateTime',
          title: "Cafeteria Access Start Date",
          synonyms: synonym.accessCafeteria_startDateTime
        },
        {
          name: "accessCafeteria_endDateTime",
          property: 'accessCafeteria_endDateTime',
          title: "Cafeteria Access End Date",
          synonyms: synonym.accessCafeteria_endDateTime
        },
        {
          name: "accessCafeteria_validAfterDateTime",
          property: 'accessCafeteria_validAfterDateTime',
          title: "Cafeteria Access Valid after",
          synonyms: synonym.accessCafeteria_validAfterDateTime
        },
        {
          name: "accessCafeteria_invalidAfterDateTime",
          property: 'accessCafeteria_invalidAfterDateTime',
          title: "Cafeteria Access Not Valid after",
          synonyms: synonym.accessCafeteria_invalidAfterDateTime
        },
        {
          name: "accessCampus_mode",
          property: 'accessCampus_mode',
          title: "Campus Access",
          synonyms: synonym.accessCampus_mode
        },
        {
          name: "accessCampus_startDateTime",
          property: 'accessCampus_startDateTime',
          title: "Campus Access start time",
          synonyms: synonym.accessCampus_startDateTime
        },
        {
          name: "accessCampus_endDateTime",
          property: 'accessCampus_endDateTime',
          title: "Campus Access End time",
          synonyms: synonym.accessCampus_startDateTime
        },
        {
          name: "accessCampus_validAfterDateTime",
          property: 'accessCampus_validAfterDateTime',
          title: "Campus Access Not Valid Before Time",
          synonyms: synonym.accessCampus_validAfterDateTime
        },
        {
          name: "accessCampus_invalidAfterDateTime",
          property: 'accessCampus_invalidAfterDateTime',
          title: "Campus Access Not Valid after Time",
          synonyms: synonym.accessCampus_invalidAfterDateTime
        },
        {
          name: "idValid_mode",
          property: 'idValid_mode',
          title: "ID Status",
          synonyms: synonym.idValid_mode
        },
        {
          name: "idValid_startDateTime",
          property: 'idValid_startDateTime',
          title: "ID Status Start",
          synonyms: synonym.idValid_startDateTime
        },
        {
          name: "idValid_endDateTime",
          property: 'idValid_endDateTime',
          title: "ID Status End",
          synonyms: synonym.idValid_endDateTime
        },
        {
          name: "idValid_validAfterDateTime",
          property: 'idValid_validAfterDateTime',
          title: "ID Status Not Valid Before Time",
          synonyms: synonym.idValid_validAfterDateTime
        },
        {
          name: "idValid_invalidAfterDateTime",
          property: 'idValid_invalidAfterDateTime',
          title: "ID Status Not Valid after Time",
          synonyms: synonym.idValid_invalidAfterDateTime
        },
        {
          name: "accessParking_mode",
          property: 'accessParking_mode',
          title: "Parking Access",
          synonyms: synonym.accessParking_mode
        },
        {
          name: "accessParking_startDateTime",
          property: 'accessParking_startDateTime',
          title: "Parking Access Start",
          synonyms: synonym.accessParking_startDateTime
        },
        {
          name: "accessParking_endDateTime",
          property: 'accessParking_endDateTime',
          title: "Parking Access End",
          synonyms: synonym.accessParking_endDateTime
        },
        {
          name: "accessParking_validAfterDateTime",
          property: 'accessParking_validAfterDateTime',
          title: "Parking Access Not Valid Before Time",
          synonyms: synonym.accessParking_validAfterDateTime
        },
        {
          name: "accessParking_invalidAfterDateTime",
          property: 'accessParking_invalidAfterDateTime',
          title: "Parking Access Not Valid after Time",
          synonyms: synonym.accessParking_invalidAfterDateTime
        },
        {
          name: "accessSocial_mode",
          property: 'accessSocial_mode',
          title: "Social Access",
          synonyms: synonym.accessSocial_mode
        },
        {
          name: "accessSocial_startDateTime",
          property: 'accessSocial_startDateTime',
          title: "Social Access Start",
          synonyms: synonym.accessSocial_startDateTime
        },
        {
          name: "accessSocial_endDateTime",
          property: 'accessSocial_endDateTime',
          title: "Social Access End",
          synonyms: synonym.accessSocial_endDateTime
        },
        {
          name: "accessSocial_validAfterDateTime",
          property: 'accessSocial_validAfterDateTime',
          title: "Social Access Not Valid Before Time",
          synonyms: synonym.accessSocial_validAfterDateTime
        },
        {
          name: "accessSocial_invalidAfterDateTime",
          property: 'accessSocial_invalidAfterDateTime',
          title: "Social Access Not Valid after Time",
          synonyms: synonym.accessSocial_invalidAfterDateTime
        },
        {
          name: "accessAthletics_mode",
          property: 'accessAthletics_mode',
          title: "Athletics Access",
          synonyms: synonym.accessAthletics_mode
        },
        {
          name: "accessAthletics_startDateTime",
          property: 'accessAthletics_startDateTime',
          title: "Athletics Access Start",
          synonyms: synonym.accessAthletics_startDateTime
        },
        {
          name: "accessAthletics_endDateTime",
          property: 'accessAthletics_endDateTime',
          title: "Athletics Access End",
          synonyms: synonym.accessAthletics_endDateTime
        },
        {
          name: "accessAthletics_validAfterDateTime",
          property: 'accessAthletics_validAfterDateTime',
          title: "Athletics Access Not Valid Before Time",
          synonyms: synonym.accessAthletics_validAfterDateTime
        },
        {
          name: "accessAthletics_invalidAfterDateTime",
          property: 'accessAthletics_invalidAfterDateTime',
          title: "Athletics Access Not Valid after Time",
          synonyms: synonym.accessAthletics_invalidAfterDateTime
        },
        {
          name: "permissionFlexSchedule_mode",
          property: 'permissionFlexSchedule_mode',
          title: "Flexible Schedule",
          synonyms: synonym.permissionFlexSchedule_mode
        },
        {
          name: "permissionFlexSchedule_startDateTime",
          property: 'permissionFlexSchedule_startDateTime',
          title: "Flexible Schedule Start",
          synonyms: synonym.permissionFlexSchedule_startDateTime
        },
        {
          name: "permissionFlexSchedule_endDateTime",
          property: 'permissionFlexSchedule_endDateTime',
          title: "Flexible Schedule End",
          synonyms: synonym.permissionFlexSchedule_endDateTime
        },
        {
          name: "permissionFlexSchedule_validAfterDateTime",
          property: 'permissionFlexSchedule_validAfterDateTime',
          title: "Flexible Schedule Not Valid Before Time",
          synonyms: synonym.permissionFlexSchedule_validAfterDateTime
        },
        {
          name: "permissionFlexSchedule_invalidAfterDateTime",
          property: 'permissionFlexSchedule_invalidAfterDateTime',
          title: "Flexible Schedule Not Valid after Time",
          synonyms: synonym.permissionFlexSchedule_invalidAfterDateTime
        },
        {
          name: "permissionToLeave_mode",
          property: 'permissionToLeave_mode',
          title: "Permission to Leave",
          synonyms: synonym.permissionToLeave_mode
        },
        {
          name: "permissionToLeave_startDateTime",
          property: 'permissionToLeave_startDateTime',
          title: "Permission to Leave Start",
          synonyms: synonym.permissionToLeave_startDateTime
        },
        {
          name: "permissionToLeave_endDateTime",
          property: 'permissionToLeave_endDateTime',
          title: "Permission to Leave End",
          synonyms: synonym.permissionToLeave_endDateTime
        },
        {
          name: "permissionToLeave_validAfterDateTime",
          property: 'permissionToLeave_validAfterDateTime',
          title: "Permission to Leave Not Valid Before Time",
          synonyms: synonym.permissionToLeave_validAfterDateTime
        },
        {
          name: "permissionToLeave_invalidAfterDateTime",
          property: 'permissionToLeave_invalidAfterDateTime',
          title: "Permission to Leave Not Valid after Time",
          synonyms: synonym.permissionToLeave_invalidAfterDateTime
        },
      ];
    case "conference":
      return [
        {
          name: "key",
          property: "key",
          title: "DB key",
          synonyms: synonym.key
        },
        {
          name: "salutation",
          property: "salutation",
          title: "Salutation",
          synonyms: synonym.salutation
        },
        {
          name: "fname",
          property: "firstName",
          title: "First Name",
          synonyms: synonym.fname
        },
        {
          name: "middleName",
          property: "middleName",
          title: "Middle Name",
          synonyms: synonym.middleName
        },
        {
          name: "nickName",
          property: "nickName",
          title: "Nick Name",
          synonyms: synonym.nickName
        },
        {
          name: "lname",
          property: "lastName",
          title: "Last Name",
          synonyms: synonym.lname
        },
        {
          name: "suffix",
          property: "suffix",
          title: "Suffix",
          synonyms: synonym.suffix
        },
        {
          name: "role",
          property: "role",
          title: "Role",
          synonyms: synonym.role
        },
        {
          name: "homeRoom",
          property: "homeRoom",
          title: "Home room",
          synonyms: synonym.homeRoom
        },
        {
          name: "period",
          property: "period",
          title: "Period",
          synonyms: []
        },
        {
          name: "track",
          property: "track",
          title: "Track",
          synonyms: []
        },
        {
          name: "photoUrl",
          property: "photoUrl",
          title: "Image Url",
          synonyms: synonym.photoUrl
        },
        {
          name: "status",
          property: "status",
          title: "status",
          synonyms: []
        },
        {
          name: "id",
          property: "license",
          title: "Organization ID",
          synonyms: ['license']
        },
        {
          name: "company",
          property: "company",
          title: "Company",
          synonyms: synonym.company
        },
        {
          name: "hash",
          property: "hash",
          title: "DB Hash",
          synonyms: synonym.hash
        },
        {
          name: "image",
          property: "photo",
          title: "Photo filename",
          synonyms: synonym.image
        },
        {
          name: "email",
          property: "email",
          title: "Email",
          synonyms: synonym.email
        },
        {
          name: "mobilePhone",
          property: "mobilePhone",
          title: "Mobile Phone",
          synonyms: synonym.mobilePhone
        },
        {
          name: "groupID",
          property: "groupID",
          title: "Company ID",
          synonyms: ['groupID', "companyID", 'company ID', 'companyId']
        },
        {
          name: "addressPart1",
          property: "addressPart1",
          title: "Street address",
          synonyms: synonym.addressPart1
        },
        {
          name: "addressPart2",
          property: "addressPart2",
          title: "Street address line 2",
          synonyms: synonym.addressPart2
        },
        {
          name: "postalCode",
          property: "postalCode",
          title: "ZIP/Postal Code",
          synonyms: synonym.postalCode
        },
        {
          name: "city",
          property: "city",
          title: "City",
          synonyms: synonym.city
        },
        {
          name: "state",
          property: "state",
          title: "State/Province",
          synonyms: synonym.state
        },
        {
          name: "country",
          property: "country",
          title: "Country",
          synonyms: synonym.country
        },
        {
          name: "photoStatus",
          property: "photoStatus",
          title: "Photo Approval Status",
          synonyms: synonym.photoStatus
        },
        {
          name: "downloads",
          property: "downloads",
          title: "Downloads",
          synonyms: []
        },
        {
          name: "specialEvents",
          property: "specialEvents",
          title: "Special Events",
          synonyms: []
        },
        {
          name: "admissibilityGroup",
          property: "admissibilityGroup",
          title: "Admissibility Group",
          synonyms: synonym.admissibilityGroup
        },
        {
          name: "RFID",
          property: "RFID",
          title: "RFID Code",
          synonyms: synonym.RFID
        },
        {
          name: "ASBMember",
          property: "ASBMember",
          title: "ASB Member",
          synonyms: synonym.ASBMember
        },
        {
          name: "group",
          property: "group",
          title: "Group",
          synonyms: []
        },
        {
          name: "department",
          property: "department",
          title: "Department",
          synonyms: []
        },
        {
          name: "schoolDepartment",
          property: "schoolDepartment",
          title: "School Department",
          synonyms: synonym.schoolDepartment
        },
        {
          name: "campus",
          property: "campus",
          title: "Campus",
          synonyms: synonym.campus
        },
        {
          name: "lunchPeriod",
          property: "lunchPeriod",
          title: "Lunch Period",
          synonyms: synonym.lunchPeriod
        },
        {
          name: "title",
          property: "title",
          title: "Title",
          synonyms: synonym.title
        },
        {
          name: "section",
          property: "section",
          title: "section",
          synonyms: []
        },
        {
          name: "custom1",
          property: "custom1",
          title: "Custom1",
          synonyms: synonym.custom1
        },
        {
          name: "custom2",
          property: "custom2",
          title: "Custom2",
          synonyms: synonym.custom2
        },
        {
          name: "custom3",
          property: "custom3",
          title: "Custom3",
          synonyms: synonym.custom3
        },
        {
          name: "auxID",
          property: "auxID",
          title: "Auxiliary ID",
          synonyms: synonym.auxID
        },
        {
          name: "lockerNumber",
          property: "lockerNumber",
          title: "Locker Number",
          synonyms: []
        },
        {
          name: "lockerCombination",
          property: "lockerCombination",
          title: "Locker Combination",
          synonyms: []
        },
        {
          name: "teammatePURL",
          property: "teammatePURL",
          title: "TeammatePURL",
          synonyms: []
        },
        {
          name: "weight",
          property: "weight",
          title: "Weight",
          synonyms: []
        },
        {
          name: "height1",
          property: "height1",
          title: "Height (feet)",
          synonyms: []
        },
        {
          name: "height2",
          property: "height2",
          title: "Height (inches)",
          synonyms: []
        },
        {
          name: "hairColor",
          property: "hairColor",
          title: "Hair Color",
          synonyms: synonym.hairColor
        },
        {
          name: "eyeColor",
          property: "eyeColor",
          title: "Eye Color",
          synonyms: synonym.eyeColor
        },
        {
          name: "indID_Client",
          property: "indID_Client",
          title: "Client indID",
          synonyms: synonym.indID_Client
        },
        {
          name: "auxID",
          property: "auxID",
          title: "Auxiliary ID",
          synonyms: synonym.auxID
        },
        {
          name: "photoYear",
          property: 'photoYear',
          title: 'Photo Year',
          synonyms: synonym.photoYear
        },
        {
          name: "address1",
          property: 'addressPart1',
          title: "Address 1",
          synonyms: synonym.addressPart1
        },
        {
          name: "address2",
          property: 'addressPart2',
          title: "Address 2",
          synonyms: synonym.addressPart2
        },
        {
          name: "postalCode",
          property: 'postalCode',
          title: "Postal Code",
          synonyms: synonym.postalCode
        },
        {
          name: 'city',
          property: "city",
          title: "City",
          synonyms: synonym.city
        },
        {
          name: "state",
          property: 'state',
          title: "State",
          synonyms: synonym.state
        },
        {
          name: "country",
          property: 'country',
          title: "Country",
          synonyms: synonym.country
        },
        {
          name: "password",
          property: 'password',
          title: "Password",
          synonyms: synonym.password
        },
        {
          name: "externalSource",
          property: "externalSource",
          title: "External Source type",
          synonyms: synonym.externalSource
        },
        {
          name: "actions",
          property: "actions",
          title: "Actions to Apply",
          synonyms: synonym.actions
        },
        {
          name: "accessBus_mode",
          property: 'accessBus_mode',
          title: "Bus Access Valid",
          synonyms: synonym.accessBus_mode
        },
        {
          name: "accessBus_startDateTime",
          property: 'accessBus_startDateTime',
          title: "Bus Access Start",
          synonyms: synonym.accessBus_startDateTime
        },
        {
          name: "accessBus_endDateTime",
          property: 'accessBus_endDateTime',
          title: "Bus Access End",
          synonyms: synonym.accessBus_endDateTime
        },
        {
          name: "accessBus_validAfterDateTime",
          property: 'accessBus_validAfterDateTime',
          title: "Bus Access Not Valid Before Time",
          synonyms: synonym.accessBus_validAfterDateTime
        },
        {
          name: "accessBus_invalidAfterDateTime",
          property: 'accessBus_invalidAfterDateTime',
          title: "Bus Access Not Valid after Time",
          synonyms: synonym.accessBus_invalidAfterDateTime
        },
        {
          name: "accessCafeteria_mode",
          property: 'accessCafeteria_mode',
          title: "Cafeteria Access",
          synonyms: synonym.accessCafeteria_mode
        },
        {
          name: "accessCafeteria_startDateTime",
          property: 'accessCafeteria_startDateTime',
          title: "Cafeteria Access Start Date",
          synonyms: synonym.accessCafeteria_startDateTime
        },
        {
          name: "accessCafeteria_endDateTime",
          property: 'accessCafeteria_endDateTime',
          title: "Cafeteria Access End Date",
          synonyms: synonym.accessCafeteria_endDateTime
        },
        {
          name: "accessCafeteria_validAfterDateTime",
          property: 'accessCafeteria_validAfterDateTime',
          title: "Cafeteria Access Valid after",
          synonyms: synonym.accessCafeteria_validAfterDateTime
        },
        {
          name: "accessCafeteria_invalidAfterDateTime",
          property: 'accessCafeteria_invalidAfterDateTime',
          title: "Cafeteria Access Not Valid after",
          synonyms: synonym.accessCafeteria_invalidAfterDateTime
        },
        {
          name: "accessCampus_mode",
          property: 'accessCampus_mode',
          title: "Campus Access",
          synonyms: synonym.accessCampus_mode
        },
        {
          name: "accessCampus_startDateTime",
          property: 'accessCampus_startDateTime',
          title: "Campus Access start time",
          synonyms: synonym.accessCampus_startDateTime
        },
        {
          name: "accessCampus_endDateTime",
          property: 'accessCampus_endDateTime',
          title: "Campus Access End time",
          synonyms: synonym.accessCampus_startDateTime
        },
        {
          name: "accessCampus_validAfterDateTime",
          property: 'accessCampus_validAfterDateTime',
          title: "Campus Access Not Valid Before Time",
          synonyms: synonym.accessCampus_validAfterDateTime
        },
        {
          name: "accessCampus_invalidAfterDateTime",
          property: 'accessCampus_invalidAfterDateTime',
          title: "Campus Access Not Valid after Time",
          synonyms: synonym.accessCampus_invalidAfterDateTime
        },
        {
          name: "idValid_mode",
          property: 'idValid_mode',
          title: "ID Status",
          synonyms: synonym.idValid_mode
        },
        {
          name: "idValid_startDateTime",
          property: 'idValid_startDateTime',
          title: "ID Status Start",
          synonyms: synonym.idValid_startDateTime
        },
        {
          name: "idValid_endDateTime",
          property: 'idValid_endDateTime',
          title: "ID Status End",
          synonyms: synonym.idValid_endDateTime
        },
        {
          name: "idValid_validAfterDateTime",
          property: 'idValid_validAfterDateTime',
          title: "ID Status Not Valid Before Time",
          synonyms: synonym.idValid_validAfterDateTime
        },
        {
          name: "idValid_invalidAfterDateTime",
          property: 'idValid_invalidAfterDateTime',
          title: "ID Status Not Valid after Time",
          synonyms: synonym.idValid_invalidAfterDateTime
        },
        {
          name: "accessParking_mode",
          property: 'accessParking_mode',
          title: "Parking Access",
          synonyms: synonym.accessParking_mode
        },
        {
          name: "accessParking_startDateTime",
          property: 'accessParking_startDateTime',
          title: "Parking Access Start",
          synonyms: synonym.accessParking_startDateTime
        },
        {
          name: "accessParking_endDateTime",
          property: 'accessParking_endDateTime',
          title: "Parking Access End",
          synonyms: synonym.accessParking_endDateTime
        },
        {
          name: "accessParking_validAfterDateTime",
          property: 'accessParking_validAfterDateTime',
          title: "Parking Access Not Valid Before Time",
          synonyms: synonym.accessParking_validAfterDateTime
        },
        {
          name: "accessParking_invalidAfterDateTime",
          property: 'accessParking_invalidAfterDateTime',
          title: "Parking Access Not Valid after Time",
          synonyms: synonym.accessParking_invalidAfterDateTime
        },
        {
          name: "accessSocial_mode",
          property: 'accessSocial_mode',
          title: "Social Access",
          synonyms: synonym.accessSocial_mode
        },
        {
          name: "accessSocial_startDateTime",
          property: 'accessSocial_startDateTime',
          title: "Social Access Start",
          synonyms: synonym.accessSocial_startDateTime
        },
        {
          name: "accessSocial_endDateTime",
          property: 'accessSocial_endDateTime',
          title: "Social Access End",
          synonyms: synonym.accessSocial_endDateTime
        },
        {
          name: "accessSocial_validAfterDateTime",
          property: 'accessSocial_validAfterDateTime',
          title: "Social Access Not Valid Before Time",
          synonyms: synonym.accessSocial_validAfterDateTime
        },
        {
          name: "accessSocial_invalidAfterDateTime",
          property: 'accessSocial_invalidAfterDateTime',
          title: "Social Access Not Valid after Time",
          synonyms: synonym.accessSocial_invalidAfterDateTime
        },
        {
          name: "accessAthletics_mode",
          property: 'accessAthletics_mode',
          title: "Athletics Access",
          synonyms: synonym.accessAthletics_mode
        },
        {
          name: "accessAthletics_startDateTime",
          property: 'accessAthletics_startDateTime',
          title: "Athletics Access Start",
          synonyms: synonym.accessAthletics_startDateTime
        },
        {
          name: "accessAthletics_endDateTime",
          property: 'accessAthletics_endDateTime',
          title: "Athletics Access End",
          synonyms: synonym.accessAthletics_endDateTime
        },
        {
          name: "accessAthletics_validAfterDateTime",
          property: 'accessAthletics_validAfterDateTime',
          title: "Athletics Access Not Valid Before Time",
          synonyms: synonym.accessAthletics_validAfterDateTime
        },
        {
          name: "accessAthletics_invalidAfterDateTime",
          property: 'accessAthletics_invalidAfterDateTime',
          title: "Athletics Access Not Valid after Time",
          synonyms: synonym.accessAthletics_invalidAfterDateTime
        },
        {
          name: "permissionFlexSchedule_mode",
          property: 'permissionFlexSchedule_mode',
          title: "Flexible Schedule",
          synonyms: synonym.permissionFlexSchedule_mode
        },
        {
          name: "permissionFlexSchedule_startDateTime",
          property: 'permissionFlexSchedule_startDateTime',
          title: "Flexible Schedule Start",
          synonyms: synonym.permissionFlexSchedule_startDateTime
        },
        {
          name: "permissionFlexSchedule_endDateTime",
          property: 'permissionFlexSchedule_endDateTime',
          title: "Flexible Schedule End",
          synonyms: synonym.permissionFlexSchedule_endDateTime
        },
        {
          name: "permissionFlexSchedule_validAfterDateTime",
          property: 'permissionFlexSchedule_validAfterDateTime',
          title: "Flexible Schedule Not Valid Before Time",
          synonyms: synonym.permissionFlexSchedule_validAfterDateTime
        },
        {
          name: "permissionFlexSchedule_invalidAfterDateTime",
          property: 'permissionFlexSchedule_invalidAfterDateTime',
          title: "Flexible Schedule Not Valid after Time",
          synonyms: synonym.permissionFlexSchedule_invalidAfterDateTime
        },
        {
          name: "permissionToLeave_mode",
          property: 'permissionToLeave_mode',
          title: "Permission to Leave",
          synonyms: synonym.permissionToLeave_mode
        },
        {
          name: "permissionToLeave_startDateTime",
          property: 'permissionToLeave_startDateTime',
          title: "Permission to Leave Start",
          synonyms: synonym.permissionToLeave_startDateTime
        },
        {
          name: "permissionToLeave_endDateTime",
          property: 'permissionToLeave_endDateTime',
          title: "Permission to Leave End",
          synonyms: synonym.permissionToLeave_endDateTime
        },
        {
          name: "permissionToLeave_validAfterDateTime",
          property: 'permissionToLeave_validAfterDateTime',
          title: "Permission to Leave Not Valid Before Time",
          synonyms: synonym.permissionToLeave_validAfterDateTime
        },
        {
          name: "permissionToLeave_invalidAfterDateTime",
          property: 'permissionToLeave_invalidAfterDateTime',
          title: "Permission to Leave Not Valid after Time",
          synonyms: synonym.permissionToLeave_invalidAfterDateTime
        },
      ];
    case "sportsteam":
      return [
        {
          name: "key",
          property: "key",
          title: "DB key",
          synonyms: synonym.key
        },
        {
          name: "signature",
          property: "signature",
          title: "Signature",
          synonyms: synonym.signature
        },
        {
          name: "feesRegistration",
          property: "feesRegistration",
          title: "Registration Fees",
          synonyms: synonym.feesRegistration
        },
        {
          name: "feesSeason",
          property: "feesSeason",
          title: "Season Fees",
          synonyms: synonym.feesSeason
        },
        {
          name: "salutation",
          property: "salutation",
          title: "Salutation",
          synonyms: synonym.salutation
        },
        {
          name: "fname",
          property: "firstName",
          title: "First Name",
          synonyms: synonym.fname
        },
        {
          name: "middleName",
          property: "middleName",
          title: "Middle Name",
          synonyms: synonym.middleName
        },
        {
          name: "nickName",
          property: "nickName",
          title: "Nick Name",
          synonyms: synonym.nickName
        },
        {
          name: "physicianName",
          property: "physicianName",
          title: "Medical Physician Name",
          synonyms: synonym.physicianName
        },
        {
          name: "physicianStreet",
          property: "physicianStreet",
          title: "Medical Physician Street",
          synonyms: synonym.physicianStreet
        },
        {
          name: "physicianCity",
          property: "physicianCity",
          title: "Medical Physician City",
          synonyms: synonym.physicianCity
        },
        {
          name: "physicianState",
          property: "physicianState",
          title: "Medical Physician State",
          synonyms: synonym.physicianState
        },
        {
          name: "physicianZip",
          property: "physicianZip",
          title: "Medical Physician Zip",
          synonyms: synonym.physicianZip
        },
        {
          name: "physicianPhone",
          property: "physicianPhone",
          title: "Medical Physician Phone",
          synonyms: synonym.physicianPhone
        },
        {
          name: "physicianFax",
          property: "physicianFax",
          title: "Medical Physician Fax",
          synonyms: synonym.physicianFax
        },
        {
          name: "physicianEmail",
          property: "physicianEmail",
          title: "Medical Physician Email",
          synonyms: synonym.physicianEmail
        },
        {
          name: "medPrefHospital",
          property: "medPrefHospital",
          title: "Medical Physician Hospital",
          synonyms: synonym.medPrefHospital
        },
        {
          name: "medAllergies",
          property: "medAllergies",
          title: "Medical Allergies",
          synonyms: synonym.medAllergies
        },
        {
          name: "medConditions",
          property: "medConditions",
          title: "Medical Conditions",
          synonyms: synonym.medConditions
        },
        {
          name: "medOtherNotes",
          property: "medOtherNotes",
          title: "Medical OtherNotes",
          synonyms: synonym.medOtherNotes
        },
        {
          name: "hasInsurance",
          property: "hasInsurance",
          title: "Insurance hasInsurance",
          synonyms: synonym.hasInsurance
        },
        {
          name: "insuranceCarrierName",
          property: "insuranceCarrierName",
          title: "Insurance CarrierName",
          synonyms: synonym.insuranceCarrierName
        },
        {
          name: "insuranceGroup",
          property: "insuranceGroup",
          title: "Insurance Group",
          synonyms: synonym.insuranceGroup
        },
        {
          name: "insurancePolicyNumber",
          property: "insurancePolicyNumber",
          title: "Insurance Policy Number",
          synonyms: synonym.insurancePolicyNumber
        },
        {
          name: "insuranceGroupNumber",
          property: "insuranceGroupNumber",
          title: "Insurance Group Number",
          synonyms: synonym.insuranceGroupNumber
        },
        {
          name: "insurancePolicyHolderName",
          property: "insurancePolicyHolderName",
          title: "Insurance Policy HolderName",
          synonyms: synonym.insurancePolicyHolderName
        },
        {
          name: "schoolName",
          property: "schoolName",
          title: "School Name",
          synonyms: synonym.schoolName
        },
        {
          name: "schoolDistrict",
          property: "schoolDistrict",
          title: "School District",
          synonyms: synonym.schoolDistrict
        },
        {
          name: "emergencyCon1Name",
          property: "emergencyCon1Name",
          title: "emergency contact 1 name",
          synonyms: synonym.emergencyCon1Name
        },
        {
          name: "emergencyCon1Phone",
          property: "emergencyCon1Phone",
          title: "emergency contact 1 phone",
          synonyms: synonym.emergencyCon1Phone
        },
        {
          name: "emergencyCon1Relationship",
          property: "emergencyCon1Relationship",
          title: "emergency contact 1 relationship",
          synonyms: synonym.emergencyCon1Relationship
        },
        {
          name: "emergencyCon2Name",
          property: "emergencyCon2Name",
          title: "emergency contact 2 name",
          synonyms: synonym.emergencyCon2Name
        },
        {
          name: "emergencyCon2Phone",
          property: "emergencyCon2Phone",
          title: "emergency contact 2 phone",
          synonyms: synonym.emergencyCon2Phone
        },
        {
          name: "emergencyCon2Relationship",
          property: "emergencyCon2Relationship",
          title: "emergency contact 2 relationship",
          synonyms: synonym.emergencyCon2Relationship
        },
        {
          name: 'employer',
          property: 'employer',
          title: 'Employer',
          synonyms: synonym.employer
        },
        {
          name: "lname",
          property: "lastName",
          title: "Last Name",
          synonyms: synonym.lname
        },
        {
          name: "suffix",
          property: "suffix",
          title: "Suffix",
          synonyms: synonym.suffix
        },
        {
          name: "role",
          property: "role",
          title: "Role",
          synonyms: []
        },
        {
          name: "status",
          property: "status",
          title: "status",
          synonyms: []
        },
        {
          name: "Image Url",
          property: "photoUrl",
          title: "Image Url",
          synonyms: ['photoUrl', 'Image Url']
        },
        {
          name: "hash",
          property: "hash",
          title: "DB Hash",
          synonyms: ['DBKey', 'dbkey']
        },
        {
          name: "image",
          property: "photo",
          title: "Photo filename",
          synonyms: ["photo", "image file name", "image name", "img name", "image", "photo name", "image_name"]
        },
        {
          name: "photoStatus",
          property: "photoStatus",
          title: "Photo Approval Status",
          synonyms: ['photoApprovalStatus', 'Image status']
        },
        {
          name: "email",
          property: "email",
          title: "Email",
          synonyms: synonym.email
        },
        {
          name: "mobilePhone",
          property: "mobilePhone",
          title: "Mobile Phone",
          synonyms: synonym.mobilePhone
        },
        {
          name: "guardianFullName",
          property: "guardianFullName",
          title: "Guardian Name",
          synonyms: synonym.guardianFullName
        },
        {
          name: "guardianFirstName",
          property: "guardianFirstName",
          title: "Guardian First Name",
          synonyms: synonym.guardian1FirstName
        },
        {
          name: "guardianLastName",
          property: "guardianLastName",
          title: "Guardian Last Name",
          synonyms: synonym.guardian1LastName
        },
        {
          name: "guardianEmail",
          property: "guardianEmail",
          title: "Guardian Email",
          synonyms: synonym.guardianEmail
        },
        {
          name: "guardianPhone",
          property: "guardianPhone",
          title: "Guardian Phone",
          synonyms: synonym.guardianPhone
        },
        {
          name: "guardianFullName2",
          property: "guardianFullName2",
          title: "Guardian Name 2",
          synonyms: synonym.guardianFullName2
        },
        {
          name: "guardian2FirstName",
          property: "guardian2FirstName",
          title: "Guardian 2 First Name",
          synonyms: synonym.guardian2FirstName
        },
        {
          name: "guardian2LastName",
          property: "guardian2LastName",
          title: "Guardian 2 Last Name",
          synonyms: synonym.guardian2LastName
        },
        {
          name: "guardianEmail2",
          property: "guardianEmail2",
          title: "Guardian Email 2",
          synonyms: synonym.guardianEmail2
        },
        {
          name: "guardianPhone2",
          property: "guardianPhone2",
          title: "Guardian Phone 2",
          synonyms: synonym.guardianPhone2
        },
        {
          name: "guardianFullName3",
          property: "guardianFullName3",
          title: "Guardian Name 3",
          synonyms: synonym.guardianFullName3
        },
        {
          name: "guardian3FirstName",
          property: "guardian3FirstName",
          title: "Guardian 3 First Name",
          synonyms: synonym.guardian3FirstName
        },
        {
          name: "guardian3LastName",
          property: "guardian3LastName",
          title: "Guardian 3 Last Name",
          synonyms: synonym.guardian3LastName
        },
        {
          name: "guardianEmail3",
          property: "guardianEmail3",
          title: "Guardian Email 3",
          synonyms: synonym.guardianEmail3
        },
        {
          name: "guardianPhone3",
          property: "guardianPhone3",
          title: "Guardian Phone 3",
          synonyms: synonym.guardianPhone3
        },
        {
          name: "guardianFullName4",
          property: "guardianFullName4",
          title: "Guardian Name 4",
          synonyms: synonym.guardianFullName4
        },
        {
          name: "guardian4FirstName",
          property: "guardian4FirstName",
          title: "Guardian 4 First Name",
          synonyms: synonym.guardian4FirstName
        },
        {
          name: "guardian4LastName",
          property: "guardian4LastName",
          title: "Guardian 4 Last Name",
          synonyms: synonym.guardian4LastName
        },
        {
          name: "guardianEmail4",
          property: "guardianEmail4",
          title: "Guardian Email 4",
          synonyms: synonym.guardianEmail4
        },
        {
          name: "guardianPhone4",
          property: "guardianPhone4",
          title: "Guardian Phone 4",
          synonyms: synonym.guardianPhone4
        },
        {
          name: "grade",
          property: "class",
          title: "Grade",
          synonyms: synonym.grade
        },

        // --- sports section fields START
        {
          name: "team",
          property: "team",
          title: "Team",
          synonyms: []
        },
        {
          name: "coach",
          property: "coach",
          title: "Coach",
          synonyms: []
        },
        {
          name: "jerseyNumber",
          property: "jerseyNumber",
          title: "Jersey Number",
          synonyms: synonym.jerseyNumber
        },
        {
          name: "position",
          property: "position",
          title: "Position",
          synonyms: []
        },
        {
          name: "weight",
          property: "weight",
          title: "Weight",
          synonyms: []
        },
        {
          name: "height1",
          property: "height1",
          title: "Height (feet)",
          synonyms: []
        },
        {
          name: "height2",
          property: "height2",
          title: "Height (inches)",
          synonyms: []
        },
        {
          name: "hairColor",
          property: "hairColor",
          title: "Hair Color",
          synonyms: synonym.hairColor
        },
        {
          name: "eyeColor",
          property: "eyeColor",
          title: "Eye Color",
          synonyms: synonym.eyeColor
        },
        {
          name: "division",
          property: "division",
          title: "Division",
          synonyms: []
        },
        // {
        //   name: "ageClass",
        //   property: "ageClass",
        //   title: "AgeClass",
        //   synonyms: []
        // },
        {
          name: "weightClass",
          property: "weightClass",
          title: "WeightClass",
          synonyms: []
        },
        {
          name: "program",
          property: "program",
          title: "Program",
          synonyms: []
        },
        {
          name: "programSection",
          property: "programSection",
          title: "Program Section",
          synonyms: []
        },
        {
          name: "playStatus",
          property: "playStatus",
          title: "Play Status",
          synonyms: []
        },
        {
          name: "paymentStatus",
          property: "paymentStatus",
          title: "Payment status",
          synonyms: []
        },
        {
          name: "bookCode",
          property: "bookCode",
          title: "Book Code",
          synonyms: []
        },
        // --- sports section fields END

        {
          name: "govtID",
          property: "govtID",
          title: "Government-issued ID",
          synonyms: ['DL', 'drivers', 'driversLicense', 'drivers', 'license', 'government ID']
        },
        {
          name: "admissibilityGroup",
          property: "admissibilityGroup",
          title: "Admissibility Group",
          synonyms: ['admissible group', 'Admissible Group', 'Admissibility Group']
        },
        {
          name: "RFID",
          property: "RFID",
          title: "RFID Code",
          synonyms: synonym.RFID
        },
        {
          name: "group",
          property: "group",
          title: "Group",
          synonyms: []
        },
        {
          name: "department",
          property: "department",
          title: "Department",
          synonyms: []
        },
        {
          name: "schoolDepartment",
          property: "schoolDepartment",
          title: "School Department",
          synonyms: ['schoolDepartment', 'school Department']
        },
        {
          name: "title",
          property: "title",
          title: "Title",
          synonyms: synonym.title
        },
        {
          name: "section",
          property: "section",
          title: "section",
          synonyms: []
        },
        {
          name: "custom1",
          property: "custom1",
          title: "Custom1",
          synonyms: synonym.custom1
        },
        {
          name: "custom2",
          property: "custom2",
          title: "Custom2",
          synonyms: synonym.custom2
        },
        {
          name: "custom3",
          property: "custom3",
          title: "Custom3",
          synonyms: synonym.custom3
        },
        {
          name: "auxID",
          property: "auxID",
          title: "Auxiliary ID",
          synonyms: synonym.auxID
        },
        {
          name: "teammatePURL",
          property: "teammatePURL",
          title: "TeammatePURL",
          synonyms: []
        },
        {
          name: "birth",
          property: "birth",
          title: "DateOfBirth",
          synonyms: ['Birthdate', 'birthday', 'Date of birth', 'DateOfBirth', 'birth', 'birth day', 'birthday']
        },
        {
          name: "company",
          property: "company",
          title: "Company",
          synonyms: ['company name', 'employer']
        },
        {
          name: "indID_Client",
          property: "indID_Client",
          title: "Client indID",
          synonyms: synonym.indID_Client
        },
        {
          name: "photoYear",
          property: 'photoYear',
          title: 'Photo Year',
          synonyms: synonym.photoYear
        },
        {
          name: "address1",
          property: 'addressPart1',
          title: "Address 1",
          synonyms: synonym.addressPart1
        },
        {
          name: "address2",
          property: 'addressPart2',
          title: "Address 2",
          synonyms: synonym.addressPart2
        },
        {
          name: "postalCode",
          property: 'postalCode',
          title: "Postal Code",
          synonyms: synonym.postalCode
        },
        {
          name: 'city',
          property: "city",
          title: "City",
          synonyms: synonym.city
        },
        {
          name: "state",
          property: 'state',
          title: "State",
          synonyms: synonym.state
        },
        {
          name: "country",
          property: 'country',
          title: "Country",
          synonyms: synonym.country
        },
        {
          name: "password",
          property: 'password',
          title: "Password",
          synonyms: synonym.password
        },
        {
          name: "externalSource",
          property: "externalSource",
          title: "External Source type",
          synonyms: synonym.externalSource
        },
        {
          name: "actions",
          property: "actions",
          title: "Actions to Apply",
          synonyms: synonym.actions
        },
        {
          name: "accessBus_mode",
          property: 'accessBus_mode',
          title: "Bus Access",
          synonyms: synonym.accessBus_mode
        },
        {
          name: "accessBus_startDateTime",
          property: 'accessBus_startDateTime',
          title: "Bus Access Start",
          synonyms: synonym.accessBus_startDateTime
        },
        {
          name: "accessBus_endDateTime",
          property: 'accessBus_endDateTime',
          title: "Bus Access End",
          synonyms: synonym.accessBus_endDateTime
        },
        {
          name: "accessBus_validAfterDateTime",
          property: 'accessBus_validAfterDateTime',
          title: "Bus Access Not Valid Before Time",
          synonyms: synonym.accessBus_validAfterDateTime
        },
        {
          name: "accessBus_invalidAfterDateTime",
          property: 'accessBus_invalidAfterDateTime',
          title: "Bus Access Not Valid after Time",
          synonyms: synonym.accessBus_invalidAfterDateTime
        },
        {
          name: "accessCafeteria_mode",
          property: 'accessCafeteria_mode',
          title: "Cafeteria Access",
          synonyms: synonym.accessCafeteria_mode
        },
        {
          name: "accessCafeteria_startDateTime",
          property: 'accessCafeteria_startDateTime',
          title: "Cafeteria Access Start Date",
          synonyms: synonym.accessCafeteria_startDateTime
        },
        {
          name: "accessCafeteria_endDateTime",
          property: 'accessCafeteria_endDateTime',
          title: "Cafeteria Access End Date",
          synonyms: synonym.accessCafeteria_endDateTime
        },
        {
          name: "accessCafeteria_validAfterDateTime",
          property: 'accessCafeteria_validAfterDateTime',
          title: "Cafeteria Access Valid after",
          synonyms: synonym.accessCafeteria_validAfterDateTime
        },
        {
          name: "accessCafeteria_invalidAfterDateTime",
          property: 'accessCafeteria_invalidAfterDateTime',
          title: "Cafeteria Access Not Valid after",
          synonyms: synonym.accessCafeteria_invalidAfterDateTime
        },
        {
          name: "accessCampus_mode",
          property: 'accessCampus_mode',
          title: "Campus Access",
          synonyms: synonym.accessCampus_mode
        },
        {
          name: "accessCampus_startDateTime",
          property: 'accessCampus_startDateTime',
          title: "Campus Access start time",
          synonyms: synonym.accessCampus_startDateTime
        },
        {
          name: "accessCampus_endDateTime",
          property: 'accessCampus_endDateTime',
          title: "Campus Access End time",
          synonyms: synonym.accessCampus_startDateTime
        },
        {
          name: "accessCampus_validAfterDateTime",
          property: 'accessCampus_validAfterDateTime',
          title: "Campus Access Not Valid Before Time",
          synonyms: synonym.accessCampus_validAfterDateTime
        },
        {
          name: "accessCampus_invalidAfterDateTime",
          property: 'accessCampus_invalidAfterDateTime',
          title: "Campus Access Not Valid after Time",
          synonyms: synonym.accessCampus_invalidAfterDateTime
        },
        {
          name: "idValid_mode",
          property: 'idValid_mode',
          title: "ID Status",
          synonyms: synonym.idValid_mode
        },
        {
          name: "idValid_startDateTime",
          property: 'idValid_startDateTime',
          title: "ID Status Start",
          synonyms: synonym.idValid_startDateTime
        },
        {
          name: "idValid_endDateTime",
          property: 'idValid_endDateTime',
          title: "ID Status End",
          synonyms: synonym.idValid_endDateTime
        },
        {
          name: "idValid_validAfterDateTime",
          property: 'idValid_validAfterDateTime',
          title: "ID Status Not Valid Before Time",
          synonyms: synonym.idValid_validAfterDateTime
        },
        {
          name: "idValid_invalidAfterDateTime",
          property: 'idValid_invalidAfterDateTime',
          title: "ID Status Not Valid after Time",
          synonyms: synonym.idValid_invalidAfterDateTime
        },
        {
          name: "accessParking_mode",
          property: 'accessParking_mode',
          title: "Parking Access",
          synonyms: synonym.accessParking_mode
        },
        {
          name: "accessParking_startDateTime",
          property: 'accessParking_startDateTime',
          title: "Parking Access Start",
          synonyms: synonym.accessParking_startDateTime
        },
        {
          name: "accessParking_endDateTime",
          property: 'accessParking_endDateTime',
          title: "Parking Access End",
          synonyms: synonym.accessParking_endDateTime
        },
        {
          name: "accessParking_validAfterDateTime",
          property: 'accessParking_validAfterDateTime',
          title: "Parking Access Not Valid Before Time",
          synonyms: synonym.accessParking_validAfterDateTime
        },
        {
          name: "accessParking_invalidAfterDateTime",
          property: 'accessParking_invalidAfterDateTime',
          title: "Parking Access Not Valid after Time",
          synonyms: synonym.accessParking_invalidAfterDateTime
        },
        {
          name: "accessSocial_mode",
          property: 'accessSocial_mode',
          title: "Social Access",
          synonyms: synonym.accessSocial_mode
        },
        {
          name: "accessSocial_startDateTime",
          property: 'accessSocial_startDateTime',
          title: "Social Access Start",
          synonyms: synonym.accessSocial_startDateTime
        },
        {
          name: "accessSocial_endDateTime",
          property: 'accessSocial_endDateTime',
          title: "Social Access End",
          synonyms: synonym.accessSocial_endDateTime
        },
        {
          name: "accessSocial_validAfterDateTime",
          property: 'accessSocial_validAfterDateTime',
          title: "Social Access Not Valid Before Time",
          synonyms: synonym.accessSocial_validAfterDateTime
        },
        {
          name: "accessSocial_invalidAfterDateTime",
          property: 'accessSocial_invalidAfterDateTime',
          title: "Social Access Not Valid after Time",
          synonyms: synonym.accessSocial_invalidAfterDateTime
        },
        {
          name: "accessAthletics_mode",
          property: 'accessAthletics_mode',
          title: "Athletics Access",
          synonyms: synonym.accessAthletics_mode
        },
        {
          name: "accessAthletics_startDateTime",
          property: 'accessAthletics_startDateTime',
          title: "Athletics Access Start",
          synonyms: synonym.accessAthletics_startDateTime
        },
        {
          name: "accessAthletics_endDateTime",
          property: 'accessAthletics_endDateTime',
          title: "Athletics Access End",
          synonyms: synonym.accessAthletics_endDateTime
        },
        {
          name: "accessAthletics_validAfterDateTime",
          property: 'accessAthletics_validAfterDateTime',
          title: "Athletics Access Not Valid Before Time",
          synonyms: synonym.accessAthletics_validAfterDateTime
        },
        {
          name: "accessAthletics_invalidAfterDateTime",
          property: 'accessAthletics_invalidAfterDateTime',
          title: "Athletics Access Not Valid after Time",
          synonyms: synonym.accessAthletics_invalidAfterDateTime
        },
        {
          name: "permissionFlexSchedule_mode",
          property: 'permissionFlexSchedule_mode',
          title: "Flexible Schedule",
          synonyms: synonym.permissionFlexSchedule_mode
        },
        {
          name: "permissionFlexSchedule_startDateTime",
          property: 'permissionFlexSchedule_startDateTime',
          title: "Flexible Schedule Start",
          synonyms: synonym.permissionFlexSchedule_startDateTime
        },
        {
          name: "permissionFlexSchedule_endDateTime",
          property: 'permissionFlexSchedule_endDateTime',
          title: "Flexible Schedule End",
          synonyms: synonym.permissionFlexSchedule_endDateTime
        },
        {
          name: "permissionFlexSchedule_validAfterDateTime",
          property: 'permissionFlexSchedule_validAfterDateTime',
          title: "Flexible Schedule Not Valid Before Time",
          synonyms: synonym.permissionFlexSchedule_validAfterDateTime
        },
        {
          name: "permissionFlexSchedule_invalidAfterDateTime",
          property: 'permissionFlexSchedule_invalidAfterDateTime',
          title: "Flexible Schedule Not Valid after Time",
          synonyms: synonym.permissionFlexSchedule_invalidAfterDateTime
        },
        {
          name: "permissionToLeave_mode",
          property: 'permissionToLeave_mode',
          title: "Permission to Leave",
          synonyms: synonym.permissionToLeave_mode
        },
        {
          name: "permissionToLeave_startDateTime",
          property: 'permissionToLeave_startDateTime',
          title: "Permission to Leave Start",
          synonyms: synonym.permissionToLeave_startDateTime
        },
        {
          name: "permissionToLeave_endDateTime",
          property: 'permissionToLeave_endDateTime',
          title: "Permission to Leave End",
          synonyms: synonym.permissionToLeave_endDateTime
        },
        {
          name: "permissionToLeave_validAfterDateTime",
          property: 'permissionToLeave_validAfterDateTime',
          title: "Permission to Leave Not Valid Before Time",
          synonyms: synonym.permissionToLeave_validAfterDateTime
        },
        {
          name: "permissionToLeave_invalidAfterDateTime",
          property: 'permissionToLeave_invalidAfterDateTime',
          title: "Permission to Leave Not Valid after Time",
          synonyms: synonym.permissionToLeave_invalidAfterDateTime
        },
      ];
    default:
      return [
        {
          name: "salutation",
          property: "salutation",
          title: "Salutation",
          synonyms: synonym.salutation
        },
        {
          name: "fname",
          property: "firstName",
          title: "First Name",
          synonyms: synonym.fname
        },
        {
          name: "middleName",
          property: "middleName",
          title: "Middle Name",
          synonyms: synonym.middleName
        },
        {
          name: "nickName",
          property: "nickName",
          title: "Nick Name",
          synonyms: synonym.nickName
        },
        {
          name: "lname",
          property: "lastName",
          title: "Last Name",
          synonyms: synonym.lname
        },
        {
          name: "suffix",
          property: "suffix",
          title: "Suffix",
          synonyms: synonym.suffix
        },
        {
          name: "id",
          property: "studentID",
          title: "Organization ID",
          synonyms: synonym.id
        },
        {
          name: "role",
          property: "role",
          title: "Role",
          synonyms: ["Position"]
        },
        {
          name: "homeRoom",
          property: "homeRoom",
          title: "Home room",
          synonyms: synonym.homeRoom
        },
        {
          name: "period",
          property: "period",
          title: "Period",
          synonyms: []
        },
        {
          name: "track",
          property: "track",
          title: "Track",
          synonyms: []
        },
        {
          name: "schoolDepartment",
          property: "schoolDepartment",
          title: "School Department",
          synonyms: ['schoolDepartment', 'school Department']
        },
        {
          name: "campus",
          property: "campus",
          title: "Campus",
          synonyms: synonym.campus
        },
        {
          name: "lunchPeriod",
          property: "lunchPeriod",
          title: "Lunch Period",
          synonyms: synonym.lunchPeriod
        },
        {
          name: "admissibilityGroup",
          property: "admissibilityGroup",
          title: "Admissibility Group",
          synonyms: ['admissible group', 'Admissible Group']
        },
        {
          name: "status",
          property: "status",
          title: "status",
          synonyms: []
        },
        {
          name: "grade",
          property: "class",
          title: "Grade",
          synonyms: []
        },
        {
          name: "teacher",
          property: "teacher",
          title: "Teacher",
          synonyms: synonym.teacher
        },
        {
          name: "id",
          property: "license",
          title: "Organization ID",
          synonyms: ['license']
        },
        {
          name: "hash",
          property: "hash",
          title: "DB Hash",
          synonyms: ['DBKey', 'dbkey']
        },
        {
          name: "image",
          property: "photo",
          title: "Photo filename",
          synonyms: ["photo", "image file name", "image name", "img name", "image", "photo name", "image_name"]
        },
        {
          name: "photoStatus",
          property: "photoStatus",
          title: "Photo Approval Status",
          synonyms: ['photoApprovalStatus', 'Image status']
        },
        {
          name: "email",
          property: "email",
          title: "Email",
          synonyms: synonym.email
        },
        {
          name: "mobilePhone",
          property: "mobilePhone",
          title: "Mobile Phone",
          synonyms: synonym.mobilePhone
        },
        {
          name: "guardianFullName",
          property: "guardianFullName",
          title: "Guardian",
          synonyms: synonym.guardianFullName
        },
        {
          name: "guardianEmail",
          property: "guardianEmail",
          title: "Guardian Email",
          synonyms: synonym.guardianEmail
        },
        {
          name: "guardianPhone",
          property: "guardianPhone",
          title: "Guardian Phone",
          synonyms: synonym.guardianPhone
        },
        {
          name: "company",
          property: "company",
          title: "Company",
          synonyms: ['company name', 'employer']
        },
        {
          name: "ASBMember",
          property: "ASBMember",
          title: "ASB Member",
          synonyms: synonym.ASBMember
        },
        {
          name: "govtID",
          property: "govtID",
          title: "Government-issued ID",
          synonyms: ['DL', 'drivers', 'driversLicense', 'drivers', 'license', 'government ID']
        },
        {
          name: "RFID",
          property: "RFID",
          title: "RFID Code",
          synonyms: synonym.RFID
        },
        {
          name: "birth",
          property: "birth",
          title: "DateOfBirth",
          synonyms: ['Birthdate', 'birthday', 'Date of birth', 'DateOfBirth', 'birth', 'birth day', 'birthday']
        },
        {
          name: "group",
          property: "group",
          title: "Group",
          synonyms: []
        },
        {
          name: "department",
          property: "department",
          title: "Department",
          synonyms: []
        },
        {
          name: "title",
          property: "title",
          title: "Title",
          synonyms: synonym.title
        },
        {
          name: "addressPart1",
          property: "addressPart1",
          title: "Street address",
          synonyms: synonym.addressPart1
        },
        {
          name: "addressPart2",
          property: "addressPart2",
          title: "Street address line 2",
          synonyms: synonym.addressPart2
        },
        {
          name: "postalCode",
          property: "postalCode",
          title: "ZIP/Postal Code",
          synonyms: synonym.postalCode
        },
        {
          name: "city",
          property: "city",
          title: "City",
          synonyms: synonym.city
        },
        {
          name: "state",
          property: "state",
          title: "State/Province",
          synonyms: synonym.state
        },
        {
          name: "country",
          property: "country",
          title: "Country",
          synonyms: synonym.country
        },
        {
          name: "section",
          property: "section",
          title: "section",
          synonyms: []
        },
        {
          name: "custom1",
          property: "custom1",
          title: "Custom1",
          synonyms: synonym.custom1
        },
        {
          name: "custom2",
          property: "custom2",
          title: "Custom2",
          synonyms: synonym.custom2
        },
        {
          name: "custom3",
          property: "custom3",
          title: "Custom3",
          synonyms: synonym.custom3
        },
        {
          name: "auxID",
          property: "auxID",
          title: "Auxiliary ID",
          synonyms: synonym.auxID
        },
        {
          name: "lockerNumber",
          property: "lockerNumber",
          title: "Locker Number",
          synonyms: []
        },
        {
          name: "lockerCombination",
          property: "lockerCombination",
          title: "Locker Combination",
          synonyms: []
        },
        {
          name: "teammatePURL",
          property: "teammatePURL",
          title: "TeammatePURL",
          synonyms: []
        },
        {
          name: "weight",
          property: "weight",
          title: "Weight",
          synonyms: []
        },
        {
          name: "height1",
          property: "height1",
          title: "Height (feet)",
          synonyms: []
        },
        {
          name: "height2",
          property: "height2",
          title: "Height (inches)",
          synonyms: []
        },
        {
          name: "hairColor",
          property: "hairColor",
          title: "Hair Color",
          synonyms: synonym.hairColor
        },
        {
          name: "eyeColor",
          property: "eyeColor",
          title: "Eye Color",
          synonyms: synonym.eyeColor
        },
        {
          name: "indID_Client",
          property: "indID_Client",
          title: "Client indID",
          synonyms: synonym.indID_Client
        },
        {
          name: "auxID",
          property: "auxID",
          title: "Auxiliary ID",
          synonyms: synonym.auxID
        },
        {
          name: "photoYear",
          property: 'photoYear',
          title: 'Photo Year',
          synonyms: synonym.photoYear
        },
        {
          name: "password",
          property: 'password',
          title: "Password",
          synonyms: synonym.password
        },
        {
          name: "externalSource",
          property: "externalSource",
          title: "External Source type",
          synonyms: synonym.externalSource
        },
        {
          name: "actions",
          property: "actions",
          title: "Actions to Apply",
          synonyms: synonym.actions
        }
      ];
  }
}

eventFieldMapper: any[] = [
  {
    name: "firstName",
    property: "firstName",
    title: "First Name",
    synonyms: UserService.synonym.fname
  },
  {
    name: "lastName",
    property: "lastName",
    title: "Last Name",
    synonyms: UserService.synonym.lname
  },
  {
    name: "eventCode",
    property: "eventCode",
    title: "Event Code",
    synonyms: UserService.synonym.eventCode
  },
  {
    name: "ticketCode",
    property: "ticketCode",
    title: "Ticket Type Code",
    synonyms: UserService.synonym.ticketCode
  },
  {
    name: 'sin',
    property: 'studentID',
    title: 'StudentID',
    synonyms: UserService.synonym.id
  }
];

// Remove Entry From Users Node
async removeUserData(uid: String) {
  return await this.db.object(`users/${uid}/account`).set(null)
}

// Update UserService Data
async updateUserData(uid: string, data: any) {
  return await this.db.object(`/users/${uid}/account/`).update(data);
}

  /**
   * delete user from firebase authentication section
   * @param uid firebase uid of the user
   * @returns promise of response object from API
   */
  async removeUser(uid) {
    if (!uid) throw new Error("uid missing in params!");
    let encryptedBody = this.encryptionProvider.encrypt(
      { uid },
      environment.edk
    );
    return await this.http
      .post(`${environment.firebaseFunctionsBaseUrl}/removeUser`, encryptedBody)
      .toPromise();
  }

}
