import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, forwardRef } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { CommonServiceService } from "../common-service.service";
import { getAuth } from "firebase/auth";
import { environment } from "../../../../environments/environment.stage";
import { lastValueFrom } from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class ApiHelperService {
  url: string = `${environment.apiBaseUrl}`

  constructor(
    private afAuth: AngularFireAuth,
    private http: HttpClient,
    @Inject(forwardRef(() => CommonServiceService)) public commonFun: CommonServiceService,
  ) {}

  // --- helper function to make cloud function API call
  async postToCloudFn(fnName: string, payload: any, overrideToken?: string) {
    // --- get current user auth token
    let token;
    if (overrideToken) token = overrideToken;
    else {
      let currentUser = await this.afAuth.currentUser;
      token = await currentUser?.getIdToken();
    }

    // --- prepare headers
    let headers = {
      Authorization: token ? `Bearer ${token}` : '',
    };

    // --- prepare server URL to send request to
    let url = this.commonFun.prepareCallableFunURL(fnName);

    return lastValueFrom(this.http.post(url, { data: payload }, { headers }))
  }
  
  async postToHttpFn(fnName: string, payload: any, overrideToken?: string) {
    let token = overrideToken
      ? overrideToken
      : getAuth().currentUser &&
      (await getAuth().currentUser.getIdToken());

    // --- prepare headers
    let headers = {
      Authorization: token ? `Bearer ${token}` : "",
    };
    return lastValueFrom(this.http.post((environment.cloudFunctionsDomainV2 + fnName), payload, { headers }));
  }

  post(endpoint: string, body: any, reqOpts?: any) {
    let url = endpoint;
    if (endpoint.indexOf("http") === -1) {
      url = `${this.url}/${endpoint}`;
    }

    const defaultOptions = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Origin', '*') 
        .set('Access-Control-Allow-Credentials', 'true')
    };

    const options = { ...defaultOptions, ...reqOpts };
    return this.http.post(url, body, options);
  }
}
export enum CloudFnNames {
  getGenLoginIdentitySettings = "getgenloginidentitysettings",
  searchInds = "searchinds",
  setIndPW = "setindpw",
  getChallengesForInd = "getchallengesforind",
  verifyGenLoginQueAns = "verifygenloginqueans",
  getIndividualData = "getindividualdata",
  getOrgData = "getorgdata",
  getOrgSecData = "getorgsecdata",
  getOrgMgrData = "getorgmgrdata",
  searchOrgs = "searchorgs",
  removeAnnUser = "removeannuser",
  resetIndPW = "resetindpw",
  addInd = "addind",
  updateInd = "updateind",
  sendMsgToInd = "sendmsgtoind",
  getIndEmail = "getindemail",
  publicOrgSignup = "publicorgsignup",
  searchStudios = "searchstudios",
  searchCCDOrgs = "searchccdorgs",
  getCustomToken = "getcustomtoken",
  verify2FA = "verifytwofa",
  reject2FA = "rejecttwofa",
  bulkRemoveUsers = "bulkremoveusers",
  bulkCreateAuthUsers = "bulkcreateauthusers",
  bulkUpdateAuthUsers = "bulkupdateauthusers",
  getIdTokenUsingId = "getidtokenusingid",
  getExToken = "getextoken",
  removeOrgIndImages = "removeorgindimages",
  signIn = "signin",
  verify2FACode = "verifytwofacode",
  generateResetPWLink = "generateresetpwlink",
  getGameMPRData = "getgamemprdata",
  wideSearchInds = "widesearchinds",
  getOrgs = "getorgs",
  getShortenUL = "getshortenurl",
  getOneRosterOrg = "getonerosterorg",
  getOneRosterOrgAcademicSession = "getonerosterorgacademicsession",
  getOneRosterAccessToken = "getonerosteraccesstoken",
  createDistrict = 'createdistrict',
  onRampSignupOrganization = 'onrampsignuporganization',
  checkIfUserExistsByEmail = 'checkifuserexistsbyemail',
  getTotalIndCount = 'gettotalindcount',
  updateSlot = 'updateslot',
  testSync = 'testsync',
  getOneRosterSchoolList = 'getonerosterschoollist',
  getRoleBasedIndCount = 'getrolebasedindcount',
  getIndFullName = 'getindfullname',
  checkOneRosterKeyMappingAndSync = 'checkonerosterkeymappingandsync',
  testOneRosterCredential = 'testonerostercredential',
  getOneRosterMappingFields = 'getonerostermappingfields',
}
export enum HttpFnNames {
  blackbaudAutoSync = "blackbaudAutoSync",
  oneRosterSync = "oneRosterSync",
  aeriesSync = "aeriesSync"
}
