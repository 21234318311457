import { Environment, decodeKeys } from './env.interface';
export const environment: Environment = {
  production: false,
  siteUrl: window.location.href.includes("localhost")
  ? "http://localhost:4200/#/"
  : "https://portal-stage.high5.id/#/",
  apiBaseUrl: "https://high5.id/api",
  firebaseImgUrl: "https://firebasestorage.googleapis.com/v0/b/",
  firebaseFunctionsBaseUrl: "https://function-stage.high5.id/httpRequests",
  firebaseCallableFunctionUrl: "https://function-stage.high5.id/funName",
  cloudFunctionsDomain:
  "https://function-stage.high5.id/httpRequests/",
  cloudFunctionsDomainV2:
  "https://function-stage.high5.id/httprequestsv2/",
  zohoBooksV2BaseUrl: "https://high5.id/stage/ZohoBooks/v2",
  redirectToHigh5: window.location.href.includes("localhost")
  ? "http://localhost:8100/#/"
  : "https://high5.id/stage/v/#/",
  firebaseConfig: {
    apiKey: decodeKeys(
      "010000010100100101111010011000010101001101111001010001000111010001010001011001100101011101010000011110010101010101110010001110010011001001001100010100110011001101110000011100010111000001001101011101110011001001010010011010000111000001011000011100110011001101101000001101010100111000110010011001010111011001000001000==="
    ),
    authDomain: "high5-test.firebaseapp.com",
    databaseURL: "https://high5-test.firebaseio.com",
    projectId: "high5-test",
    storageBucket: "high5-test.appspot.com",
    messagingSenderId: "155372021514",
    appId: "1:155372021514:web:f8f8499af2de5bf5973cf3",
    measurementId: "G-2R8WXTHKQZ"
  },
  awsImageUpload: {
    signUrlApi:
      "https://u56iadd5gb.execute-api.us-east-1.amazonaws.com/dev/upload-urls/fetch",
    getImageUrlApi:
      "https://u56iadd5gb.execute-api.us-east-1.amazonaws.com/dev/url/fetch",
    xApiKey: decodeKeys(
      "0110001101000010011000110101010101100101011011000101001101100001010011100100100000110110010110000011100001000010001100000011010001101011011011000011001001101111010011000011010101110001011100110101000001100101010010100110001001000010011011010110100001111010001100100111000001100111010101100110011101000100010001010100011100=="
    ),
    bucketUrl: "https://test-high5-image.s3.amazonaws.com",
    endPoint: "https://u56iadd5gb.execute-api.us-east-1.amazonaws.com/dev",
    awsImageEndPoint: "https://test-high5-image.s3.amazonaws.com",
    deleteFolderApi:
        "https://u56iadd5gb.execute-api.us-east-1.amazonaws.com/dev/objects?path=",
    bucket: "test-high5-image"
  },
  superAdminEmail: "admin@high5.id",
  onrampSetupAdminEmail: "sales@high5.id",
  orgManagerType: "-MKcuuD1prov6sLCgjn_", // Teammate id
  districtType: "-MKdDR96NxuTlMYIOZ5J", //district id
  yourlsObj: {
    username: "admin@vippass.io",
    password: decodeKeys(
      "01110010011000010110111001100100011011110110110101010000011000010111001101110011011101110110111101110010011001000000000======="
    ),
  },
  placeholderImageUrl:
    "https://firebasestorage.googleapis.com/v0/b/vip-pass.appspot.com/o/photos%2Findividuals%2Fimage-not-found.jpg?alt=media&token=db93ec98-fcf5-4791-ba54-b7913d105188",
  mailFromAddress: "noreply",
  elasticSearch: {
    endPoint: "/stage-elastic.php"
  },
  mailgunObj: {
    endPoint: "stage-mailgun-send-mail.php"
  },
  smsObj: {
    endPoint: "stage-message-sms-bulk.php"
  },
  edk: decodeKeys(
    "01110001011001110110010101100011010000010100111001011000011010010110001101010010010101100010000101100010010110100110101100100001010000000110100001001000011010010110010100111001010000010010010001000110000==="
  ),
  exportBulkIndBaseUrl: "stage-export-individuals.php",
  blackbaudObj: {
    baseUrl: "https://api.sky.blackbaud.com/school/v1/",
    subscriptionKey: "f3af7feabcdd44358d677f70f2e55030",
    client_id: "f3015386-7a06-4c1c-9974-dba54b97dda2"
  },
};