import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumbersOnly]'
})
export class NumbersOnlyDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const initialValue = this.el.nativeElement.value;
    // If '+' is not at the beginning or if there are multiple '+', replace all non-digit characters
    if (!/^\+/.test(initialValue) || initialValue.match(/\+/g)?.length > 1) {
      this.el.nativeElement.value = initialValue.replace(/\D/g, '');
    }
  }
}
