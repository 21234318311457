import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appInfiniteScroll]',
  standalone: true
})
export class InfiniteScrollDirective {

  constructor() { }
  @Output() onScrolled = new EventEmitter<void>();

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const target = event.target as HTMLElement;
    const scrollPosition = target.scrollTop + target.clientHeight;
    const totalHeight = target.scrollHeight;

    // If the user has scrolled to the bottom of the container, emit the scrolled event
    if (scrollPosition === totalHeight) {
      this.onScrolled.emit();
    }
  }
}
