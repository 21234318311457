import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, forwardRef } from '@angular/core';
import { environment } from '../../../environments/environment.stage';
import { Subject, Subscription, timeout, defer, Observable, from, BehaviorSubject, firstValueFrom, lastValueFrom } from 'rxjs';
import lodash from 'lodash';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { CacheKeys, CacheService } from './cache/cache.service';
import { MessageService } from 'primeng/api';
import { OrgManagerType, Role, StorageHost, TimeVaryingMethod } from '../constants/enums';
import moment, { Moment, MomentFormatSpecification } from 'moment';
import { Utils } from '../utils/utils';
import { map, mergeAll, reduce, take } from 'rxjs/operators';
import { EncryptionService } from './encryption/encryption.service';
import { UserService } from './user/user.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { getDeviceInfo } from "../../../assets/js/deviceInfo.js";
import { AuthService } from './auth/auth.service';
export interface AlertButton<T> {
  btnText: string;
  value: T;
}
declare var OpenLocationCode;

@Injectable({
  providedIn: 'root',
})
export class CommonServiceService {
  TaxTICCodes = {
    LICENCES: 93205,
    CARDS: 20090,
    SHIPPING: 11000,
  };
  static readonly tvtPathArr = [
    "/visitor/take-visitor-photo",
    "/visitor/visitor-badge"
  ];
  /**
   * Page Header Configuration
   * Enter page Title in "string" Data type
   */
  pageHeader: Subject<string> = new Subject();

  /**
   * Back Button Configuration
   * { "navigateUrl": "URL for page navigation", "pageTitle": "Title of the navigated page", "isShow": "It's consider boolean value for show and hide back arrow" }
   */
  goBackButton$: Subject<any> = new Subject();
  isStudioDataSet: BehaviorSubject<boolean> = new BehaviorSubject(false);
  static readonly ImgExtension = ["png", "jpg", "jpeg", "webp"];
  usaSynonymList = [
    'USA',
    'US',
    'US of a',
    'america',
    'U.S.A',
    'United States',
  ];
  public yearbookArr : any = [
    {
      value: "true",
      label: "Yes",
    },
    {
      value: "false",
      label: "No",
    },
  ];
  constructor(
    private http: HttpClient,
    private db: AngularFireDatabase,
    private firebaseStorage: AngularFireStorage,
    @Inject(forwardRef(() => CacheService)) public cacheProvider,
    @Inject(forwardRef(() => MessageService)) public messageService,
    @Inject(forwardRef(() => Utils)) public utilsService,
    @Inject(forwardRef(() => EncryptionService)) public encryptionService,
    @Inject(forwardRef(() => UserService)) public user
  ) {}
  /**
   * get error message from any error object
   * @param e any type object
   * @returns string error message
   */
  prepareErrorMessage(e: any, defaultMsgInCaseOfErr: string | boolean = true) {
    if (e instanceof Error) {
      return e.message;
    }

    if (lodash.isString(e)) return e;

    // --- this block is for firebase callable functions error handling
    if (e && typeof e == 'object' && lodash.has(e, 'error.error.message'))
      return e.error.error.message;

    // --- this block is for renderer side error (image load failure error)
    if (e && e.error && e.error.details) {
      return e.error.details;
    }

    if (e && e.message) return e.message;
    if (e && e.errorMessage) return e.errorMessage;

    if (lodash.isString(defaultMsgInCaseOfErr)) return defaultMsgInCaseOfErr;
    if (defaultMsgInCaseOfErr)
      return 'Something went wrong. please check console for more details.';
    return null;
  }
  // --- prompt utilities
  readonly promptUtility = {
    dismissBtn: [{ btnText: 'Dismiss', value: null }],
    okBtn: [{ btnText: 'Ok', value: null }],
    yesNoBtns: [
      { btnText: 'No', value: false },
      { btnText: 'Yes', value: true },
    ],
    okCancelBtns: [
      { btnText: 'Cancel', value: false },
      { btnText: 'Ok', value: true },
    ],
  };

  // async prompt<T>(
  //   msg: string,
  //   title: string = "Alert",
  //   buttons: AlertButton<T>[] = this.promptUtility.dismissBtn,
  //   cssClass?: string,
  //   enableBackdropDismiss: boolean = true,
  // ): Promise<T> {
  //   return new Promise<T>((resolve) => {
  //     let alert = this.alertCtrl.create({
  //       message: msg,
  //       title: title,
  //       enableBackdropDismiss: enableBackdropDismiss,
  //       buttons: lodash.map(buttons, (btn) => {
  //         return {
  //           text: btn.btnText,
  //           handler: () => resolve(btn.value),
  //         };
  //       }),
  //       cssClass: cssClass,
  //     });
  //     alert.present();
  //   });
  // }

  // create firebase push id
  createFirebasePushId() {
    return this.db.createPushId();
  }

  getFirebaseGeneratedKey() {
    return this.db.list('nothing').push(null).key;
  }

  createId(str: string): string {
    return str.replace(/[^A-Za-z0-9]/gi, '_');
  }

  createIndId(): string {
    let indId: string = this.createFirebasePushId();
    return indId.substring(1);
  }

  async handleError(error, callback?) {
    console.log('error', error);
    // await this.prompt(this.prepareErrorMessage(error), "Error");
    if (callback) callback();
  }

  /**
   * execute promises with error handling built in
   * @param promise Promise function
   * @returns array containing 2 elements, 1st is result and 2nd is error message if any
   */
  async executePromise(promise: Promise<any>) {
    let res = [null, null];
    try {
      res = [await promise, null];
    } catch (e) {
      res = [null, e];
    }
    return res;
  }

  generateS3SignUrl(obj) {
    let dataObj: any = {
      extensions: obj.extensions,
      path: obj.path,
    };
    if (obj.fileName) dataObj.fileName = obj.fileName;
    if (obj.bucket) dataObj.bucket = obj.bucket;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('x-api-key', environment.awsImageUpload.xApiKey);

    return this.http.post(environment.awsImageUpload.signUrlApi, dataObj, {
      headers: headers,
    });
  }

  isBase64Img(s) {
    if (typeof s == 'string' && s.indexOf(';base64,') != -1) return true;
    return false;
  }

  dataURLtoFile(dataurl: any, filename: string) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  getExtensionFromB64Img(base64) {
    let fileType: string = base64.substring(
      'data:image/'.length,
      base64.indexOf(';base64')
    );
    if (!lodash.find(['png', 'jpg', 'jpeg', 'webp'], (ext) => ext == fileType))
      fileType = 'png';
    return fileType;
  }

  // --- upload contents on given URL
  uploadContentsOnUrl(url: string, contents: any, options: any = {}) {
    return this.http.put(url, contents, options);
  }

  uploadImageOnS3(signUrlApi, fileObj, index?) {
    let file: File;
    if (this.isBase64Img(fileObj)) {
      file = this.dataURLtoFile(
        fileObj,
        `unnamed.${this.getExtensionFromB64Img(fileObj)}`
      );
    } else {
      file = fileObj;
    }

    let head: any = {
      // NOTE: Because we are posting a Blob (File is a specialized Blob
      // object) as the POST body, we have to include the Content-Type
      // header. If we don't, the server will try to parse the body as
      // plain text.
      headers: {
        'Content-Type': file.type,
      },
      // added responseType as text because response is getting null
      responseType: 'text',
    };
    if (lodash.isNil(index)) {
      return this.uploadContentsOnUrl(signUrlApi, file, head);
    } else {
      // --- if index present, that means image upload triggered from loop,
      // we have special formula to find timeout value of the request by the index.
      // formula -> "timeout = (index * 300) + 20000 milliseconds" is a best timeout can be expected for request to be completed
      return this.uploadContentsOnUrl(signUrlApi, file, head).pipe(
        timeout(index * 300 + 20000)
      );
    }
  }

  imageUpload(obj, fileObj) {
    return new Promise((response, reject) => {
      this.generateS3SignUrl(obj).subscribe({
        next: (res) => {
          if (res) {
            this.uploadImageOnS3(res[0].signedUrl, fileObj).subscribe({
              next: (res) => {
                response(res[0].fileName);
              },
              error: (err) => {
                reject();
              }
            });
          }
        },
        error: (err) => {
          reject();
        }
      });
    });
  }

  convertObjToArr(obj) {
    let returnArr = [];
    Object.keys(obj).forEach((objKey) => {
      let value = obj[objKey];
      if (typeof value == 'object') {
        value['key'] = objKey;
        returnArr.push(value);
      }
    });
    return returnArr;
  }

  convertArrToObj(arr: any): any {
    arr = lodash.cloneDeep(arr);
    let data = {};
    arr.forEach((element) => {
      let key = element.key;
      delete element.key;
      data[key] = element;
    });
    return data;
  }

  // --- get studio data using studio id
  async getStudio(id: string, useCache = false) {
    if (!id) return '';

    if (useCache) {
      let cachedData = this.cacheProvider.get(CacheKeys.StudioData, id);
      if (cachedData) return cachedData.value;
    }

    let snapshot = await this.db.object(`/studios/${id}`).query.once('value');
    let studioData = snapshot.val();
    if (!studioData) return '';
    studioData['key'] = id;

    // --- set cache
    this.cacheProvider.set(CacheKeys.StudioData, id, studioData);

    return studioData;
  }

  // --- validates password based on firebase password minimum requirement
  isPwValid(password: string) {
    return lodash.size(password) >= 6;
  }

  prepareCallableFunURL(funName: string) {
    return environment.firebaseCallableFunctionUrl.replace('funName', funName);
  }

  // ---- check blank, undefined or null value
  isBlank(val: string): boolean {
    return val === '' || val === undefined || val === null;
  }

  ignoreCaseComparator(objValue, othValue) {
    return lodash.toLower(objValue) == lodash.toLower(othValue);
  }

  isEqual(val1, val2, ignoreCase: boolean = false) {
    let comparatorFn;
    if (ignoreCase) comparatorFn = this.ignoreCaseComparator;

    if (comparatorFn) return lodash.isEqualWith(val1, val2);
    else return lodash.isEqual(val1, val2);
  }

  // --- present alert (generic)
  async prompt<T>(
    msg: string,
    title: 'success' | 'info' | 'warn' | 'error' | 'confirm' = 'warn',
    detail?: string
  ) {
    this.messageService.add({
      key: title == 'confirm' ? title : null,
      severity: title == 'confirm' ? 'success' : title,
      summary: msg,
      detail: detail,
    });
  }

  // --- get list of all reporting years of organization
  async getAllReportingYears(orgID: string) {
    let reportingYearsObj = (
      await this.db
        .list(`Transactions/${orgID}/ReportingYear`)
        .query.once('value')
    ).val();
    return lodash.map(reportingYearsObj, (ryData: any, key) => {
      return { ...ryData, key };
    });
  }

  // --- clear RY data cache
  clearRYCache(orgOrStudioID) {
    if (
      this.currentCycleData &&
      this.currentCycleData.orgOrStudioId == orgOrStudioID
    ) {
      this.currentCycleData = {};
    }
  }

  /**
   * Get current cycle (reporting year) of org
   * @param orgOrStudioId Organization Or Studio Id
   * @param role Role of the user
   * @returns Reporting Year
   */
  currentCycleData: any = {};
  async getReportingYear(
    orgOrStudioId: string,
    role: string = Role.ORG,
    propToReturn: 'value' | 'fullObj' = 'value',
    skipCache: boolean = false
  ) {
    // --- return value from local variable if available
    if (!orgOrStudioId) throw new Error('params missing!');
    if (
      !skipCache &&
      this.currentCycleData &&
      this.currentCycleData.orgOrStudioId == orgOrStudioId &&
      this.currentCycleData[propToReturn]
    )
      return this.currentCycleData[propToReturn];

    const getCycleData = async (id) => {
      if (!id) return null;
      let snapshot = await this.db
        .list(`Transactions/${id}/ReportingYear`)
        .query.once('value');
      return snapshot.val();
    };

    this.currentCycleData['orgOrStudioId'] = orgOrStudioId;
    let reportingYearsObj = await getCycleData(orgOrStudioId);

    // --- if the role is organization and org has no cycle defined, try to fetch cycle from inheritance
    if (!reportingYearsObj && role == Role.ORG) {
      // --- fetch org data
      let orgData = (
        await this.db
          .object(`organizations/${orgOrStudioId}`)
          .query.once('value')
      ).val();

      let cycleDataPromises = [];
      if (orgData && orgData.type == 'sportsteam') {
        let studioDataPromises = [];
        studioDataPromises.push(this.getStudio(orgData.regionID));
        studioDataPromises.push(this.getStudio(orgData.conferenceID));

        cycleDataPromises = [];
        cycleDataPromises.push(getCycleData(orgData.leagueID));
        cycleDataPromises.push(getCycleData(orgData.regionID));
        cycleDataPromises.push(getCycleData(orgData.conferenceID));
        cycleDataPromises.push(getCycleData('superadmin'));

        let promisesResults;
        try {
          promisesResults = await Promise.all(
            [studioDataPromises, cycleDataPromises].map((pa) => Promise.all(pa))
          );
        } catch (e) {
          console.log('Error while resolving promises: ', e);
        }

        if (promisesResults) {
          let studioDataPromisesResults = promisesResults[0];
          let cycleDataPromisesResults = promisesResults[1];
          const inheritanceRecursion = (
            cycleDataPosition,
            studioDataPosition
          ) => {
            let cycleData = cycleDataPromisesResults[cycleDataPosition];
            if (cycleData) return cycleData;

            if (cycleDataPosition == 0) return null;

            let studioData = studioDataPromisesResults[studioDataPosition];
            if (studioData && studioData.hasOwnProperty('inheritFrom')) {
              if (studioData.inheritFrom == orgData.regionID)
                return inheritanceRecursion(1, 0);
              else if (studioData.inheritFrom == orgData.leagueID)
                return inheritanceRecursion(0, null);
            }
            return null;
          };

          let res;
          if (orgData.studioID == orgData.leagueID)
            res = inheritanceRecursion(0, null);
          else if (orgData.studioID == orgData.regionID)
            res = inheritanceRecursion(1, 0);
          else if (orgData.studioID == orgData.conferenceID)
            res = inheritanceRecursion(2, 1);
          if (res) reportingYearsObj = res;
        }
      } else if (orgData && orgData.studioID) {
        cycleDataPromises = [];
        cycleDataPromises.push(getCycleData(orgData.studioID));
        cycleDataPromises.push(getCycleData('superadmin'));
        let [results, err] = await this.executePromise(
          Promise.all(cycleDataPromises)
        );

        let studioReportingYears = results[0];
        let superadminReportingYears = results[1];
        reportingYearsObj = studioReportingYears
          ? studioReportingYears
          : superadminReportingYears;
      }
    }

    let reportingYears = [];
    lodash.forOwn(reportingYearsObj, (val, key) => {
      if (!val) return;

      val['key'] = key;
      reportingYears.push(val);
    });
    let currentReportingYear = reportingYears.filter((ele) => {
      return ele['isCurrentYear'] == 'Y';
    });
    let currentCalendarYear = new Date().getFullYear();
    let currentCalendarYearRY = lodash.find(
      reportingYears,
      (year) => year.reportingYear == currentCalendarYear
    );
    let ryToBeSetAsCurrentRY = lodash.first(reportingYears);
    if (currentReportingYear.length > 0) {
      this.currentCycleData['value'] = currentReportingYear[0].reportingYear;
      this.currentCycleData['fullObj'] = currentReportingYear[0];
    } else if (currentCalendarYearRY) {
      currentCalendarYearRY['isCurrentYear'] = 'Y';
      this.currentCycleData['value'] = currentCalendarYearRY.reportingYear;
      this.currentCycleData['fullObj'] = currentCalendarYearRY;

      // --- set reporting year as current year
      this.db
        .object(
          `Transactions/${orgOrStudioId}/ReportingYear/${currentCalendarYearRY.key}/isCurrentYear`
        )
        .set('Y');
    } else if (ryToBeSetAsCurrentRY) {
      ryToBeSetAsCurrentRY['isCurrentYear'] = 'Y';
      this.currentCycleData['value'] = ryToBeSetAsCurrentRY.reportingYear;
      this.currentCycleData['fullObj'] = ryToBeSetAsCurrentRY;

      // --- set reporting year as current year
      this.db
        .object(
          `Transactions/${orgOrStudioId}/ReportingYear/${ryToBeSetAsCurrentRY.key}/isCurrentYear`
        )
        .set('Y');
    } else {
      this.currentCycleData['value'] = `${currentCalendarYear}`;
      //
      if (role == Role.STUDIO) {
        let reportingYear = (
          await this.db
            .list(`Transactions/superadmin/ReportingYear/`)
            .query.once('value')
        ).val();
        if (reportingYear) {
          let reportingYearList = this.convertObjToArr(reportingYear);
          reportingYearList = lodash.filter(reportingYearList, (ele) => {
            return ele.isCurrentYear == 'Y';
          });
          delete reportingYearList[0].key;
          this.currentCycleData['fullObj'] = { ...reportingYearList[0] };
          this.currentCycleData['value'] = reportingYearList[0].reportingYear;
        } else {
          this.currentCycleData['fullObj'] = {
            isCurrentYear: 'Y',
            reportingYear: `${new Date().getFullYear()}`,
            yearStartDate: moment().startOf('year').format('YYYY/MM/DD'),
            default: true,
          };
        }
      } else {
        this.currentCycleData['fullObj'] = {
          isCurrentYear: 'Y',
          reportingYear: `${new Date().getFullYear()}`,
          yearStartDate: moment().startOf('year').format('YYYY/MM/DD'),
          default: true,
        };
      }

      // --- create reporting year in DB
      let ryData = lodash.cloneDeep(this.currentCycleData['fullObj']);
      delete ryData.default;
      delete ryData.key;
      this.db.list(`Transactions/${orgOrStudioId}/ReportingYear`).push(ryData);
    }

    return this.currentCycleData[propToReturn];
  }

  /**
   * Get Current User's League Id
   * @returns League Id of current logged in Organization (of type sportsteam) or Org Manager (of type League or Region or Conference).
   * If you're requesting for some invalid type, like school, it will return null
   * @deprecated use getUserLeagueID instead
   */
  getCurrentUserLeagueId() {
    if (this.user.role == Role.STUDIO) {
      let orgManagerTypeName = lodash.get(
        this.user,
        'studio.orgManagerTypeName'
      );
      if (orgManagerTypeName == 'League') {
        return this.user.studioID;
      } else if (
        orgManagerTypeName == 'Region' ||
        orgManagerTypeName == 'Conference'
      ) {
        return this.user.studio.leagueID;
      }
    } else if (this.user.role == Role.ORG) {
      if (
        this.user.org &&
        this.user.org.type == 'sportsteam' &&
        this.user.org.leagueID
      ) {
        return this.user.org.leagueID;
      }
    }
    return null;
  }

  getUserID(user, superAdminID?) {
    if (!user || !user.role) return null;

    if (user.role == Role.ORG) return user.orgID;
    else if (user.role == Role.STUDIO) return user.studioID;
    else if (user.role == Role.SUPERADMIN && superAdminID) return superAdminID;
    else return null;
  }

  getUserData(user) {
    let usersData = {};

    if (!user) return usersData;
    if (user.role == Role.ORG && user.org) {
      usersData[user.orgID] = {
        ...user.org,
        key: user.orgID,
      };
    } else if (user.role == Role.STUDIO && user.studio) {
      usersData[user.studioID] = {
        ...user.studio,
        key: user.studioID,
      };
    }

    return usersData;
  }

  // --- get given user display name
  getUserName(user) {
    if (lodash.has(user, 'subUserData'))
      return `${lodash.get(user, 'subUserData.fname', '')} ${lodash.get(
        user,
        'subUserData.lname',
        ''
      )}`.trim();
    return lodash.get(user, 'org.administratorName', '');
  }

  // -- get given user unique ID (firebase auth id)
  getUserUID(user) {
    if (lodash.has(user, 'subUserData'))
      return lodash.get(user, 'subUserData.key', '');
    return lodash.get(user, 'uid', '');
  }

  // --- get user details mini object
  getUserDetails(user) {
    return {
      userRole: lodash.get(user, 'role') || null,
      userName: this.getUserName(user) || null,
      userID: this.getUserID(user, 'superadmin') || null,
      userUID: this.getUserUID(user) || null,
    };
  }

  /**
   * get league ID of given user
   * @param user current user
   * @returns league ID or null
   * @important due to security rules updates, orgs cannot query organizations node directly like this. Use @method getOrgsList from organizations provider
   */
  getUserLeagueID(user) {
    let role = lodash.get(user, 'role');

    if (role == Role.ORG) {
      let orgType = lodash.get(user, 'org.type');
      let leagueID = lodash.get(user, 'org.leagueID');
      if (orgType == 'sportsteam' && leagueID) return leagueID;
    }

    if (role == Role.STUDIO) {
      let orgManagerTypeName = lodash.get(user, 'studio.orgManagerTypeName');
      if (orgManagerTypeName == 'League') return user.studioID;
      else if (
        orgManagerTypeName == 'Region' ||
        orgManagerTypeName == 'Conference'
      ) {
        return user.studio.leagueID;
      }
    }

    return null;
  }

  // --- check user login is league or region or conference
  isLoginUserISRegionConf(): boolean {
    let orgManagerList = [OrgManagerType.region, OrgManagerType.conference];
    if (lodash.includes(orgManagerList, this.user.studio.orgManagerType)) {
      return true;
    } else {
      return false;
    }
  }

  // --- merge two arrays
  mergeTwoArrUsingKeyMatch(arr1, arr2): any[] {
    let resultantArr = arr1;
    if (!arr2 || arr2.length == 0) return resultantArr;

    let arr1KeyValueMap = lodash.keyBy(arr1, 'key');
    lodash.each(arr2, (item) => {
      if (!arr1KeyValueMap[item.key]) resultantArr.push(item);
    });
    return resultantArr;
  }

  checkAnsFormat(ansStr, surveyAns) {
    if (ansStr == null) return -1;
    ansStr = ansStr.trim();
    if (ansStr.includes('[') && ansStr.includes(']')) {
      if (ansStr.startsWith('[') && ansStr.endsWith(']')) {
        let ansArr = ansStr.split(',');
        let lower = +ansArr[0].replace(/\D/g, '');
        let upper = +ansArr[1].replace(/\D/g, '');
        if (surveyAns >= lower && surveyAns <= upper) {
          return 1;
        } else {
          return -1;
        }
      } else {
        return -1;
      }
    } else if (ansStr.includes('(') && ansStr.includes(')')) {
      if (ansStr.startsWith('(') && ansStr.endsWith(')')) {
        let ansArr = ansStr.split(',');
        let lower = +ansArr[0].replace(/\D/g, '');
        let upper = +ansArr[1].replace(/\D/g, '');
        if (surveyAns > lower && surveyAns < upper) {
          return 1;
        } else {
          return -1;
        }
      } else {
        return -1;
      }
    } else if (ansStr.includes('{') && ansStr.includes('}')) {
      if (ansStr.startsWith('{') && ansStr.endsWith('}')) {
        let ansArr = ansStr.split(',');
        ansArr.map((item, index) => {
          ansArr[index] = +item.replace(/\D/g, '');
        });
        if (ansArr.indexOf(surveyAns) > -1) {
          return 1;
        } else {
          return -1;
        }
      } else {
        return -1;
      }
    } else if (
      lodash.isString(ansStr) &&
      surveyAns.toLowerCase() == surveyAns.toLowerCase()
    ) {
      return 1;
    } else {
      return -1;
    }
  }

  // --- unsubscribe method
  unSub(x: Subscription) {
    if (x && !x.closed) {
      x.unsubscribe();
    }
  }
  getTimezoneUsingLatLong(location): Promise<string> {
    return new Promise((resolve, reject) => {
      let timezone = '';
      let millis = Number(moment().format('x').slice(0, -3));
      this.http
        .get(
          `
      https://maps.googleapis.com/maps/api/timezone/json?location=${location.lat}%2C${location.lng}&timestamp=${millis}&key=AIzaSyDtQfWPyUr92LS3pqpMw2RhpXs3h5N2evA`
        )
        .subscribe({
          next: (timeZoneOffSetRes: any) => {
            if (timeZoneOffSetRes.dstOffset > 0) {
              let index = this.utilsService.timezoneList.findIndex(
                (timezone) => {
                  return (
                    timezone.isdst &&
                    timezone.utc.find((tz) => {
                      return tz == timeZoneOffSetRes.timeZoneId;
                    })
                  );
                }
              );
              if (index > -1)
                timezone = this.utilsService.timezoneList[index].text;
              resolve(timezone);
            } else {
              let index = this.utilsService.timezoneList.findIndex(
                (timezone) => {
                  return timezone.utc.find((tz) => {
                    return tz == timeZoneOffSetRes.timeZoneId;
                  });
                }
              );
              if (index > -1)
                timezone = this.utilsService.timezoneList[index].text;
              resolve(timezone);
            }
          },
          error: (err) => {
            resolve(timezone);
          }
       });
    });
  }

  getTimeZone(data) {
    return new Promise((resolve, reject) => {
      let obj = {
        orgLat: '',
        orgLong: '',
        timezones: '',
        locationPlusCode: '',
      };
      this.http
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${data}&key=AIzaSyDtQfWPyUr92LS3pqpMw2RhpXs3h5N2evA`
        )
        .subscribe(async (res: any) => {
          let location = lodash.get(res, 'results[0].geometry.location');
          if (location && location.lat && location.lng) {
            obj.orgLat = location.lat;
            obj.orgLong = location.lng;

            obj.timezones = await this.getTimezoneUsingLatLong(location);

            // get plus code
            let plusCode = OpenLocationCode.encode(
              location.lat,
              location.lng,
              OpenLocationCode.CODE_PRECISION_EXTRA
            );
            if (plusCode) {
              obj.locationPlusCode = plusCode;
            }
            resolve(obj);
          } else {
            resolve(obj);
          }
        });
    });
  }

  confirm(
    msg = 'Are you sure?',
    title = 'Confirmation',
    positiveBtnText = 'Yes',
    negativeBtnText = 'No',
    cssClass?,
    positiveBtnHandler?: Function,
    negativeBtnHandler?: Function
  ): Promise<boolean> {
    return new Promise((resolve) => {
      this.messageService.add({
        key: 'alertToast',
        severity: 'info',
        summary: title,
        detail: msg,
        sticky: true,
        life: 0,
        closable: false,
        data: {
          negativeBtnText: negativeBtnText,
          positiveBtnText: positiveBtnText,
          negativeBtnHandler: negativeBtnHandler,
          positiveBtnHandler: positiveBtnHandler,
        },
      });
    });
  }

  // --- calculate tax
  async getTax(requestObj) {
    let requestBody = {
      type: 'getTaxDetails',
      cartID: 'usdfsdfdsfdsfdsuid222',
      deliveredBySeller: true,
      origin: {
        Address1: '4219 S Market Ct Ste N Sacremento, CA 95834',
        City: 'Sacremento, CA',
        State: 'CA',
        Zip5: '95834',
      },
      ...requestObj,
    };

    let [response, error] = await this.executePromise(
      this.http
        .post(`${environment.apiBaseUrl}/api.php`, requestBody)
        .pipe(timeout(30 * 1000), take(1))
        .toPromise()
    );

    let success = lodash.get(response, 'success');
    if (!error && !success) error = lodash.get(response, 'message');

    if (error) throw new Error(this.prepareErrorMessage(error));

    response = lodash.get(response, 'data');
    let cartItemsTaxData = lodash.get(response, 'CartItemsResponse');
    if (!cartItemsTaxData || cartItemsTaxData.length == 0) {
      let messages = lodash.get(response, 'Messages');
      let errorMessages = [];
      if (messages && messages.length > 0) {
        lodash.each(messages, (message) => {
          let msg: string = lodash.get(message, 'Message', '');
          errorMessages.push(
            msg.toLowerCase().indexOf('not valid for this state') > -1
              ? `Teammate Zipcode is not valid (${requestObj.destination.Zip5}).`
              : msg
          );
        });
      } else {
        errorMessages.push('Something went wrong calculating tax.');
      }
      throw new Error(errorMessages.join('<br/>'));
    }

    return cartItemsTaxData;
  }

  // --- get Individuals List
  getIndividualsList(orgId: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let indListSub = this.db
        .list(`/individuals/${orgId}`)
        .snapshotChanges()
        .subscribe({
         next: (list) => {
            indListSub.unsubscribe();
            let indList = [];
            if (list.length > 0) {
              lodash.each(list, (entry) => {
                let value: any = entry.payload.val();
                value['key'] = entry.payload.key;
                if (
                  value.role &&
                  value.disabled != true &&
                  this.isDateTimeRangValid(
                    value.idValid_mode,
                    'now',
                    value.idValid_startDateTime,
                    value.idValid_endDateTime,
                    value.idValid_invalidAfterDateTime,
                    value.idValid_validAfterDateTime
                  )
                )
                  indList.push(value);
              });
              resolve(indList);
            } else {
              resolve([]);
            }
          },
          error: (err) => {
            console.log('err: ', err);
            reject([]);
          }
        });
    });
  }

  isDateTimeRangValid(
    val: number,
    currentDateTime: any,
    startDateTime: number,
    endDateTime: number,
    beforeDateTime: number,
    afterDateTime: number
  ) {
    currentDateTime = isNaN(currentDateTime)
      ? Number(moment().format('x'))
      : Number(currentDateTime);
    let currentFormate = !lodash.isBoolean(val)
      ? val > 6
        ? 'YYYY/MM/DD HH:mm'
        : val <= 6 && val > 2
        ? 'HH:mm'
        : 'x'
      : 'x';
    let currentMoment: any = moment(currentDateTime).format(currentFormate);
    currentMoment = moment(currentMoment, currentFormate);
    const startMoment = moment(
      moment(startDateTime, 'x').format(currentFormate),
      currentFormate
    );
    const endMoment = moment(
      moment(endDateTime, 'x').format(currentFormate),
      currentFormate
    );
    const beforeMoment = moment(
      moment(beforeDateTime, 'x').format(currentFormate),
      currentFormate
    );
    const afterMoment = moment(
      moment(afterDateTime, 'x').format(currentFormate),
      currentFormate
    );
    switch (val) {
      case TimeVaryingMethod.YES:
        return true;

      case TimeVaryingMethod.NO:
        return false;

      case TimeVaryingMethod.YESINTIME:
      case TimeVaryingMethod.YESINDATETIME:
        return currentMoment.isBetween(startMoment, endMoment);

      case TimeVaryingMethod.NOINTIME:
      case TimeVaryingMethod.NOINDATETIME:
        return !currentMoment.isBetween(startMoment, endMoment);

      case TimeVaryingMethod.YESAFTERTIME:
      case TimeVaryingMethod.YESAFTERDATETIME:
        return (
          afterMoment.isBefore(currentMoment) &&
          !afterMoment.isAfter(currentMoment)
        );

      case TimeVaryingMethod.NOAFTERTIME:
      case TimeVaryingMethod.NOAFTERDATETIME:
        return (
          beforeMoment.isAfter(currentMoment) &&
          !beforeMoment.isBefore(currentMoment)
        );

      default:
        return true;
    }
  }

  // --- check email validation
  isEmailVerified(email: string): Boolean {
    let regexp: RegExp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const isBlank = (val) => {
      return val === '' || val === undefined || val === null;
    };
    if (!email || isBlank(email) || !regexp.test(email)) {
      return false;
    }
    return true;
  }

  isEmailValid(email: string): boolean {
    let regexp: RegExp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape
    );
    if (!email || !regexp.test(email)) return false;
    return true;
  }

  getCurrentURLRootPath() {
    let path = `${location.protocol}//${location.host}`;
    if (location.pathname.indexOf("/stage") > -1) {
      path += `/stage`;
    }
    return path;
  }

  failedImportHash: any = {};
  // sport field program options
  fieldArr = [
    'birth',
    'class',
    'email',
    'guardianEmail',
    'genNewPW',
    'guardianPhone',
    'firstName',
    'middleName',
    'lastName',
    'suffix',
    'studentID',
    'hash',
    'homeRoom',
    'mobilePhone',
    'photo',
    'role',
    'orgId',
    'photoUrl',
    'lockerNumber',
    'custom3',
    'timestampCreated',
    'timestampUpdated',
    'jerseyNumber',
  ];

  // --- execute observables with parallel execution limit (eg. max 10 observable executions should run parallel)
  executeObservablesWithParallelLimit(
    observables: Observable<any>[],
    limit: number = 10,
    onProgress?: (processedCount: number) => void
  ) {
    let processedCount = 0;
    return this.executePromise(
      from(observables)
        .pipe(mergeAll(limit))
        .pipe(
          reduce((acc, val) => {
            if (onProgress) onProgress(++processedCount);
            return acc.concat(val);
          }, [])
        )
        .toPromise()
    );
  }

  // Decrypt Response
  decryptResponse(res: string) {
    return JSON.parse(this.encryptionService.decrypt(res, environment.edk));
  }

  generatePassword() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i <= length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  sessionID;
  getSessionID() {
    if (!this.sessionID) this.sessionID = this.randomIntFromInterval(0, 999999);
    return this.sessionID;
  }
    // --- generate random integer in the given range
    randomIntFromInterval(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
  

  // --- get high5 trace
  getH5Trace() {
    return localStorage.getItem("h5:trace");
  }
    // --- add high5 trace (eg. helpful for partial issues debugging on client's machines)
    addH5Trace(trc: H5Trace) {
      try {
        // --- add session id in trace
        lodash.set(trc, "sid", this.getSessionID());
  
        const maxTraceCount = 100;
  
        // --- extract trace from cookies
        let trace: any = this.getH5Trace();
        if (!trace) trace = "[]";
  
        // --- parse trace
        try {
          trace = JSON.parse(trace);
        } catch (e) {
          console.log("Error parsing trace", e, trace);
          return;
        }
  
        // --- add new item to trace
        trace = lodash.concat(trace, trc);
  
        // --- maintain limited trace size
        let traceSize = lodash.size(trace);
        let diff = maxTraceCount - traceSize;
        if (diff < 0) trace = lodash.slice(trace, Math.abs(diff));
  
        // --- store trace back to cookies
        localStorage.setItem("h5:trace", JSON.stringify(trace));
      } catch (e) {
        console.log("Error storing trace", e);
      }
    }  
    sendLogToSlack(msg) {
      this.http
        .post(
          "https://hooks.slack.com/services/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",
          { channel: "#high5-prod1-bug", text: msg },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            responseType: "text",
          }
        )
        .subscribe({
          next: (res) => {
            console.log("res: ", res);
          },
          error: (err) => {
            console.log("err: ", err);
          }
        });
    }

    getCachePathForProps(props: string[]) {
      let containsAll = lodash.some(props, (prop) => prop == "all");
      if (containsAll) return `all`;
      else {
        return lodash
          .chain(props)
          .orderBy((prop) => prop)
          .join("_")
          .value();
      }
    }

      // --- convert callback function to promise
  promisify(fn: Function, callbackArgPosition, self) {
    return function (...args) {
      return new Promise((resolve, reject) => {
        let callbackFunction = (...callbackArgs) => {
          if (callbackArgs.length == 0) resolve(null);
          else if (callbackArgs.length == 1) resolve(callbackArgs[0]);
          else resolve(callbackArgs);
        };
        args[callbackArgPosition] = callbackFunction;

        fn.call(self, ...args);
      });
    };
  }

   /**
   * get filename and extension from URL
   * @param url url
   */
   getFileNameAndExtensionFromURL(
    url: string,
    defFileName: string = "untitled"
  ) {
    let urlWithoutQueryParams = lodash.replace(url, /\?.*/g, "");
    let fileNameWithExt = lodash
      .chain(urlWithoutQueryParams)
      .split("/")
      .last()
      .value();
    let ary = lodash.split(fileNameWithExt, ".");
    if (ary.length <= 1) return { fileName: defFileName, extension: "png" };

    let fileName: string = lodash.nth(ary, 0);
    let extension = lodash.toLower(lodash.nth(ary, 1));

    let isValidExt = lodash.some(
      CommonServiceService.ImgExtension,
      (ext) => ext == extension
    );
    if (!isValidExt) extension = "png";

    return { fileName, extension };
  }

  goToLink(url) {
    window.open(url, "_blank");
  }

  isValidPhone(value) {
    return /^[0-9 \-\+\(\)]*$/g.test(`${value}`);
  }
  
     /**
   * get url of give firebase storage path
   * @param pathOrURL Firebase Path or Any URL
   * @returns URL of the file/image
   */
     async fetchFirebaseStorageDownloadURL(pathOrURL) {
      if (!pathOrURL) return null;
  
      if (
        pathOrURL.indexOf("http") > -1 ||
        pathOrURL.indexOf("firebasestorage") > -1
      )
        return pathOrURL;
  
      let [logoUrl, error] = await this.executePromise(
        this.firebaseStorage
          .ref(pathOrURL)
          .getDownloadURL()
          .pipe(take(1))
          .toPromise()
      );
      if (logoUrl) return logoUrl;
      if (error) console.log("Error while fetching logo url: ", error);
      return null;
    }

      /**
   * Generate Random string id of the given length
   * @param length Length of the id you want to generate
   */
  public generateId(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

   // --- delete file from high5 storages
   async deleteFile(url: string) {
    let deleteFilePromise;
    let storageHost: StorageHost = this.getStorageHostFromURL(url);
    if (storageHost == StorageHost.S3) {
      let storagePath = this.extractS3StoragePath(url);
      if (storagePath) {
        let reqBody = {
          type: 'deleteObjects',
          objectPaths: [storagePath],
        };
        deleteFilePromise = await lastValueFrom(
          this.http
            .post(`${environment.awsImageUpload.endPoint}/s3`, reqBody, {
              headers: { 'x-api-key': environment.awsImageUpload.xApiKey },
            })
            .pipe(take(1))
        );
      }
    } else if (storageHost == StorageHost.FIREBASE) {
      let storagePath = this.extractFirebaseStoragePath(url);
      if (storagePath)
        deleteFilePromise = await lastValueFrom(
          this.firebaseStorage.ref(storagePath).delete().pipe(take(1))
        );
    }

    return deleteFilePromise;
  }

   /**
   * Get storage host name from given URL
   * @param url url string
   * @returns Storage host where the file is stored
   */
   getStorageHostFromURL(url: string): StorageHost {
    if (!url) return StorageHost.UNKNOWN;

    if (url.indexOf(environment.awsImageUpload.awsImageEndPoint) > -1)
      return StorageHost.S3;
    if (url.indexOf(environment.firebaseImgUrl) > -1) return StorageHost.FIREBASE;
    return StorageHost.UNKNOWN;
  }

  /**
   * extract firebase storage file path from URL
   * @param url firebase storage file URL
   * @returns path of file in firebase storage
   */
  extractFirebaseStoragePath(url: string) {
    if (!url) return null;

    url = lodash.replace(url, /%2F/gi, '/');
    url = lodash.replace(url, /\?.*/gi, '/');
    let path = lodash.replace(
      url,
      `${environment.firebaseImgUrl}${environment.firebaseConfig.storageBucket}/o/`,
      ''
    );

    if (path.indexOf('http') > -1) return null;
    else return path;
  }

  /**
   * extract s3 storage file path from URL
   * @param url s3 storage file url
   * @returns path of file in s3 storage
   */
  extractS3StoragePath(url: string) {
    if (!url) return null;

    let regex = /https:\/\/[^.]*.s3.amazonaws.com\//g;
    let path = lodash.replace(url, regex, '');

    if (path.indexOf('http') > -1) return null;
    else return path;
  }

  deleteImageFolder(path){
    let url = `${environment.awsImageUpload.endPoint}/objects?path=${path}`
    let head:any = {
      // NOTE: Because we are posting a Blob (File is a specialized Blob
      // object) as the POST body, we have to include the Content-Type
      // header. If we don't, the server will try to parse the body as
      // plain text.
      headers: {
        'x-api-key': environment.awsImageUpload.xApiKey
      },
      // added responseType as text because response is getting null
      responseType: 'text'
    }
    return this.http.delete(url, head)
   }

   convertKeysToLowerCase(arrayOfObjects: any[]): any[] {
    return arrayOfObjects.map(obj => {
      const newObj = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          newObj[key.toLowerCase()] = obj[key];
        }
      }
      return newObj;
    });
  }

  guid(len) {
    var buf = [],
      chars = "0123456789",
      charlen = chars.length,
      length = len || 32;
    for (var i = 0; i < length; i++) {
      buf[i] = chars.charAt(Math.floor(Math.random() * charlen));
    }
    return buf.join("");
  }

  async downloadFile(fileURL: string, fileName: string): Promise<void> {
    const response = await fetch(fileURL);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const element = document.createElement("a");
    element.href = url;
    element.download = fileName;
    element.click();
    URL.revokeObjectURL(url);
  }

    /**
    * @param photographerCount: number
    * @param tracksPerPhotographer: number;
    * @param startTime: miliseconds;
    * @param endTime: milisecounds;
    * @param slotDuration: minutes;
    * @param slotType: 'booking' | 'lunch';
    * @param lunchStartTime: milisecounds;
    * @param lunchDuration: minutes;
    * @param photographers: number;
    * @param photographyDayCount: number;
    * @param numberOfSlotRequired: number;
    * @param eventStartDate: milliseconds;
    * @param lunchOffset: minutes;
    * @param slotOffset: minutes;
    * @return {
    * slots: any[];
    * tracks: any[];
    * photographers: any[]
    *  };
  */
    generateSlots(
      photographerCount: number,
      tracksPerPhotographer: number,
      startTime: number,
      endTime: number,
      slotDuration: number,
      slotType: 'booking' | 'lunch',
      lunchStartTime: number,
      lunchDuration: number,
      numberOfSlotRequired: number,
      eventStartDate: number,
      lunchOffset: number,
      slotOffset: number,
      isForSlotGenerate: boolean = false
    ) {
      let slotCounter = 0;
      let photographerList = [];
      for (
        let photographerCounter = 0;
        photographerCounter < photographerCount;
        photographerCounter++
      ) {
        let photographer = {
          key: this.createFirebasePushId(),
          photographerName: 'Photographer ' + (+photographerCounter + 1),
        };
        photographerList.push(photographer);
      }
  
      if (!!(photographerList.length > 0)) {
        let trackList: any[] = [];
        let slotList: any[] = [];
        photographerList.map((photographer: any) => {
          for (
            let trackCounter = 0;
            trackCounter < tracksPerPhotographer;
            trackCounter++
          ) {
            const track: any = {
              photographerId: photographer.key,
              trackName: 'Track ' + String.fromCharCode(65 + (trackCounter % 26)),
              key: this.createFirebasePushId(),
            };
            trackList.push(track);
          }
        });
        let dayCounter = 0;
        let dayWiseSlotCountList: number[] = [];
        let dayWiseSlotList:any[] =[];
        if (!!(trackList.length > 0)) {
          for (dayCounter = 0; slotCounter < numberOfSlotRequired; dayCounter++) {
            let slotListByDay:any[] =[];
            let slotCountByDay: number = 0;
            const startTimeMoment = moment(eventStartDate)
              .set('hours', moment(startTime).hours()).set('minutes', moment(startTime).minutes()).set('seconds', 0)
              .add(dayCounter, 'days');
            const endTimeMoment = moment(eventStartDate)
              .set('hours', moment(endTime).hours()).set('minutes', moment(endTime).minutes()).set('seconds', 0)
              .add(dayCounter, 'days');
            const lunchStartTimeMoment = moment(eventStartDate)
              .set('hours', moment(lunchStartTime).hours()).set('minutes', moment(lunchStartTime).minutes()).set('seconds', 0)
              .add(dayCounter, 'days');
            const slotDurationMoment = moment.duration(
              Number(slotDuration),
              'minutes'
            );
            const lunchDurationMoment = moment.duration(
              Number(lunchDuration),
              'minutes'
            );
  
            const slotOffsetMinutes: moment.Duration = moment.duration(
              Number(slotOffset),
              'minutes'
            );
            const lunchOffsetMinutes: moment.Duration = moment.duration(
              Number(lunchOffset),
              'minutes'
            );
            
            if(endTimeMoment.diff(startTimeMoment, 'minutes') < slotDurationMoment.asMinutes()){
              throw new Error('Invalid slotDuration')
            }
            let slots: any[] = [];
            let lunchSlots: any[] = [];
            if (slotCounter < numberOfSlotRequired && lunchStartTimeMoment) {
              for (let i = 0; i < photographerList.length; i++) {
                let tracksOfPhotographer = trackList.filter(
                  (track) => track.photographerId == photographerList[i].key
                );
                const photographerSlotStart = lunchStartTimeMoment.clone();
                const photographerSlotEnd = lunchStartTimeMoment
                  .clone()
                  .add(lunchDurationMoment.asMinutes(), 'minutes');
                lunchStartTimeMoment.add(
                  lunchOffsetMinutes.asMinutes(),
                  'minutes'
                );
                for (let track of tracksOfPhotographer) {
                  lunchSlots.push({
                    startTime: photographerSlotStart.valueOf(),
                    endTime: photographerSlotEnd.valueOf(),
                    type: 'lunch',
                    trackId: track.key,
                    key: this.createFirebasePushId(),
                  });
                }
              }
            }
  
            let currentTime = startTimeMoment.clone();
            let isDayComplete = false;
            for (
              let slotIndex = 0;
              slotIndex < numberOfSlotRequired &&
              slotCounter < numberOfSlotRequired &&
              !isDayComplete;
              slotIndex++
            ) {

              let slotStart = currentTime.clone();
              currentTime.add(slotDurationMoment.asMinutes(), 'minutes');
              let tempCurrentTime = currentTime.clone();
              let tempSlotStartTime = slotStart.clone();
              for ( let j = 0; j < tracksPerPhotographer &&
                (slotCounter < numberOfSlotRequired) &&
                !isDayComplete; j++ ) {

              for (
                let i = 0;
                i < photographerList.length &&
                slotCounter < numberOfSlotRequired &&
                !isDayComplete;
                i++
              ) {
                let tracksOfPhotographer = lodash.chain(trackList).filter(
                  (track) => track?.photographerId == photographerList[i]?.key
                ).sortBy('trackName').value();

                let photographerSlotStart = slotStart.clone();
                slotStart.add(slotOffsetMinutes.asMinutes(), 'minutes');
               
                  const lunchSlot = lunchSlots.find(
                    (slot) => slot.trackId == tracksOfPhotographer[j].key
                  );
                  let isLunchSlotExist =
                    !!slots.find((slot) => slot?.key == lunchSlot?.key) || false;
                  const lunchSlotStartMoment = moment(lunchSlot.startTime);
                  const lunchSlotEndMoment = moment(lunchSlot.endTime);
                  if (!lunchSlotStartMoment.isSame(lunchSlotEndMoment) &&
                    photographerSlotStart
                      .clone()
                      .isSameOrAfter(lunchSlotStartMoment) &&
                    photographerSlotStart
                      .clone()
                      .isSameOrBefore(lunchSlotEndMoment)
                  ) {
                    if (j < 1 && i < 1) {
                      photographerSlotStart = lunchSlotEndMoment.clone()
                      tempCurrentTime = lunchSlotEndMoment
                        .clone()
                        .add(slotDurationMoment.asMinutes(), 'minutes')
                        
                    }
                    if (!isLunchSlotExist && !lunchSlotStartMoment.isSame(lunchSlotEndMoment)) {
                      slots.push(lunchSlot);
                      isLunchSlotExist = true;
                    }
                    if (
                      i == photographerList.length - 1 &&
                      j == tracksOfPhotographer.length - 1
                    ) {
                      currentTime = tempCurrentTime.clone();                      
                    }
                  }

                    const trackSlotStart = photographerSlotStart.clone();
                    const trackSlotEnd = photographerSlotStart
                    .clone()
                    .add(slotDurationMoment.asMinutes(), 'minutes');
                  if (!lunchSlotStartMoment.isSame(lunchSlotEndMoment) ?
                   ((!trackSlotStart.isSameOrAfter(lunchSlotEndMoment) &&
                    !currentTime.isSameOrAfter(lunchSlotEndMoment) &&
                    !tempSlotStartTime.isSameOrAfter(lunchSlotEndMoment)) &&
                    (trackSlotEnd.isSameOrBefore(lunchSlotStartMoment) &&
                    currentTime.isSameOrBefore(lunchSlotStartMoment)) &&
                    tempSlotStartTime.isSameOrBefore(lunchSlotStartMoment)) : true
                  ) {
                    // slotStart.add(slotOffsetMinutes.asMinutes(), 'minutes');
                    if (
                      trackSlotEnd.isAfter(endTimeMoment) ||
                      trackSlotStart.isSameOrAfter(endTimeMoment)
                    ) {
                      isDayComplete = true;
                      break;
                    }
                    let slotData = {
                      startTime: trackSlotStart.valueOf(),
                      endTime: trackSlotEnd.valueOf(),
                      type: slotType,
                      trackId: tracksOfPhotographer[j].key,
                      key: this.createFirebasePushId(),
                    };
                    slots.push(slotData);
                    slotListByDay.push(slotData)
                    slotCounter++;
                    slotCountByDay++;
                  } else {
                    if (!isLunchSlotExist && !lunchSlotStartMoment.isSame(lunchSlotEndMoment)) {
                      slots.push(lunchSlot);
                    }
                  }
              }
            }
            }

            // after lunch
            currentTime = moment(eventStartDate)
            .set('hours', moment(lunchStartTime).hours()).set('minutes', moment(lunchStartTime).minutes()).set('seconds', 0)
            .add(dayCounter, 'days').add(lunchDurationMoment.asMinutes(), 'minutes')
            // console.log('currentTime: ', currentTime.format('HH:mm'));
            for (
              let slotIndex = 0;
              slotIndex < numberOfSlotRequired &&
              slotCounter < numberOfSlotRequired &&
              !isDayComplete;
              slotIndex++
            ) {

              let slotStart = currentTime.clone();
              currentTime.add(slotDurationMoment.asMinutes(), 'minutes');
              for ( let j = 0; j < tracksPerPhotographer &&
                (slotCounter < numberOfSlotRequired) &&
                !isDayComplete; j++ ) {

              for (
                let i = 0;
                i < photographerList.length &&
                slotCounter < numberOfSlotRequired &&
                !isDayComplete;
                i++
              ) {
                let tracksOfPhotographer = lodash.chain(trackList).filter(
                  (track) => track?.photographerId == photographerList[i]?.key
                ).sortBy('trackName').value();

                let photographerSlotStart = slotStart.clone();
                slotStart.add(slotOffsetMinutes.asMinutes(), 'minutes');
               
                  const lunchSlot = lunchSlots.find(
                    (slot) => slot.trackId == tracksOfPhotographer[j].key
                  );
                  let isLunchSlotExist =
                    !!slots.find((slot) => slot?.key == lunchSlot?.key) || false;
                  const lunchSlotStartMoment = moment(lunchSlot.startTime);
                  const lunchSlotEndMoment = moment(lunchSlot.endTime);
                  if(lunchSlotStartMoment.isSame(lunchSlotEndMoment)) break;
                    const trackSlotStart = photographerSlotStart.clone();
                    const trackSlotEnd = photographerSlotStart
                    .clone()
                    .add(slotDurationMoment.asMinutes(), 'minutes');
                  if (
                   ((trackSlotStart.isSameOrAfter(lunchSlotEndMoment) &&
                    currentTime.isSameOrAfter(lunchSlotEndMoment)) &&
                    (!trackSlotEnd.isSameOrBefore(lunchSlotStartMoment) &&
                    !currentTime.isSameOrBefore(lunchSlotStartMoment)))
                  ) {
                    // slotStart.add(slotOffsetMinutes.asMinutes(), 'minutes');
                    // console.log(slotCounter,'slotCounter');
                    // console.log('slotCountByDay: ', slotCountByDay);
                    if (
                      trackSlotEnd.isAfter(endTimeMoment) ||
                      trackSlotStart.isSameOrAfter(endTimeMoment)
                    ) {
                      isDayComplete = true;
                      break;
                    }
                    let slotData = {
                      startTime: trackSlotStart.valueOf(),
                      endTime: trackSlotEnd.valueOf(),
                      type: slotType,
                      trackId: tracksOfPhotographer[j].key,
                      key: this.createFirebasePushId(),
                    };
                    slots.push(slotData);
                    slotListByDay.push(slotData)
                    slotCounter++;
                    slotCountByDay++;
                  } else {
                    if (!isLunchSlotExist && !lunchSlotStartMoment.isSame(lunchSlotEndMoment)) {
                      slots.push(lunchSlot);
                    }
                  }
              }
            }
            }
            dayWiseSlotCountList.push(slotCountByDay);
            dayWiseSlotList.push(slotListByDay);
            slotList.push([...slots]);
          }
          slotList = slotList.flat();
        }
        let slotCountRes = {
          dayCount: dayCounter || 0,
          dayWiseSlotCountList: dayWiseSlotCountList || [],
          totalSlotGenerated: slotCounter,
          dayWiseSlotList: dayWiseSlotList
        };
        let slotGenAndCountRes = {
          photographerList: photographerList || [],
          trackList: trackList || [],
          slotList: slotList || [],
          ...slotCountRes,
        };
  
        return isForSlotGenerate ? slotGenAndCountRes : slotCountRes;
      } else {
        let slotCountRes = {
          dayCount: 0,
          dayWiseSlotCountList: [],
          totalSlotGenerated: 0,
          dayWiseSlotList: []
        };
        let slotGenAndCountRes = {
          photographerList: photographerList || [],
          trackList: [],
          slotList: [],
        };
  
        return isForSlotGenerate ? slotGenAndCountRes : slotCountRes;
      }
    }

  calculateRequiredSlotCount(subjects: number, percent: number) {
    let requiredSlots: number = 0;
      if(subjects > 0){
        if (percent > 0) {
          requiredSlots = subjects + (subjects * percent) / 100;
        } else {
          requiredSlots = subjects;
        }
      }
    return requiredSlots || 0;
  }

  /**
    * @param startTime: miliseconds;
    * @param endTime: milisecounds;
    * @param lunchStartTime: milisecounds;
    * @param lunchDuration: minutes;
    * @param slotDuration: minutes;
    * @param photographers: number;
    * @param tracksPerPhotographer: number;
    * @return perDaySlot: number;
  */
  calculatePerDaySlotCount(
    startTime: number,
    endTime: number,
    lunchStartTime: number,
    lunchDuration: number,
    slotDuration: number,
    photographers: number,
    tracksPerPhotographer: number
  ) {
    const startTimeMoment = moment(startTime);
    const endTimeMoment = moment(endTime);
    const lunchStartTimeMoment = moment(lunchStartTime);
    const lunchEndTimeMoment = lunchStartTimeMoment.clone().add(
      Number(lunchDuration),
      'minutes'
    );
    const slotDurationMoment = moment.duration(Number(slotDuration), 'minutes');

    const calculateSlotsToGenerate = (
      startTimeMoment: Moment,
      endTimeMoment: Moment,
      slotDurationMoment: moment.Duration
    ) => {
      // Calculate the duration between start and end time
      const duration = moment.duration(endTimeMoment.diff(startTimeMoment));
      // Calculate the number of slots
      const numberOfSlots = Math.floor(
        duration.asMinutes() / slotDurationMoment.asMinutes()
      );
      return numberOfSlots || 0;
    };
    const beforeLunchSlotCount = calculateSlotsToGenerate(
      startTimeMoment,
      lunchStartTimeMoment,
      slotDurationMoment
    );
    const afterLunchSlotCount = calculateSlotsToGenerate(
      lunchEndTimeMoment,
      endTimeMoment,
      slotDurationMoment
    );

    return (beforeLunchSlotCount + afterLunchSlotCount) * (photographers * tracksPerPhotographer) || 0;
  }


  generateUniqueNumber(start: number, end: number) {
    // Validate input
    if (typeof start !== 'number' || typeof end !== 'number' || start >= end) {
      throw new Error(
        'Invalid input: start and end must be numbers, and start must be less than end'
      );
    }

    // Create an array with all numbers from start to end
    const numberArray: number[] = [];
    for (let i = start; i <= end; i++) {
      numberArray.push(i);
    }

    for (let i = numberArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [numberArray[i], numberArray[j]] = [numberArray[j], numberArray[i]];
    }

    return numberArray;
  }
  
  logs = [];
  appendLog(log: string) {
    this.logs.push(`${log} :: ${moment().valueOf()}`);
  }

  async reportLogs() {
    try {
      let deviceID = "";
      try {
        deviceID = localStorage.getItem("deviceID");
      } catch (e) {}
      if (!deviceID) {
        deviceID = this.generateId(10);
        try {
          localStorage.setItem("deviceID", deviceID);
        } catch (e) {}
      }

      let log = {
        details: JSON.stringify(this.logs),
        timestamp: moment().valueOf(),
        url: location.href
      };
      await this.db.list(`temp/${deviceID}/`).push(log);
      await this.db.object(`temp/${deviceID}/deviceInfo`).set(getDeviceInfo());
      this.resetLogs();
    } catch (e) {
      console.log("Error reporting logs", e);
      return false;
    }

    return true;
  }

  resetLogs() {
    this.logs = [];
  }

  // --- wait for authorization to be set
  async waitForAuthorization(authService: AuthService) {
    // --- wait for authorization
    let [res, authStateErr] = await this.executePromise(
      authService.waitForAuthStateToGetReady()
    );
    console.log('res: ', res);
    if (authStateErr) {
      await this.handleError(authStateErr);
      location.reload();
      return false;
    }
    return true;
  }
  
    /**
   * Check if the current device is mobile/tablet or something else
   * @returns true if device is mobile/tablet. false otherwise
   */
    mobileAndTabletCheck() {
      let check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor);
      return check;
    }

  // --- check if given time is within given limit or not
  isTimeWithin(
    timeToCheck: string,
    format: MomentFormatSpecification,
    limit: number,
    unit: moment.unitOfTime.DurationConstructor
  ) {
    if (!timeToCheck) return false;

    return (
      moment().valueOf() <
      moment(timeToCheck, format)
        .add(limit, unit)
        .valueOf()
    );
  }

  extractTags(inputString) {
    const regex = /~\^(.+?)~\^/g;
    let tags = [];
    let match;
    while ((match = regex.exec(inputString)) !== null) {
      tags.push(match[1]);
    }
    return tags;
  }

  replaceTags(inputString, replacements) {
    return inputString.replace(/~\^(.+?)~\^/g, (tag, tagString) => {
      return replacements[tagString] || tag;
    });
  }

  getFieldKeyword(key) {
    if (key != "") {
      return this.db.object("/Keywords/" + key).valueChanges();
    } else {
      return this.db.list("/Keywords/")
        .snapshotChanges()
        .pipe(
          map((changes) =>
            changes.map((c: any) => ({ key: c.payload.key, ...c.payload.val() }))
          )
        );
    }
  }
  // Update Authentication Password
  // Org Manager, Organization, Sub User
  // All Account Password Change
  updateAuthEmailPassword(reqBody: any) {
    return firstValueFrom(
      this.http.post(`${environment.cloudFunctionsDomain}updateEmailPassword`, reqBody)
    );
  }

  formatOneRosterSyncBaseUrl(baseUrl: string) {
    let syncBaseUrl = baseUrl.trim();

    // Remove 'https://' if it starts with it
    if (syncBaseUrl.startsWith("https://")) {
      syncBaseUrl = syncBaseUrl.replace("https://", '');
    }
    // Remove trailing '/' if present
    if (syncBaseUrl.startsWith("/")) {
      syncBaseUrl = syncBaseUrl.replace(/^\/+/, '');
    }
    // Remove trailing '/' if present
    if (syncBaseUrl.endsWith('/')) {
      syncBaseUrl = syncBaseUrl.slice(0, -1);
    }

    syncBaseUrl = this.cleanOneRosterUrl(syncBaseUrl);
    let finalUrl = syncBaseUrl || "";
    if(syncBaseUrl.includes('v1p2')){
      if(!syncBaseUrl.endsWith("ims/oneroster/rostering/v1p2")){
        finalUrl = `${finalUrl}/ims/oneroster/rostering/v1p2`      
      }
    } else if(!syncBaseUrl.endsWith("/ims/oneroster/v1p1")) {      
      finalUrl = `${finalUrl}/ims/oneroster/v1p1`;
    }
    syncBaseUrl = syncBaseUrl.replace("ims/oneroster/rostering/v1p2", "")
    syncBaseUrl = syncBaseUrl.replace("ims/oneroster/v1p1", "");
    
    if (syncBaseUrl.trim() !== '') {
        // Construct base URL
        syncBaseUrl = `https://${syncBaseUrl}`;      
        finalUrl = `https://${finalUrl}`;      
    }
    return {syncBaseUrl, finalUrl};
  }

  cleanOneRosterUrl(baseUrl) {
    // Define the patterns to remove from the end of the URL
    const trailingPatterns = [ '/ims', '/oneroster', '/rostering', '/ims/oneroster', '/ims/oneroster/rostering', '/'];

    // const versionPattern = /\/v(?!1p[12])\d+p\d+/i;

    // Remove trailing slashes first to avoid partial matches
    baseUrl = baseUrl.replace(/\/+$/, '');

    // Remove unwanted version patterns
    // if (versionPattern.test(baseUrl)) {
    //   baseUrl = baseUrl.replace(versionPattern, '');
    // }

    // Convert the URL to lowercase for pattern matching, but keep the original for the final output
    let lowerCaseUrl = baseUrl.toLowerCase();

    // Loop through the patterns and remove them if they are found at the end
    trailingPatterns.forEach(pattern => {
        if (lowerCaseUrl.endsWith(pattern.toLowerCase())) {
          baseUrl = baseUrl.slice(0, -pattern.length);  // Use original baseUrl for slicing
          lowerCaseUrl = lowerCaseUrl.slice(0, -pattern.length);
        }
    });

    return baseUrl;
}
  fieldMappingSynonyms: any = {
    'title': ['title'],
    'first name': [
      'firstname', 'first name', 'fname', 'first_name', 'first-name', 
      'given name', 'forename', 'f name', 'first', ...UserService.generateSynonyms(["student"], ["first name", "firstname", "fname"])
    ],
    'middle name': [
      'middlename', 'middle name', 'mname', 'middle_name', 'middle-name', 'middle initial'
    ],
    'last name': [
      'lastname', 'last name', 'lname', 'last_name', 'last-name', 'surname', 
      'family name', 'familyname', 'l name', 'last', ...UserService.generateSynonyms(["student"], ["last name", "lastname", "lname"])
    ],
    'suffix': ['suffix', 'title suffix', 'name suffix'],
    'preferred': ['preferred', 'preferred name', 'nickname'],
    'role': ['role', 'job role'],
    'job title': ['job title', 'jobtitle', 'title', 'position', 'designation'],
    'studentid': [
      "studentid", "id", "student id", "student number", 'sin', 'studentnumber', 'student#', 'student #', 'org id', 'orgid', 'org_id', "staff number",
      "staffnumber", "staffnum", "staff num", 'staff#', 'staff #' ,"studentcode", "student code", "student_number", "online code", "user id", "userid", "sid", "child id"
    ],
    'indid_client': [
      'indid_client', 'client id', 'client identifier', 'client indid', "studioid", "studio id",
      'clientindid', "subjectid", "subject id", 'internal id', "clientid"
    ],
    'grade': ['grade', 'student grade', 'grading', 'performance grade', 'class'],
    'mobile phone': [
      'phone', 'telephone', 'contact number', 'mobile', 'mobileno', 
      'mobile-no', 'cell phone', 'cellphone', 'mobile phone'
    ],
    'email': [
      'email id', 'emailid', 'email', 'e-mail', 'email address', 'student email', 
      'studentemail', 'preferred_email', 'preferred email', 'preferredemail'
    ],
    "guardian 1 first name": UserService.generateSynonyms(["guardian", "parent", "father", "mother"], ["1", ""], ["firstname", "first name", "givenname", "given name"], ["", "(1)"]),
    "guardian 1 last name": UserService.generateSynonyms(["guardian", "parent", "father", "mother"], ["1", ""], ["lastname", "last name", "familyname", "family name"], ["", "(1)"]),
    "guardian 1 email": [
      'guardian 1 email', 'guardian one email', 'parent 1 email', "parentemail", "parent email", "parentemail1", "parent email 1", "parent 1 email", 
      "parentmail", "parent mail", "parentmail1", "parent mail 1", "parent 1 mail", "guardianemail", "guardian email", "guardianemail1", "guardian email 1", 
      "guardian mail", "guardian 1 mail", "guardian mail 1", "email1", "parent email (1)"
    ],
    "guardian 1 phone": [
      'guardian 1 phone', 'guardian one phone', 'parent 1 phone', 'primary phone', "parentphone", "parent phone", 'primary phone 1', "parent 1 phone", 
      "parentphone1", "parent phone 1", "guardian phone", "guardianphone", "guardian phone 1", "guardian 1 phone", "guardian telephone", "guardiantelphone", 
      "guardiantelphone1", "guardian telephone 1", "guardian 1 telephone", "guardian mobile", "guardianmobile", "guardianmobile1", "guardian mobile 1", 
      "guardian 1 mobile", "phone1", "phone 1", "home phone", "parent phone (1)"
    ],
    "guardian 2 first name": UserService.generateSynonyms(["guardian", "parent", "father", "mother"], ["2", ""], ["firstname", "first name", "givenname", "given name"], ["", "(2)"]),
    "guardian 2 last name": UserService.generateSynonyms(["guardian", "parent", "father", "mother"], ["2", ""], ["lastname", "last name", "familyname", "family name"], ["", "(2)"]),
    "guardian 2 email": [
      'guardian 2 email', 'guardian two email', 'parent 2 email', "parentemail2",  "parent email 2", "parent 2 email", "parentmail2", "parent mail 2", 
      "parent 2 mail", "guardianemail2", "guardian email 2", "guardian 2 email", "guardian mail 2", "guardian 2 mail", "email2", "parent email (2)"
    ],
    "guardian 2 phone": [
      'guardian 2 phone', 'guardian two phone', 'parent 2 phone', 'primary phone 2', "parentphone2", "parent phone 2", "parent 2 phone", "guardianphone2", 
      "guardian phone 2", "guardian 2 phone", "guardiantelphone 2", "guardiantelphone2", "guardian 2 telephone", "guardian mobile 2", 
      "guardianmobile2", "guardian 2 mobile", "phone2", "phone 2", "parent phone (2)"
    ],
    "photo": ['photo', 'photoname', 'photofilename', 'photos', 'file', 'filename'],
    "isprimary": ['isprimary', 'primary photo', 'primaryphoto', 'primary', 'isprimaryphoto']
  };

  fieldMappingSynonymsCSVData(csvData) {
    const mappedData = csvData.map((row) => {
      const newRow: any = {};
      Object.keys(this.fieldMappingSynonyms).forEach((mappedKey) => {
        const fieldNames = this.fieldMappingSynonyms[mappedKey];
        const foundKey = Object.keys(row).find((key) => fieldNames.includes(key.toLowerCase()));
        if (foundKey) {
          newRow[mappedKey] = row[foundKey];
        }
      });
      return newRow;
    });
    return mappedData;
  }

  async sendNotification(studentID: string, message: string, type: string): Promise<void> {
    try {
      if (!studentID) {
        throw new Error('Params missing!')
      }
      const notificationObj = {
        message,
        type,
        timestamp: moment().valueOf()
      };
      await this.db.list(`/portal/notifications/${studentID}`).push(notificationObj);
    } catch (error) {
      throw error;
    }
  }  

  async archiveNotification(studentID: string, notificationId: string): Promise<void> {
    try {
      if (!studentID || !notificationId) {
        throw new Error('Params missing!')
      }
      await this.db
        .object(`/portal/notifications/${studentID}/${notificationId}`)
        .update({ isArchived: true });
    } catch (error) {
      throw error;
    }
  }  

  async archiveAllNotifications(studentID: string, notificationKeys: string[]): Promise<void> {
    try {
        if (!studentID || !notificationKeys || notificationKeys.length === 0) {
            throw new Error('Student ID or notification keys are missing or empty!');
        }

        const updates = {};
        notificationKeys.forEach(key => {
            updates[`${studentID}/${key}/isArchived`] = true;
        });

        await this.db.object('/portal/notifications/').update(updates);

    } catch (error) {
        throw error;
    }
  }

  prepareElasticReqBodyNew(obj: any) {
    let requestBody = JSON.stringify(obj);
    let encryptedBody = this.encryptionService.encrypt(requestBody, environment.edk);
    return encryptedBody;
  }
}

export interface H5Trace {
  f: string; // function name
  t: number; // timestamp
  [key: string]: any; // for additional key/value pairs
}

