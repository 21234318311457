import { Injectable } from '@angular/core';
@Injectable()
export class Utils {
  modulesList = [
    {
      id: 'printedCards',
      title: 'Printed Cards',
      value: 69,
      bolvalue: false,
      disable: false,
    },
    {
      id: 'idEssentials',
      title: 'ID Essentials',
      value: 80,
      bolvalue: true,
      disable: true,
    },
    {
      id: 'entranceScreening',
      title: 'Entrance Screening',
      value: 0,
      bolvalue: true,
      disable: true,
    },
    {
      id: 'studentTracking',
      title: 'Student Tracking',
      value: 80,
      bolvalue: false,
    },
    {
      id: 'visitorTracking',
      title: 'Visitor Tracking',
      value: 55,
      bolvalue: false,
    },
    {
      id: 'eventTracking',
      title: 'Event Tracking',
      value: 80,
      bolvalue: false,
    },
    {
      id: 'busTracking',
      title: 'Bus Tracking',
      value: 80,
      bolvalue: false,
    },
  ];
  countryList = [
    {
      name: 'United States',
      code: 'US',
    },
    {
      name: 'Canada',
      code: 'CA',
    },
    // {
    //   name: 'Afghanistan',
    //   code: 'AF',
    // },
    // {
    //   name: 'Åland Islands',
    //   code: 'AX',
    // },
    // {
    //   name: 'Albania',
    //   code: 'AL',
    // },
    // {
    //   name: 'Algeria',
    //   code: 'DZ',
    // },
    // {
    //   name: 'American Samoa',
    //   code: 'AS',
    // },
    // {
    //   name: 'AndorrA',
    //   code: 'AD',
    // },
    // {
    //   name: 'Angola',
    //   code: 'AO',
    // },
    // {
    //   name: 'Anguilla',
    //   code: 'AI',
    // },
    // {
    //   name: 'Antarctica',
    //   code: 'AQ',
    // },
    // {
    //   name: 'Antigua and Barbuda',
    //   code: 'AG',
    // },
    // {
    //   name: 'Argentina',
    //   code: 'AR',
    // },
    // {
    //   name: 'Armenia',
    //   code: 'AM',
    // },
    // {
    //   name: 'Aruba',
    //   code: 'AW',
    // },
    // {
    //   name: 'Australia',
    //   code: 'AU',
    // },
    // {
    //   name: 'Austria',
    //   code: 'AT',
    // },
    // {
    //   name: 'Azerbaijan',
    //   code: 'AZ',
    // },
    // {
    //   name: 'Bahamas',
    //   code: 'BS',
    // },
    // {
    //   name: 'Bahrain',
    //   code: 'BH',
    // },
    // {
    //   name: 'Bangladesh',
    //   code: 'BD',
    // },
    // {
    //   name: 'Barbados',
    //   code: 'BB',
    // },
    // {
    //   name: 'Belarus',
    //   code: 'BY',
    // },
    // {
    //   name: 'Belgium',
    //   code: 'BE',
    // },
    // {
    //   name: 'Belize',
    //   code: 'BZ',
    // },
    // {
    //   name: 'Benin',
    //   code: 'BJ',
    // },
    // {
    //   name: 'Bermuda',
    //   code: 'BM',
    // },
    // {
    //   name: 'Bhutan',
    //   code: 'BT',
    // },
    // {
    //   name: 'Bolivia',
    //   code: 'BO',
    // },
    // {
    //   name: 'Bosnia and Herzegovina',
    //   code: 'BA',
    // },
    // {
    //   name: 'Botswana',
    //   code: 'BW',
    // },
    // {
    //   name: 'Bouvet Island',
    //   code: 'BV',
    // },
    // {
    //   name: 'Brazil',
    //   code: 'BR',
    // },
    // {
    //   name: 'British Indian Ocean Territory',
    //   code: 'IO',
    // },
    // {
    //   name: 'Brunei Darussalam',
    //   code: 'BN',
    // },
    // {
    //   name: 'Bulgaria',
    //   code: 'BG',
    // },
    // {
    //   name: 'Burkina Faso',
    //   code: 'BF',
    // },
    // {
    //   name: 'Burundi',
    //   code: 'BI',
    // },
    // {
    //   name: 'Cambodia',
    //   code: 'KH',
    // },
    // {
    //   name: 'Cameroon',
    //   code: 'CM',
    // },
    // {
    //   name: 'Canada',
    //   code: 'CA',
    // },
    // {
    //   name: 'Cape Verde',
    //   code: 'CV',
    // },
    // {
    //   name: 'Cayman Islands',
    //   code: 'KY',
    // },
    // {
    //   name: 'Central African Republic',
    //   code: 'CF',
    // },
    // {
    //   name: 'Chad',
    //   code: 'TD',
    // },
    // {
    //   name: 'Chile',
    //   code: 'CL',
    // },
    // {
    //   name: 'China',
    //   code: 'CN',
    // },
    // {
    //   name: 'Christmas Island',
    //   code: 'CX',
    // },
    // {
    //   name: 'Cocos (Keeling) Islands',
    //   code: 'CC',
    // },
    // {
    //   name: 'Colombia',
    //   code: 'CO',
    // },
    // {
    //   name: 'Comoros',
    //   code: 'KM',
    // },
    // {
    //   name: 'Congo',
    //   code: 'CG',
    // },
    // {
    //   name: 'Congo, The Democratic Republic of the',
    //   code: 'CD',
    // },
    // {
    //   name: 'Cook Islands',
    //   code: 'CK',
    // },
    // {
    //   name: 'Costa Rica',
    //   code: 'CR',
    // },
    // {
    //   name: 'Cote D"Ivoire',
    //   code: 'CI',
    // },
    // {
    //   name: 'Croatia',
    //   code: 'HR',
    // },
    // {
    //   name: 'Cuba',
    //   code: 'CU',
    // },
    // {
    //   name: 'Cyprus',
    //   code: 'CY',
    // },
    // {
    //   name: 'Czech Republic',
    //   code: 'CZ',
    // },
    // {
    //   name: 'Denmark',
    //   code: 'DK',
    // },
    // {
    //   name: 'Djibouti',
    //   code: 'DJ',
    // },
    // {
    //   name: 'Dominica',
    //   code: 'DM',
    // },
    // {
    //   name: 'Dominican Republic',
    //   code: 'DO',
    // },
    // {
    //   name: 'Ecuador',
    //   code: 'EC',
    // },
    // {
    //   name: 'Egypt',
    //   code: 'EG',
    // },
    // {
    //   name: 'El Salvador',
    //   code: 'SV',
    // },
    // {
    //   name: 'Equatorial Guinea',
    //   code: 'GQ',
    // },
    // {
    //   name: 'Eritrea',
    //   code: 'ER',
    // },
    // {
    //   name: 'Estonia',
    //   code: 'EE',
    // },
    // {
    //   name: 'Ethiopia',
    //   code: 'ET',
    // },
    // {
    //   name: 'Falkland Islands (Malvinas)',
    //   code: 'FK',
    // },
    // {
    //   name: 'Faroe Islands',
    //   code: 'FO',
    // },
    // {
    //   name: 'Fiji',
    //   code: 'FJ',
    // },
    // {
    //   name: 'Finland',
    //   code: 'FI',
    // },
    // {
    //   name: 'France',
    //   code: 'FR',
    // },
    // {
    //   name: 'French Guiana',
    //   code: 'GF',
    // },
    // {
    //   name: 'French Polynesia',
    //   code: 'PF',
    // },
    // {
    //   name: 'French Southern Territories',
    //   code: 'TF',
    // },
    // {
    //   name: 'Gabon',
    //   code: 'GA',
    // },
    // {
    //   name: 'Gambia',
    //   code: 'GM',
    // },
    // {
    //   name: 'Georgia',
    //   code: 'GE',
    // },
    // {
    //   name: 'Germany',
    //   code: 'DE',
    // },
    // {
    //   name: 'Ghana',
    //   code: 'GH',
    // },
    // {
    //   name: 'Gibraltar',
    //   code: 'GI',
    // },
    // {
    //   name: 'Greece',
    //   code: 'GR',
    // },
    // {
    //   name: 'Greenland',
    //   code: 'GL',
    // },
    // {
    //   name: 'Grenada',
    //   code: 'GD',
    // },
    // {
    //   name: 'Guadeloupe',
    //   code: 'GP',
    // },
    // {
    //   name: 'Guam',
    //   code: 'GU',
    // },
    // {
    //   name: 'Guatemala',
    //   code: 'GT',
    // },
    // {
    //   name: 'Guernsey',
    //   code: 'GG',
    // },
    // {
    //   name: 'Guinea',
    //   code: 'GN',
    // },
    // {
    //   name: 'Guinea-Bissau',
    //   code: 'GW',
    // },
    // {
    //   name: 'Guyana',
    //   code: 'GY',
    // },
    // {
    //   name: 'Haiti',
    //   code: 'HT',
    // },
    // {
    //   name: 'Heard Island and Mcdonald Islands',
    //   code: 'HM',
    // },
    // {
    //   name: 'Holy See (Vatican City State)',
    //   code: 'VA',
    // },
    // {
    //   name: 'Honduras',
    //   code: 'HN',
    // },
    // {
    //   name: 'Hong Kong',
    //   code: 'HK',
    // },
    // {
    //   name: 'Hungary',
    //   code: 'HU',
    // },
    // {
    //   name: 'Iceland',
    //   code: 'IS',
    // },
    // {
    //   name: 'India',
    //   code: 'IN',
    // },
    // {
    //   name: 'Indonesia',
    //   code: 'ID',
    // },
    // {
    //   name: 'Iran, Islamic Republic Of',
    //   code: 'IR',
    // },
    // {
    //   name: 'Iraq',
    //   code: 'IQ',
    // },
    // {
    //   name: 'Ireland',
    //   code: 'IE',
    // },
    // {
    //   name: 'Isle of Man',
    //   code: 'IM',
    // },
    // {
    //   name: 'Israel',
    //   code: 'IL',
    // },
    // {
    //   name: 'Italy',
    //   code: 'IT',
    // },
    // {
    //   name: 'Jamaica',
    //   code: 'JM',
    // },
    // {
    //   name: 'Japan',
    //   code: 'JP',
    // },
    // {
    //   name: 'Jersey',
    //   code: 'JE',
    // },
    // {
    //   name: 'Jordan',
    //   code: 'JO',
    // },
    // {
    //   name: 'Kazakhstan',
    //   code: 'KZ',
    // },
    // {
    //   name: 'Kenya',
    //   code: 'KE',
    // },
    // {
    //   name: 'Kiribati',
    //   code: 'KI',
    // },
    // {
    //   name: 'Korea, Democratic People"S Republic of',
    //   code: 'KP',
    // },
    // {
    //   name: 'Korea, Republic of',
    //   code: 'KR',
    // },
    // {
    //   name: 'Kuwait',
    //   code: 'KW',
    // },
    // {
    //   name: 'Kyrgyzstan',
    //   code: 'KG',
    // },
    // {
    //   name: 'Lao People"S Democratic Republic',
    //   code: 'LA',
    // },
    // {
    //   name: 'Latvia',
    //   code: 'LV',
    // },
    // {
    //   name: 'Lebanon',
    //   code: 'LB',
    // },
    // {
    //   name: 'Lesotho',
    //   code: 'LS',
    // },
    // {
    //   name: 'Liberia',
    //   code: 'LR',
    // },
    // {
    //   name: 'Libyan Arab Jamahiriya',
    //   code: 'LY',
    // },
    // {
    //   name: 'Liechtenstein',
    //   code: 'LI',
    // },
    // {
    //   name: 'Lithuania',
    //   code: 'LT',
    // },
    // {
    //   name: 'Luxembourg',
    //   code: 'LU',
    // },
    // {
    //   name: 'Macao',
    //   code: 'MO',
    // },
    // {
    //   name: 'Macedonia, The Former Yugoslav Republic of',
    //   code: 'MK',
    // },
    // {
    //   name: 'Madagascar',
    //   code: 'MG',
    // },
    // {
    //   name: 'Malawi',
    //   code: 'MW',
    // },
    // {
    //   name: 'Malaysia',
    //   code: 'MY',
    // },
    // {
    //   name: 'Maldives',
    //   code: 'MV',
    // },
    // {
    //   name: 'Mali',
    //   code: 'ML',
    // },
    // {
    //   name: 'Malta',
    //   code: 'MT',
    // },
    // {
    //   name: 'Marshall Islands',
    //   code: 'MH',
    // },
    // {
    //   name: 'Martinique',
    //   code: 'MQ',
    // },
    // {
    //   name: 'Mauritania',
    //   code: 'MR',
    // },
    // {
    //   name: 'Mauritius',
    //   code: 'MU',
    // },
    // {
    //   name: 'Mayotte',
    //   code: 'YT',
    // },
    // {
    //   name: 'Mexico',
    //   code: 'MX',
    // },
    // {
    //   name: 'Micronesia, Federated States of',
    //   code: 'FM',
    // },
    // {
    //   name: 'Moldova, Republic of',
    //   code: 'MD',
    // },
    // {
    //   name: 'Monaco',
    //   code: 'MC',
    // },
    // {
    //   name: 'Mongolia',
    //   code: 'MN',
    // },
    // {
    //   name: 'Montserrat',
    //   code: 'MS',
    // },
    // {
    //   name: 'Morocco',
    //   code: 'MA',
    // },
    // {
    //   name: 'Mozambique',
    //   code: 'MZ',
    // },
    // {
    //   name: 'Myanmar',
    //   code: 'MM',
    // },
    // {
    //   name: 'Namibia',
    //   code: 'NA',
    // },
    // {
    //   name: 'Nauru',
    //   code: 'NR',
    // },
    // {
    //   name: 'Nepal',
    //   code: 'NP',
    // },
    // {
    //   name: 'Netherlands',
    //   code: 'NL',
    // },
    // {
    //   name: 'Netherlands Antilles',
    //   code: 'AN',
    // },
    // {
    //   name: 'New Caledonia',
    //   code: 'NC',
    // },
    // {
    //   name: 'New Zealand',
    //   code: 'NZ',
    // },
    // {
    //   name: 'Nicaragua',
    //   code: 'NI',
    // },
    // {
    //   name: 'Niger',
    //   code: 'NE',
    // },
    // {
    //   name: 'Nigeria',
    //   code: 'NG',
    // },
    // {
    //   name: 'Niue',
    //   code: 'NU',
    // },
    // {
    //   name: 'Norfolk Island',
    //   code: 'NF',
    // },
    // {
    //   name: 'Northern Mariana Islands',
    //   code: 'MP',
    // },
    // {
    //   name: 'Norway',
    //   code: 'NO',
    // },
    // {
    //   name: 'Oman',
    //   code: 'OM',
    // },
    // {
    //   name: 'Pakistan',
    //   code: 'PK',
    // },
    // {
    //   name: 'Palau',
    //   code: 'PW',
    // },
    // {
    //   name: 'Palestinian Territory, Occupied',
    //   code: 'PS',
    // },
    // {
    //   name: 'Panama',
    //   code: 'PA',
    // },
    // {
    //   name: 'Papua New Guinea',
    //   code: 'PG',
    // },
    // {
    //   name: 'Paraguay',
    //   code: 'PY',
    // },
    // {
    //   name: 'Peru',
    //   code: 'PE',
    // },
    // {
    //   name: 'Philippines',
    //   code: 'PH',
    // },
    // {
    //   name: 'Pitcairn',
    //   code: 'PN',
    // },
    // {
    //   name: 'Poland',
    //   code: 'PL',
    // },
    // {
    //   name: 'Portugal',
    //   code: 'PT',
    // },
    // {
    //   name: 'Puerto Rico',
    //   code: 'PR',
    // },
    // {
    //   name: 'Qatar',
    //   code: 'QA',
    // },
    // {
    //   name: 'Reunion',
    //   code: 'RE',
    // },
    // {
    //   name: 'Romania',
    //   code: 'RO',
    // },
    // {
    //   name: 'Russian Federation',
    //   code: 'RU',
    // },
    // {
    //   name: 'RWANDA',
    //   code: 'RW',
    // },
    // {
    //   name: 'Saint Helena',
    //   code: 'SH',
    // },
    // {
    //   name: 'Saint Kitts and Nevis',
    //   code: 'KN',
    // },
    // {
    //   name: 'Saint Lucia',
    //   code: 'LC',
    // },
    // {
    //   name: 'Saint Pierre and Miquelon',
    //   code: 'PM',
    // },
    // {
    //   name: 'Saint Vincent and the Grenadines',
    //   code: 'VC',
    // },
    // {
    //   name: 'Samoa',
    //   code: 'WS',
    // },
    // {
    //   name: 'San Marino',
    //   code: 'SM',
    // },
    // {
    //   name: 'Sao Tome and Principe',
    //   code: 'ST',
    // },
    // {
    //   name: 'Saudi Arabia',
    //   code: 'SA',
    // },
    // {
    //   name: 'Senegal',
    //   code: 'SN',
    // },
    // {
    //   name: 'Serbia',
    //   code: 'RS',
    // },
    // {
    //   name: 'Montenegro',
    //   code: 'ME',
    // },
    // {
    //   name: 'Seychelles',
    //   code: 'SC',
    // },
    // {
    //   name: 'Sierra Leone',
    //   code: 'SL',
    // },
    // {
    //   name: 'Singapore',
    //   code: 'SG',
    // },
    // {
    //   name: 'Slovakia',
    //   code: 'SK',
    // },
    // {
    //   name: 'Slovenia',
    //   code: 'SI',
    // },
    // {
    //   name: 'Solomon Islands',
    //   code: 'SB',
    // },
    // {
    //   name: 'Somalia',
    //   code: 'SO',
    // },
    // {
    //   name: 'South Africa',
    //   code: 'ZA',
    // },
    // {
    //   name: 'South Georgia and the South Sandwich Islands',
    //   code: 'GS',
    // },
    // {
    //   name: 'Spain',
    //   code: 'ES',
    // },
    // {
    //   name: 'Sri Lanka',
    //   code: 'LK',
    // },
    // {
    //   name: 'Sudan',
    //   code: 'SD',
    // },
    // {
    //   name: 'Suriname',
    //   code: 'SR',
    // },
    // {
    //   name: 'Svalbard and Jan Mayen',
    //   code: 'SJ',
    // },
    // {
    //   name: 'Swaziland',
    //   code: 'SZ',
    // },
    // {
    //   name: 'Sweden',
    //   code: 'SE',
    // },
    // {
    //   name: 'Switzerland',
    //   code: 'CH',
    // },
    // {
    //   name: 'Syrian Arab Republic',
    //   code: 'SY',
    // },
    // {
    //   name: 'Taiwan, Province of China',
    //   code: 'TW',
    // },
    // {
    //   name: 'Tajikistan',
    //   code: 'TJ',
    // },
    // {
    //   name: 'Tanzania, United Republic of',
    //   code: 'TZ',
    // },
    // {
    //   name: 'Thailand',
    //   code: 'TH',
    // },
    // {
    //   name: 'Timor-Leste',
    //   code: 'TL',
    // },
    // {
    //   name: 'Togo',
    //   code: 'TG',
    // },
    // {
    //   name: 'Tokelau',
    //   code: 'TK',
    // },
    // {
    //   name: 'Tonga',
    //   code: 'TO',
    // },
    // {
    //   name: 'Trinidad and Tobago',
    //   code: 'TT',
    // },
    // {
    //   name: 'Tunisia',
    //   code: 'TN',
    // },
    // {
    //   name: 'Turkey',
    //   code: 'TR',
    // },
    // {
    //   name: 'Turkmenistan',
    //   code: 'TM',
    // },
    // {
    //   name: 'Turks and Caicos Islands',
    //   code: 'TC',
    // },
    // {
    //   name: 'Tuvalu',
    //   code: 'TV',
    // },
    // {
    //   name: 'Uganda',
    //   code: 'UG',
    // },
    // {
    //   name: 'Ukraine',
    //   code: 'UA',
    // },
    // {
    //   name: 'United Arab Emirates',
    //   code: 'AE',
    // },
    // {
    //   name: 'United Kingdom',
    //   code: 'GB',
    // },
    // {
    //   name: 'United States Minor Outlying Islands',
    //   code: 'UM',
    // },
    // {
    //   name: 'Uruguay',
    //   code: 'UY',
    // },
    // {
    //   name: 'Uzbekistan',
    //   code: 'UZ',
    // },
    // {
    //   name: 'Vanuatu',
    //   code: 'VU',
    // },
    // {
    //   name: 'Venezuela',
    //   code: 'VE',
    // },
    // {
    //   name: 'Viet Nam',
    //   code: 'VN',
    // },
    // {
    //   name: 'Virgin Islands, British',
    //   code: 'VG',
    // },
    // {
    //   name: 'Virgin Islands, U.S.',
    //   code: 'VI',
    // },
    // {
    //   name: 'Wallis and Futuna',
    //   code: 'WF',
    // },
    // {
    //   name: 'Western Sahara',
    //   code: 'EH',
    // },
    // {
    //   name: 'Yemen',
    //   code: 'YE',
    // },
    // {
    //   name: 'Zambia',
    //   code: 'ZM',
    // },
    // {
    //   name: 'Zimbabwe',
    //   code: 'ZW',
    // },
  ];
  usStateList = [
    { name: 'Alabama', 'alpha-2': 'AL' },
    { name: 'Alaska', 'alpha-2': 'AK' },
    { name: 'Arizona', 'alpha-2': 'AZ' },
    { name: 'Arkansas', 'alpha-2': 'AR' },
    { name: 'California', 'alpha-2': 'CA' },
    { name: 'Colorado', 'alpha-2': 'CO' },
    { name: 'Connecticut', 'alpha-2': 'CT' },
    { name: 'Delaware', 'alpha-2': 'DE' },
    { name: 'District of Columbia', 'alpha-2': 'DC' },
    { name: 'Florida', 'alpha-2': 'FL' },
    { name: 'Georgia', 'alpha-2': 'GA' },
    { name: 'Hawaii', 'alpha-2': 'HI' },
    { name: 'Idaho', 'alpha-2': 'ID' },
    { name: 'Illinois', 'alpha-2': 'IL' },
    { name: 'Indiana', 'alpha-2': 'IN' },
    { name: 'Iowa', 'alpha-2': 'IA' },
    { name: 'Kansas', 'alpha-2': 'KS' },
    { name: 'Kentucky', 'alpha-2': 'KY' },
    { name: 'Lousiana', 'alpha-2': 'LA' },
    { name: 'Maine', 'alpha-2': 'ME' },
    { name: 'Maryland', 'alpha-2': 'MD' },
    { name: 'Massachusetts', 'alpha-2': 'MA' },
    { name: 'Michigan', 'alpha-2': 'MI' },
    { name: 'Minnesota', 'alpha-2': 'MN' },
    { name: 'Mississippi', 'alpha-2': 'MS' },
    { name: 'Missouri', 'alpha-2': 'MO' },
    { name: 'Montana', 'alpha-2': 'MT' },
    { name: 'Nebraska', 'alpha-2': 'NE' },
    { name: 'Nevada', 'alpha-2': 'NV' },
    { name: 'New Hampshire', 'alpha-2': 'NH' },
    { name: 'New Jersey', 'alpha-2': 'NJ' },
    { name: 'New Mexico', 'alpha-2': 'NM' },
    { name: 'New York', 'alpha-2': 'NY' },
    { name: 'North Carolina', 'alpha-2': 'NC' },
    { name: 'North Dakota', 'alpha-2': 'ND' },
    { name: 'Ohio', 'alpha-2': 'OH' },
    { name: 'Oklahoma', 'alpha-2': 'OK' },
    { name: 'Oregon', 'alpha-2': 'OR' },
    { name: 'Pennsylvania', 'alpha-2': 'PA' },
    { name: 'Rhode Island', 'alpha-2': 'RI' },
    { name: 'South Carolina', 'alpha-2': 'SC' },
    { name: 'South Dakota', 'alpha-2': 'SD' },
    { name: 'Tennessee', 'alpha-2': 'TN' },
    { name: 'Texas', 'alpha-2': 'TX' },
    { name: 'Utah', 'alpha-2': 'UT' },
    { name: 'Vermont', 'alpha-2': 'VT' },
    { name: 'Virginia', 'alpha-2': 'VA' },
    { name: 'Washington', 'alpha-2': 'WA' },
    { name: 'West Virginia', 'alpha-2': 'WV' },
    { name: 'Wisconsin', 'alpha-2': 'WI' },
    { name: 'Wyoming', 'alpha-2': 'WY' },
  ];
  timezoneList = [
    {
      value: 'Dateline Standard Time',
      abbr: 'DST',
      offset: -12,
      isdst: false,
      text: '(UTC-12:00) International Date Line West',
      utc: ['Etc/GMT+12'],
    },
    {
      value: 'UTC-11',
      abbr: 'U',
      offset: -11,
      isdst: false,
      text: '(UTC-11:00) Coordinated Universal Time-11',
      utc: [
        'Etc/GMT+11',
        'Pacific/Midway',
        'Pacific/Niue',
        'Pacific/Pago_Pago',
      ],
    },
    {
      value: 'Hawaiian Standard Time',
      abbr: 'HST',
      offset: -10,
      isdst: false,
      text: '(UTC-10:00) Hawaii',
      utc: [
        'Etc/GMT+10',
        'Pacific/Honolulu',
        'Pacific/Johnston',
        'Pacific/Rarotonga',
        'Pacific/Tahiti',
      ],
    },
    {
      value: 'Alaskan Standard Time',
      abbr: 'AKDT',
      offset: -8,
      isdst: true,
      text: '(UTC-09:00) Alaska',
      utc: [
        'America/Anchorage',
        'America/Juneau',
        'America/Nome',
        'America/Sitka',
        'America/Yakutat',
      ],
    },
    {
      value: 'Pacific Standard Time (Mexico)',
      abbr: 'PDT',
      offset: -7,
      isdst: true,
      text: '(UTC-08:00) Baja California',
      utc: ['America/Santa_Isabel'],
    },
    {
      value: 'Pacific Standard Time',
      abbr: 'PST',
      offset: -8,
      isdst: false,
      text: '(UTC-08:00) Pacific Time (US & Canada)',
      utc: [
        'America/Los_Angeles',
        'America/Tijuana',
        'America/Vancouver',
        'America/Whitehorse',
        'PST8PDT',
      ],
    },
    {
      value: 'US Mountain Standard Time',
      abbr: 'UMST',
      offset: -7,
      isdst: false,
      text: '(UTC-07:00) Arizona',
      utc: [
        'America/Creston',
        'America/Dawson_Creek',
        'America/Hermosillo',
        'America/Phoenix',
        'Etc/GMT+7',
      ],
    },
    {
      value: 'Mountain Standard Time (Mexico)',
      abbr: 'MDT',
      offset: -6,
      isdst: true,
      text: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
      utc: ['America/Chihuahua', 'America/Mazatlan'],
    },
    {
      value: 'Mountain Standard Time',
      abbr: 'MDT',
      offset: -6,
      isdst: true,
      text: '(UTC-07:00) Mountain Time (US & Canada)',
      utc: [
        'America/Boise',
        'America/Cambridge_Bay',
        'America/Denver',
        'America/Edmonton',
        'America/Inuvik',
        'America/Ojinaga',
        'America/Yellowknife',
        'MST7MDT',
      ],
    },
    {
      value: 'Central America Standard Time',
      abbr: 'CAST',
      offset: -6,
      isdst: false,
      text: '(UTC-06:00) Central America',
      utc: [
        'America/Belize',
        'America/Costa_Rica',
        'America/El_Salvador',
        'America/Guatemala',
        'America/Managua',
        'America/Tegucigalpa',
        'Etc/GMT+6',
        'Pacific/Galapagos',
      ],
    },
    {
      value: 'Central Standard Time',
      abbr: 'CDT',
      offset: -5,
      isdst: true,
      text: '(UTC-06:00) Central Time (US & Canada)',
      utc: [
        'America/Chicago',
        'America/Indiana/Knox',
        'America/Indiana/Tell_City',
        'America/Matamoros',
        'America/Menominee',
        'America/North_Dakota/Beulah',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/Rainy_River',
        'America/Rankin_Inlet',
        'America/Resolute',
        'America/Winnipeg',
        'CST6CDT',
      ],
    },
    {
      value: 'Central Standard Time (Mexico)',
      abbr: 'CDT',
      offset: -5,
      isdst: true,
      text: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
      utc: [
        'America/Bahia_Banderas',
        'America/Cancun',
        'America/Merida',
        'America/Mexico_City',
        'America/Monterrey',
      ],
    },
    {
      value: 'Canada Central Standard Time',
      abbr: 'CCST',
      offset: -6,
      isdst: false,
      text: '(UTC-06:00) Saskatchewan',
      utc: ['America/Regina', 'America/Swift_Current'],
    },
    {
      value: 'SA Pacific Standard Time',
      abbr: 'SPST',
      offset: -5,
      isdst: false,
      text: '(UTC-05:00) Bogota, Lima, Quito',
      utc: [
        'America/Bogota',
        'America/Cayman',
        'America/Coral_Harbour',
        'America/Eirunepe',
        'America/Guayaquil',
        'America/Jamaica',
        'America/Lima',
        'America/Panama',
        'America/Rio_Branco',
        'Etc/GMT+5',
      ],
    },
    {
      value: 'Eastern Daylight Time',
      abbr: 'EDT',
      offset: -4,
      isdst: true,
      text: '(UTC-04:00) Eastern Daylight Time (US & Canada)',
      utc: [
        'America/Detroit',
        'America/Havana',
        'America/Indiana/Petersburg',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Iqaluit',
        'America/Kentucky/Monticello',
        'America/Louisville',
        'America/Montreal',
        'America/Nassau',
        'America/New_York',
        'America/Nipigon',
        'America/Pangnirtung',
        'America/Port-au-Prince',
        'America/Thunder_Bay',
        'America/Toronto',
        'EST5EDT',
      ],
    },
    {
      value: 'Eastern Standard Time',
      abbr: 'EST',
      offset: -5,
      isdst: false,
      text: '(UTC-05:00) Eastern Time (US & Canada)',
      utc: [
        'America/Detroit',
        'America/Havana',
        'America/Indiana/Petersburg',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Iqaluit',
        'America/Kentucky/Monticello',
        'America/Louisville',
        'America/Montreal',
        'America/Nassau',
        'America/New_York',
        'America/Nipigon',
        'America/Pangnirtung',
        'America/Port-au-Prince',
        'America/Thunder_Bay',
        'America/Toronto',
      ],
    },
    {
      value: 'US Eastern Standard Time',
      abbr: 'UEDT',
      offset: -4,
      isdst: true,
      text: '(UTC-05:00) Indiana (East)',
      utc: [
        'America/Indiana/Marengo',
        'America/Indiana/Vevay',
        'America/Indianapolis',
      ],
    },
    {
      value: 'Venezuela Standard Time',
      abbr: 'VST',
      offset: -4.5,
      isdst: false,
      text: '(UTC-04:30) Caracas',
      utc: ['America/Caracas'],
    },
    {
      value: 'Paraguay Standard Time',
      abbr: 'PYT',
      offset: -4,
      isdst: false,
      text: '(UTC-04:00) Asuncion',
      utc: ['America/Asuncion'],
    },
    {
      value: 'Atlantic Standard Time',
      abbr: 'ADT',
      offset: -3,
      isdst: true,
      text: '(UTC-04:00) Atlantic Time (Canada)',
      utc: [
        'America/Glace_Bay',
        'America/Goose_Bay',
        'America/Halifax',
        'America/Moncton',
        'America/Thule',
        'Atlantic/Bermuda',
      ],
    },
    {
      value: 'Central Brazilian Standard Time',
      abbr: 'CBST',
      offset: -4,
      isdst: false,
      text: '(UTC-04:00) Cuiaba',
      utc: ['America/Campo_Grande', 'America/Cuiaba'],
    },
    {
      value: 'SA Western Standard Time',
      abbr: 'SWST',
      offset: -4,
      isdst: false,
      text: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
      utc: [
        'America/Anguilla',
        'America/Antigua',
        'America/Aruba',
        'America/Barbados',
        'America/Blanc-Sablon',
        'America/Boa_Vista',
        'America/Curacao',
        'America/Dominica',
        'America/Grand_Turk',
        'America/Grenada',
        'America/Guadeloupe',
        'America/Guyana',
        'America/Kralendijk',
        'America/La_Paz',
        'America/Lower_Princes',
        'America/Manaus',
        'America/Marigot',
        'America/Martinique',
        'America/Montserrat',
        'America/Port_of_Spain',
        'America/Porto_Velho',
        'America/Puerto_Rico',
        'America/Santo_Domingo',
        'America/St_Barthelemy',
        'America/St_Kitts',
        'America/St_Lucia',
        'America/St_Thomas',
        'America/St_Vincent',
        'America/Tortola',
        'Etc/GMT+4',
      ],
    },
    {
      value: 'Pacific SA Standard Time',
      abbr: 'PSST',
      offset: -4,
      isdst: false,
      text: '(UTC-04:00) Santiago',
      utc: ['America/Santiago', 'Antarctica/Palmer'],
    },
    {
      value: 'Newfoundland Standard Time',
      abbr: 'NDT',
      offset: -2.5,
      isdst: true,
      text: '(UTC-03:30) Newfoundland',
      utc: ['America/St_Johns'],
    },
    {
      value: 'E. South America Standard Time',
      abbr: 'ESAST',
      offset: -3,
      isdst: false,
      text: '(UTC-03:00) Brasilia',
      utc: ['America/Sao_Paulo'],
    },
    {
      value: 'Argentina Standard Time',
      abbr: 'AST',
      offset: -3,
      isdst: false,
      text: '(UTC-03:00) Buenos Aires',
      utc: [
        'America/Argentina/La_Rioja',
        'America/Argentina/Rio_Gallegos',
        'America/Argentina/Salta',
        'America/Argentina/San_Juan',
        'America/Argentina/San_Luis',
        'America/Argentina/Tucuman',
        'America/Argentina/Ushuaia',
        'America/Buenos_Aires',
        'America/Catamarca',
        'America/Cordoba',
        'America/Jujuy',
        'America/Mendoza',
      ],
    },
    {
      value: 'SA Eastern Standard Time',
      abbr: 'SEST',
      offset: -3,
      isdst: false,
      text: '(UTC-03:00) Cayenne, Fortaleza',
      utc: [
        'America/Araguaina',
        'America/Belem',
        'America/Cayenne',
        'America/Fortaleza',
        'America/Maceio',
        'America/Paramaribo',
        'America/Recife',
        'America/Santarem',
        'Antarctica/Rothera',
        'Atlantic/Stanley',
        'Etc/GMT+3',
      ],
    },
    {
      value: 'Greenland Standard Time',
      abbr: 'GDT',
      offset: -3,
      isdst: true,
      text: '(UTC-03:00) Greenland',
      utc: ['America/Godthab'],
    },
    {
      value: 'Montevideo Standard Time',
      abbr: 'MST',
      offset: -3,
      isdst: false,
      text: '(UTC-03:00) Montevideo',
      utc: ['America/Montevideo'],
    },
    {
      value: 'Bahia Standard Time',
      abbr: 'BST',
      offset: -3,
      isdst: false,
      text: '(UTC-03:00) Salvador',
      utc: ['America/Bahia'],
    },
    {
      value: 'UTC-02',
      abbr: 'U',
      offset: -2,
      isdst: false,
      text: '(UTC-02:00) Coordinated Universal Time-02',
      utc: ['America/Noronha', 'Atlantic/South_Georgia', 'Etc/GMT+2'],
    },
    {
      value: 'Mid-Atlantic Standard Time',
      abbr: 'MDT',
      offset: -1,
      isdst: true,
      text: '(UTC-02:00) Mid-Atlantic - Old',
      utc: [],
    },
    {
      value: 'Azores Standard Time',
      abbr: 'ADT',
      offset: 0,
      isdst: true,
      text: '(UTC-01:00) Azores',
      utc: ['America/Scoresbysund', 'Atlantic/Azores'],
    },
    {
      value: 'Cape Verde Standard Time',
      abbr: 'CVST',
      offset: -1,
      isdst: false,
      text: '(UTC-01:00) Cape Verde Is.',
      utc: ['Atlantic/Cape_Verde', 'Etc/GMT+1'],
    },
    {
      value: 'Morocco Standard Time',
      abbr: 'MDT',
      offset: 1,
      isdst: true,
      text: '(UTC) Casablanca',
      utc: ['Africa/Casablanca', 'Africa/El_Aaiun'],
    },
    {
      value: 'UTC',
      abbr: 'UTC',
      offset: 0,
      isdst: false,
      text: '(UTC) Coordinated Universal Time',
      utc: ['America/Danmarkshavn', 'Etc/GMT'],
    },
    {
      value: 'GMT Standard Time',
      abbr: 'GMT',
      offset: 0,
      isdst: false,
      text: '(UTC) Edinburgh, London',
      utc: [
        'Europe/Isle_of_Man',
        'Europe/Guernsey',
        'Europe/Jersey',
        'Europe/London',
      ],
    },
    {
      value: 'British Summer Time',
      abbr: 'BST',
      offset: 1,
      isdst: true,
      text: '(UTC+01:00) Edinburgh, London',
      utc: [
        'Europe/Isle_of_Man',
        'Europe/Guernsey',
        'Europe/Jersey',
        'Europe/London',
      ],
    },
    {
      value: 'GMT Standard Time',
      abbr: 'GDT',
      offset: 1,
      isdst: true,
      text: '(UTC) Dublin, Lisbon',
      utc: [
        'Atlantic/Canary',
        'Atlantic/Faeroe',
        'Atlantic/Madeira',
        'Europe/Dublin',
        'Europe/Lisbon',
      ],
    },
    {
      value: 'Greenwich Standard Time',
      abbr: 'GST',
      offset: 0,
      isdst: false,
      text: '(UTC) Monrovia, Reykjavik',
      utc: [
        'Africa/Abidjan',
        'Africa/Accra',
        'Africa/Bamako',
        'Africa/Banjul',
        'Africa/Bissau',
        'Africa/Conakry',
        'Africa/Dakar',
        'Africa/Freetown',
        'Africa/Lome',
        'Africa/Monrovia',
        'Africa/Nouakchott',
        'Africa/Ouagadougou',
        'Africa/Sao_Tome',
        'Atlantic/Reykjavik',
        'Atlantic/St_Helena',
      ],
    },
    {
      value: 'W. Europe Standard Time',
      abbr: 'WEDT',
      offset: 2,
      isdst: true,
      text: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
      utc: [
        'Arctic/Longyearbyen',
        'Europe/Amsterdam',
        'Europe/Andorra',
        'Europe/Berlin',
        'Europe/Busingen',
        'Europe/Gibraltar',
        'Europe/Luxembourg',
        'Europe/Malta',
        'Europe/Monaco',
        'Europe/Oslo',
        'Europe/Rome',
        'Europe/San_Marino',
        'Europe/Stockholm',
        'Europe/Vaduz',
        'Europe/Vatican',
        'Europe/Vienna',
        'Europe/Zurich',
      ],
    },
    {
      value: 'Central Europe Standard Time',
      abbr: 'CEDT',
      offset: 2,
      isdst: true,
      text: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
      utc: [
        'Europe/Belgrade',
        'Europe/Bratislava',
        'Europe/Budapest',
        'Europe/Ljubljana',
        'Europe/Podgorica',
        'Europe/Prague',
        'Europe/Tirane',
      ],
    },
    {
      value: 'Romance Standard Time',
      abbr: 'RDT',
      offset: 2,
      isdst: true,
      text: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
      utc: [
        'Africa/Ceuta',
        'Europe/Brussels',
        'Europe/Copenhagen',
        'Europe/Madrid',
        'Europe/Paris',
      ],
    },
    {
      value: 'Central European Standard Time',
      abbr: 'CEDT',
      offset: 2,
      isdst: true,
      text: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
      utc: [
        'Europe/Sarajevo',
        'Europe/Skopje',
        'Europe/Warsaw',
        'Europe/Zagreb',
      ],
    },
    {
      value: 'W. Central Africa Standard Time',
      abbr: 'WCAST',
      offset: 1,
      isdst: false,
      text: '(UTC+01:00) West Central Africa',
      utc: [
        'Africa/Algiers',
        'Africa/Bangui',
        'Africa/Brazzaville',
        'Africa/Douala',
        'Africa/Kinshasa',
        'Africa/Lagos',
        'Africa/Libreville',
        'Africa/Luanda',
        'Africa/Malabo',
        'Africa/Ndjamena',
        'Africa/Niamey',
        'Africa/Porto-Novo',
        'Africa/Tunis',
        'Etc/GMT-1',
      ],
    },
    {
      value: 'Namibia Standard Time',
      abbr: 'NST',
      offset: 1,
      isdst: false,
      text: '(UTC+01:00) Windhoek',
      utc: ['Africa/Windhoek'],
    },
    {
      value: 'GTB Standard Time',
      abbr: 'GDT',
      offset: 3,
      isdst: true,
      text: '(UTC+02:00) Athens, Bucharest',
      utc: [
        'Asia/Nicosia',
        'Europe/Athens',
        'Europe/Bucharest',
        'Europe/Chisinau',
      ],
    },
    {
      value: 'Middle East Standard Time',
      abbr: 'MEDT',
      offset: 3,
      isdst: true,
      text: '(UTC+02:00) Beirut',
      utc: ['Asia/Beirut'],
    },
    {
      value: 'Egypt Standard Time',
      abbr: 'EST',
      offset: 2,
      isdst: false,
      text: '(UTC+02:00) Cairo',
      utc: ['Africa/Cairo'],
    },
    {
      value: 'Syria Standard Time',
      abbr: 'SDT',
      offset: 3,
      isdst: true,
      text: '(UTC+02:00) Damascus',
      utc: ['Asia/Damascus'],
    },
    {
      value: 'E. Europe Standard Time',
      abbr: 'EEDT',
      offset: 3,
      isdst: true,
      text: '(UTC+02:00) E. Europe',
      utc: [
        'Asia/Nicosia',
        'Europe/Athens',
        'Europe/Bucharest',
        'Europe/Chisinau',
        'Europe/Helsinki',
        'Europe/Kiev',
        'Europe/Mariehamn',
        'Europe/Nicosia',
        'Europe/Riga',
        'Europe/Sofia',
        'Europe/Tallinn',
        'Europe/Uzhgorod',
        'Europe/Vilnius',
        'Europe/Zaporozhye',
      ],
    },
    {
      value: 'South Africa Standard Time',
      abbr: 'SAST',
      offset: 2,
      isdst: false,
      text: '(UTC+02:00) Harare, Pretoria',
      utc: [
        'Africa/Blantyre',
        'Africa/Bujumbura',
        'Africa/Gaborone',
        'Africa/Harare',
        'Africa/Johannesburg',
        'Africa/Kigali',
        'Africa/Lubumbashi',
        'Africa/Lusaka',
        'Africa/Maputo',
        'Africa/Maseru',
        'Africa/Mbabane',
        'Etc/GMT-2',
      ],
    },
    {
      value: 'FLE Standard Time',
      abbr: 'FDT',
      offset: 3,
      isdst: true,
      text: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
      utc: [
        'Europe/Helsinki',
        'Europe/Kiev',
        'Europe/Mariehamn',
        'Europe/Riga',
        'Europe/Sofia',
        'Europe/Tallinn',
        'Europe/Uzhgorod',
        'Europe/Vilnius',
        'Europe/Zaporozhye',
      ],
    },
    {
      value: 'Turkey Standard Time',
      abbr: 'TDT',
      offset: 3,
      isdst: false,
      text: '(UTC+03:00) Istanbul',
      utc: ['Europe/Istanbul'],
    },
    {
      value: 'Israel Standard Time',
      abbr: 'JDT',
      offset: 3,
      isdst: true,
      text: '(UTC+02:00) Jerusalem',
      utc: ['Asia/Jerusalem'],
    },
    {
      value: 'Libya Standard Time',
      abbr: 'LST',
      offset: 2,
      isdst: false,
      text: '(UTC+02:00) Tripoli',
      utc: ['Africa/Tripoli'],
    },
    {
      value: 'Jordan Standard Time',
      abbr: 'JST',
      offset: 3,
      isdst: false,
      text: '(UTC+03:00) Amman',
      utc: ['Asia/Amman'],
    },
    {
      value: 'Arabic Standard Time',
      abbr: 'AST',
      offset: 3,
      isdst: false,
      text: '(UTC+03:00) Baghdad',
      utc: ['Asia/Baghdad'],
    },
    {
      value: 'Kaliningrad Standard Time',
      abbr: 'KST',
      offset: 3,
      isdst: false,
      text: '(UTC+02:00) Kaliningrad',
      utc: ['Europe/Kaliningrad'],
    },
    {
      value: 'Arab Standard Time',
      abbr: 'AST',
      offset: 3,
      isdst: false,
      text: '(UTC+03:00) Kuwait, Riyadh',
      utc: [
        'Asia/Aden',
        'Asia/Bahrain',
        'Asia/Kuwait',
        'Asia/Qatar',
        'Asia/Riyadh',
      ],
    },
    {
      value: 'E. Africa Standard Time',
      abbr: 'EAST',
      offset: 3,
      isdst: false,
      text: '(UTC+03:00) Nairobi',
      utc: [
        'Africa/Addis_Ababa',
        'Africa/Asmera',
        'Africa/Dar_es_Salaam',
        'Africa/Djibouti',
        'Africa/Juba',
        'Africa/Kampala',
        'Africa/Khartoum',
        'Africa/Mogadishu',
        'Africa/Nairobi',
        'Antarctica/Syowa',
        'Etc/GMT-3',
        'Indian/Antananarivo',
        'Indian/Comoro',
        'Indian/Mayotte',
      ],
    },
    {
      value: 'Moscow Standard Time',
      abbr: 'MSK',
      offset: 3,
      isdst: false,
      text: '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk',
      utc: [
        'Europe/Kirov',
        'Europe/Moscow',
        'Europe/Simferopol',
        'Europe/Volgograd',
        'Europe/Minsk',
      ],
    },
    {
      value: 'Samara Time',
      abbr: 'SAMT',
      offset: 4,
      isdst: false,
      text: '(UTC+04:00) Samara, Ulyanovsk, Saratov',
      utc: ['Europe/Astrakhan', 'Europe/Samara', 'Europe/Ulyanovsk'],
    },
    {
      value: 'Iran Standard Time',
      abbr: 'IDT',
      offset: 4.5,
      isdst: true,
      text: '(UTC+03:30) Tehran',
      utc: ['Asia/Tehran'],
    },
    {
      value: 'Arabian Standard Time',
      abbr: 'AST',
      offset: 4,
      isdst: false,
      text: '(UTC+04:00) Abu Dhabi, Muscat',
      utc: ['Asia/Dubai', 'Asia/Muscat', 'Etc/GMT-4'],
    },
    {
      value: 'Azerbaijan Standard Time',
      abbr: 'ADT',
      offset: 5,
      isdst: true,
      text: '(UTC+04:00) Baku',
      utc: ['Asia/Baku'],
    },
    {
      value: 'Mauritius Standard Time',
      abbr: 'MST',
      offset: 4,
      isdst: false,
      text: '(UTC+04:00) Port Louis',
      utc: ['Indian/Mahe', 'Indian/Mauritius', 'Indian/Reunion'],
    },
    {
      value: 'Georgian Standard Time',
      abbr: 'GET',
      offset: 4,
      isdst: false,
      text: '(UTC+04:00) Tbilisi',
      utc: ['Asia/Tbilisi'],
    },
    {
      value: 'Caucasus Standard Time',
      abbr: 'CST',
      offset: 4,
      isdst: false,
      text: '(UTC+04:00) Yerevan',
      utc: ['Asia/Yerevan'],
    },
    {
      value: 'Afghanistan Standard Time',
      abbr: 'AST',
      offset: 4.5,
      isdst: false,
      text: '(UTC+04:30) Kabul',
      utc: ['Asia/Kabul'],
    },
    {
      value: 'West Asia Standard Time',
      abbr: 'WAST',
      offset: 5,
      isdst: false,
      text: '(UTC+05:00) Ashgabat, Tashkent',
      utc: [
        'Antarctica/Mawson',
        'Asia/Aqtau',
        'Asia/Aqtobe',
        'Asia/Ashgabat',
        'Asia/Dushanbe',
        'Asia/Oral',
        'Asia/Samarkand',
        'Asia/Tashkent',
        'Etc/GMT-5',
        'Indian/Kerguelen',
        'Indian/Maldives',
      ],
    },
    {
      value: 'Yekaterinburg Time',
      abbr: 'YEKT',
      offset: 5,
      isdst: false,
      text: '(UTC+05:00) Yekaterinburg',
      utc: ['Asia/Yekaterinburg'],
    },
    {
      value: 'Pakistan Standard Time',
      abbr: 'PKT',
      offset: 5,
      isdst: false,
      text: '(UTC+05:00) Islamabad, Karachi',
      utc: ['Asia/Karachi'],
    },
    {
      value: 'India Standard Time',
      abbr: 'IST',
      offset: 5.5,
      isdst: false,
      text: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
      utc: ['Asia/Kolkata', 'Asia/Calcutta'],
    },
    {
      value: 'Sri Lanka Standard Time',
      abbr: 'SLST',
      offset: 5.5,
      isdst: false,
      text: '(UTC+05:30) Sri Jayawardenepura',
      utc: ['Asia/Colombo'],
    },
    {
      value: 'Nepal Standard Time',
      abbr: 'NST',
      offset: 5.75,
      isdst: false,
      text: '(UTC+05:45) Kathmandu',
      utc: ['Asia/Kathmandu'],
    },
    {
      value: 'Central Asia Standard Time',
      abbr: 'CAST',
      offset: 6,
      isdst: false,
      text: '(UTC+06:00) Nur-Sultan (Astana)',
      utc: [
        'Antarctica/Vostok',
        'Asia/Almaty',
        'Asia/Bishkek',
        'Asia/Qyzylorda',
        'Asia/Urumqi',
        'Etc/GMT-6',
        'Indian/Chagos',
      ],
    },
    {
      value: 'Bangladesh Standard Time',
      abbr: 'BST',
      offset: 6,
      isdst: false,
      text: '(UTC+06:00) Dhaka',
      utc: ['Asia/Dhaka', 'Asia/Thimphu'],
    },
    {
      value: 'Myanmar Standard Time',
      abbr: 'MST',
      offset: 6.5,
      isdst: false,
      text: '(UTC+06:30) Yangon (Rangoon)',
      utc: ['Asia/Rangoon', 'Indian/Cocos'],
    },
    {
      value: 'SE Asia Standard Time',
      abbr: 'SAST',
      offset: 7,
      isdst: false,
      text: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
      utc: [
        'Antarctica/Davis',
        'Asia/Bangkok',
        'Asia/Hovd',
        'Asia/Jakarta',
        'Asia/Phnom_Penh',
        'Asia/Pontianak',
        'Asia/Saigon',
        'Asia/Vientiane',
        'Etc/GMT-7',
        'Indian/Christmas',
      ],
    },
    {
      value: 'N. Central Asia Standard Time',
      abbr: 'NCAST',
      offset: 7,
      isdst: false,
      text: '(UTC+07:00) Novosibirsk',
      utc: ['Asia/Novokuznetsk', 'Asia/Novosibirsk', 'Asia/Omsk'],
    },
    {
      value: 'China Standard Time',
      abbr: 'CST',
      offset: 8,
      isdst: false,
      text: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
      utc: ['Asia/Hong_Kong', 'Asia/Macau', 'Asia/Shanghai'],
    },
    {
      value: 'North Asia Standard Time',
      abbr: 'NAST',
      offset: 8,
      isdst: false,
      text: '(UTC+08:00) Krasnoyarsk',
      utc: ['Asia/Krasnoyarsk'],
    },
    {
      value: 'Singapore Standard Time',
      abbr: 'MPST',
      offset: 8,
      isdst: false,
      text: '(UTC+08:00) Kuala Lumpur, Singapore',
      utc: [
        'Asia/Brunei',
        'Asia/Kuala_Lumpur',
        'Asia/Kuching',
        'Asia/Makassar',
        'Asia/Manila',
        'Asia/Singapore',
        'Etc/GMT-8',
      ],
    },
    {
      value: 'W. Australia Standard Time',
      abbr: 'WAST',
      offset: 8,
      isdst: false,
      text: '(UTC+08:00) Perth',
      utc: ['Antarctica/Casey', 'Australia/Perth'],
    },
    {
      value: 'Taipei Standard Time',
      abbr: 'TST',
      offset: 8,
      isdst: false,
      text: '(UTC+08:00) Taipei',
      utc: ['Asia/Taipei'],
    },
    {
      value: 'Ulaanbaatar Standard Time',
      abbr: 'UST',
      offset: 8,
      isdst: false,
      text: '(UTC+08:00) Ulaanbaatar',
      utc: ['Asia/Choibalsan', 'Asia/Ulaanbaatar'],
    },
    {
      value: 'North Asia East Standard Time',
      abbr: 'NAEST',
      offset: 8,
      isdst: false,
      text: '(UTC+08:00) Irkutsk',
      utc: ['Asia/Irkutsk'],
    },
    {
      value: 'Japan Standard Time',
      abbr: 'JST',
      offset: 9,
      isdst: false,
      text: '(UTC+09:00) Osaka, Sapporo, Tokyo',
      utc: [
        'Asia/Dili',
        'Asia/Jayapura',
        'Asia/Tokyo',
        'Etc/GMT-9',
        'Pacific/Palau',
      ],
    },
    {
      value: 'Korea Standard Time',
      abbr: 'KST',
      offset: 9,
      isdst: false,
      text: '(UTC+09:00) Seoul',
      utc: ['Asia/Pyongyang', 'Asia/Seoul'],
    },
    {
      value: 'Cen. Australia Standard Time',
      abbr: 'CAST',
      offset: 9.5,
      isdst: false,
      text: '(UTC+09:30) Adelaide',
      utc: ['Australia/Adelaide', 'Australia/Broken_Hill'],
    },
    {
      value: 'AUS Central Standard Time',
      abbr: 'ACST',
      offset: 9.5,
      isdst: false,
      text: '(UTC+09:30) Darwin',
      utc: ['Australia/Darwin'],
    },
    {
      value: 'E. Australia Standard Time',
      abbr: 'EAST',
      offset: 10,
      isdst: false,
      text: '(UTC+10:00) Brisbane',
      utc: ['Australia/Brisbane', 'Australia/Lindeman'],
    },
    {
      value: 'AUS Eastern Standard Time',
      abbr: 'AEST',
      offset: 10,
      isdst: false,
      text: '(UTC+10:00) Canberra, Melbourne, Sydney',
      utc: ['Australia/Melbourne', 'Australia/Sydney'],
    },
    {
      value: 'West Pacific Standard Time',
      abbr: 'WPST',
      offset: 10,
      isdst: false,
      text: '(UTC+10:00) Guam, Port Moresby',
      utc: [
        'Antarctica/DumontDUrville',
        'Etc/GMT-10',
        'Pacific/Guam',
        'Pacific/Port_Moresby',
        'Pacific/Saipan',
        'Pacific/Truk',
      ],
    },
    {
      value: 'Tasmania Standard Time',
      abbr: 'TST',
      offset: 10,
      isdst: false,
      text: '(UTC+10:00) Hobart',
      utc: ['Australia/Currie', 'Australia/Hobart'],
    },
    {
      value: 'Yakutsk Standard Time',
      abbr: 'YST',
      offset: 9,
      isdst: false,
      text: '(UTC+09:00) Yakutsk',
      utc: ['Asia/Chita', 'Asia/Khandyga', 'Asia/Yakutsk'],
    },
    {
      value: 'Central Pacific Standard Time',
      abbr: 'CPST',
      offset: 11,
      isdst: false,
      text: '(UTC+11:00) Solomon Is., New Caledonia',
      utc: [
        'Antarctica/Macquarie',
        'Etc/GMT-11',
        'Pacific/Efate',
        'Pacific/Guadalcanal',
        'Pacific/Kosrae',
        'Pacific/Noumea',
        'Pacific/Ponape',
      ],
    },
    {
      value: 'Vladivostok Standard Time',
      abbr: 'VST',
      offset: 11,
      isdst: false,
      text: '(UTC+11:00) Vladivostok',
      utc: ['Asia/Sakhalin', 'Asia/Ust-Nera', 'Asia/Vladivostok'],
    },
    {
      value: 'New Zealand Standard Time',
      abbr: 'NZST',
      offset: 12,
      isdst: false,
      text: '(UTC+12:00) Auckland, Wellington',
      utc: ['Antarctica/McMurdo', 'Pacific/Auckland'],
    },
    {
      value: 'UTC+12',
      abbr: 'U',
      offset: 12,
      isdst: false,
      text: '(UTC+12:00) Coordinated Universal Time+12',
      utc: [
        'Etc/GMT-12',
        'Pacific/Funafuti',
        'Pacific/Kwajalein',
        'Pacific/Majuro',
        'Pacific/Nauru',
        'Pacific/Tarawa',
        'Pacific/Wake',
        'Pacific/Wallis',
      ],
    },
    {
      value: 'Fiji Standard Time',
      abbr: 'FST',
      offset: 12,
      isdst: false,
      text: '(UTC+12:00) Fiji',
      utc: ['Pacific/Fiji'],
    },
    {
      value: 'Magadan Standard Time',
      abbr: 'MST',
      offset: 12,
      isdst: false,
      text: '(UTC+12:00) Magadan',
      utc: [
        'Asia/Anadyr',
        'Asia/Kamchatka',
        'Asia/Magadan',
        'Asia/Srednekolymsk',
      ],
    },
    {
      value: 'Kamchatka Standard Time',
      abbr: 'KDT',
      offset: 13,
      isdst: true,
      text: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
      utc: ['Asia/Kamchatka'],
    },
    {
      value: 'Tonga Standard Time',
      abbr: 'TST',
      offset: 13,
      isdst: false,
      text: "(UTC+13:00) Nuku'alofa",
      utc: [
        'Etc/GMT-13',
        'Pacific/Enderbury',
        'Pacific/Fakaofo',
        'Pacific/Tongatapu',
      ],
    },
    {
      value: 'Samoa Standard Time',
      abbr: 'SST',
      offset: 13,
      isdst: false,
      text: '(UTC+13:00) Samoa',
      utc: ['Pacific/Apia'],
    },
  ];
  constructor() {}

  priceRangeOld(indTotal) {
    console.log('indTotal', indTotal);
    if (indTotal >= 0 && indTotal < 50) {
      return {
        printedCards: 0.0,
        capturOnly: 0.0,
        idEssentials: 0.0,
        entranceScreening: 0.0,
        studentTracking: 0.0,
        visitorTracking: 0.0,
        eventTracking: 0.0,
        busTracking: 0.0,
      };
    } else if (indTotal >= 50 && indTotal < 100) {
      return {
        printedCards: 2.0,
        capturOnly: 1.24,
        idEssentials: 2.0,
        entranceScreening: 0.0,
        studentTracking: 2.5,
        visitorTracking: 6.0,
        eventTracking: 2.5,
        busTracking: 2.5,
      };
    } else if (indTotal >= 100 && indTotal < 200) {
      // 100   $1.58  $1.00  $1.50  $0.00  $2.00  $3.50  $2.00  $2.00
      return {
        printedCards: 1.58,
        capturOnly: 1.0,
        idEssentials: 1.5,
        entranceScreening: 0.0,
        studentTracking: 2.0,
        visitorTracking: 3.5,
        eventTracking: 2.0,
        busTracking: 2.0,
      };
    } else if (indTotal >= 200 && indTotal < 500) {
      // 200   $1.17  $0.75  $1.25  $0.00  $1.50  $2.00  $1.50  $1.50
      return {
        printedCards: 1.17,
        capturOnly: 0.75,
        idEssentials: 1.25,
        entranceScreening: 0.0,
        studentTracking: 1.5,
        visitorTracking: 2.0,
        eventTracking: 1.5,
        busTracking: 1.5,
      };
    } else if (indTotal >= 500 && indTotal < 1000) {
      // 500   $0.75  $0.60  $1.00  $0.00  $1.20  $1.10  $1.20  $1.20
      return {
        printedCards: 0.75,
        capturOnly: 0.6,
        idEssentials: 1.0,
        entranceScreening: 0.0,
        studentTracking: 1.2,
        visitorTracking: 1.1,
        eventTracking: 1.2,
        busTracking: 1.2,
      };
    } else if (indTotal >= 1000 && indTotal < 2000) {
      // 1,000   $0.69  $0.40  $0.80  $0.00  $0.80  $0.55  $0.80  $0.80
      return {
        printedCards: 0.69,
        capturOnly: 0.4,
        idEssentials: 0.8,
        entranceScreening: 0.0,
        studentTracking: 0.8,
        visitorTracking: 0.55,
        eventTracking: 0.8,
        busTracking: 0.8,
      };
    } else if (indTotal >= 2000 && indTotal < 5000) {
      // 2,000   $0.63  $0.20  $0.50  $0.00  $0.50  $0.28  $0.50  $0.50
      return {
        printedCards: 0.63,
        capturOnly: 0.2,
        idEssentials: 0.5,
        entranceScreening: 0.0,
        studentTracking: 0.5,
        visitorTracking: 0.28,
        eventTracking: 0.5,
        busTracking: 0.5,
      };
    } else if (indTotal >= 5000) {
      // 5,000   $0.50  $0.25  $0.50  $0.00  $0.50  $0.11  $0.50  $0.50
      return {
        printedCards: 0.5,
        capturOnly: 0.25,
        idEssentials: 0.5,
        entranceScreening: 0.0,
        studentTracking: 0.5,
        visitorTracking: 0.11,
        eventTracking: 0.5,
        busTracking: 0.5,
      };
    }
    return null;
  }
  priceRange(indTotal) {
    console.log('indTotal', indTotal);
    if (indTotal >= 0 && indTotal < 50) {
      return {
        printedCards: 0.0,
        capturOnly: 0.0,
        idEssentials: 0.0,
        entranceScreening: 0.0,
        studentTracking: 0.0,
        visitorTracking: 0.0,
        eventTracking: 0.0,
        busTracking: 0.0,
      };
    } else if (indTotal >= 50 && indTotal < 100) {
      return {
        printedCards: 2.0,
        capturOnly: 1.86,
        idEssentials: 3.0,
        entranceScreening: 0.0,
        studentTracking: 3.0,
        visitorTracking: 7.0,
        eventTracking: 3.0,
        busTracking: 3.0,
      };
    } else if (indTotal >= 100 && indTotal < 200) {
      // 100   $1.58  $1.00  $1.50  $0.00  $2.00  $3.50  $2.00  $2.00
      //       $1.58	$1.50	$2.00	$0.00	$2.00	$4.50	$2.00	$2.00
      return {
        printedCards: 1.58,
        capturOnly: 1.5,
        idEssentials: 2.0,
        entranceScreening: 0.0,
        studentTracking: 2.0,
        visitorTracking: 4.5,
        eventTracking: 2.0,
        busTracking: 2.0,
      };
    } else if (indTotal >= 200 && indTotal < 500) {
      // 200   $1.17  $0.75  $1.25  $0.00  $1.50  $2.00  $1.50  $1.50
      //$1.00	$1.13	$1.50	$0.00	$1.50	$2.75	$1.50	$1.50
      return {
        printedCards: 1.0,
        capturOnly: 1.13,
        idEssentials: 1.5,
        entranceScreening: 0.0,
        studentTracking: 1.5,
        visitorTracking: 2.75,
        eventTracking: 1.5,
        busTracking: 1.5,
      };
    } else if (indTotal >= 500 && indTotal < 1000) {
      // 500   $0.75  $0.60  $1.00  $0.00  $1.20  $1.10  $1.20  $1.20
      //$0.50	$0.90	$1.20	$0.00	$1.20	$1.10	$1.20	$1.20
      return {
        printedCards: 0.5,
        capturOnly: 0.9,
        idEssentials: 1.2,
        entranceScreening: 0.0,
        studentTracking: 1.2,
        visitorTracking: 1.1,
        eventTracking: 1.2,
        busTracking: 1.2,
      };
    } else if (indTotal >= 1000 && indTotal < 2000) {
      // 1,000   $0.69  $0.40  $0.80  $0.00  $0.80  $0.55  $0.80  $0.80
      //   $0.50	$0.60	$0.90	$0.00	$0.90	$0.55	$0.90	$0.90
      return {
        printedCards: 0.5,
        capturOnly: 0.6,
        idEssentials: 0.9,
        entranceScreening: 0.0,
        studentTracking: 0.9,
        visitorTracking: 0.55,
        eventTracking: 0.9,
        busTracking: 0.9,
      };
    } else if (indTotal >= 2000 && indTotal < 5000) {
      // 2,000   $0.63  $0.20  $0.50  $0.00  $0.50  $0.28  $0.50  $0.50
      //   $0.50	$0.30	$0.68	$0.00	$0.68	$0.28	$0.68	$0.68
      return {
        printedCards: 0.5,
        capturOnly: 0.3,
        idEssentials: 0.68,
        entranceScreening: 0.0,
        studentTracking: 0.68,
        visitorTracking: 0.28,
        eventTracking: 0.68,
        busTracking: 0.68,
      };
    } else if (indTotal >= 5000) {
      // 5,000   $0.50  $0.25  $0.50  $0.00  $0.50  $0.11  $0.50  $0.50
      // $0.50	$0.38	$0.54	$0.00	$0.54	$0.11	$0.54	$0.54
      return {
        printedCards: 0.5,
        capturOnly: 0.38,
        idEssentials: 0.54,
        entranceScreening: 0.0,
        studentTracking: 0.54,
        visitorTracking: 0.11,
        eventTracking: 0.54,
        busTracking: 0.54,
      };
    }
    return null;
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  commissionRange(amount) {
    let commissionPercentage = 0;
    if (amount) {
      amount = parseInt(amount);
      if (amount < 2000) {
        commissionPercentage = 0;
      } else if (amount >= 2000 && amount < 5000) {
        commissionPercentage = 10;
      } else if (amount >= 5000 && amount < 15000) {
        commissionPercentage = 20;
      } else if (amount >= 15000 && amount < 30000) {
        commissionPercentage = 30;
      } else if (amount >= 30000) {
        commissionPercentage = 50;
      }
    } else {
    }
    return commissionPercentage;
  }

  getModuleList(discount, type: any) {
    if (type == 'teammate') {
    } else if (type == 'super admin') {
      this.modulesList = this.modulesList.filter(
        (item) => item.id !== 'printedCards' && item.id !== 'idEssentials'
      );
      this.modulesList.map((item, index) => {
        if (discount) {
          item.value = +((item.value * discount) / 100).toFixed(2);
        }
      });
    }
    return this.modulesList;
  }

  invoiceBtnShow(amount) {
    if (amount && amount >= 5000) {
      return true;
    } else {
      return false;
    }
  }
}
