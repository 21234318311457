import { Pipe, PipeTransform } from '@angular/core';
import { EventService } from '../services/event/event.service';
import moment from 'moment';
import { UserService } from '../services/user/user.service';
import { CommonServiceService } from '../services/common-service.service';

@Pipe({
  name: 'getOrgUpcommingEvent',
  standalone: true,
})
export class GetOrgUpcommingEventPipe implements PipeTransform {
  constructor(
    private eventService: EventService,
    private userService: UserService,
    private commonFun: CommonServiceService
  ) {}
  async transform(orgId: string): Promise<any> {
    const currentDate = moment().startOf('day').valueOf();
    let orgUpcomingEvent = (await this.eventService.getFirstEventFromGivenDate(
      currentDate,
      this.userService.studioID,
      orgId
    )).val()
    if (orgUpcomingEvent) {
      return this.commonFun.convertObjToArr(orgUpcomingEvent)[0];
    } else return null;
  }
}
