import { Pipe, PipeTransform } from '@angular/core';
import lodash from 'lodash';
import { ApiHelperService, CloudFnNames } from '../services/apiHelperService/api-helper.service';

@Pipe({
  name: 'getIndCount',
  standalone: true,
})
export class GetIndCountPipe implements PipeTransform {
  constructor(private apiHelperService: ApiHelperService) {}

  async transform(
    orgId: string,
    retryCount: number = 0
  ): Promise<number> {
    try {
      const response: any = await this.apiHelperService.postToCloudFn(
        CloudFnNames.getTotalIndCount,
        { orgID: orgId }
      );
      if (lodash.get(response, 'result.message', 'failed') == 'success') {
        if (lodash.get(response, 'result.data', 0) === 0 && retryCount < 3) {
          await new Promise((resolve) => setTimeout(resolve, 2500)); // Delay for 2500ms
          return this.transform(orgId, retryCount + 1); // Retry with incremented retryCount
        } else {
          return lodash.get(response, 'result.data', 0); // If indCount is not 0 or if retry limit reached, return the indCount
        }
      } else {
        return 0; // If it's not a success response, return 0
      }
    } catch (error) {
      return 0; // If there's an error, return 0
    }
  }
}
