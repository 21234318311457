export function decodeKeys(s: string) {
  // --- remove appended 0s and eqls from the end
  let regExp = new RegExp(/=+$/g);
  let matches = regExp.exec(s);
  let match = !!matches && matches.length > 0 ? matches[0] : "";
  let count = match.length;
  s = s.substring(0, s.length - count * 2);

  // --- devide in group of 8 and convert to decimal > character
  let chunks: any = s.match(/.{1,8}/g);
  chunks = chunks.map((bin) => parseInt(bin, 2));
  chunks = chunks.map((dec) => String.fromCharCode(dec));
  return chunks.join("");
}

export interface Environment {
  production: boolean;
  siteUrl:string;
  redirectToHigh5: string;
  apiBaseUrl: string;
  firebaseImgUrl: any;
  firebaseFunctionsBaseUrl:string;
  firebaseCallableFunctionUrl: string;
  cloudFunctionsDomain: string;
  cloudFunctionsDomainV2: string;
  zohoBooksV2BaseUrl: string;
  firebaseConfig: any;
  awsImageUpload: any;
  superAdminEmail: string;
  onrampSetupAdminEmail: string;
  orgManagerType?: string;
  districtType?: string;
  yourlsObj: any;
  placeholderImageUrl: string;
  mailFromAddress: string;
  elasticSearch: any;
  mailgunObj: any;
  smsObj: any;
  edk: string;
  exportBulkIndBaseUrl: string;
  blackbaudObj: any;
}
